import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize isAuthenticated based on the token's presence
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(sessionStorage.getItem("accessToken")));

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "accessToken" && event.oldValue !== event.newValue) {
        setIsAuthenticated(Boolean(event.newValue)); // Update based on the new value
      }
    };

    // Listen to session storage changes
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const login = async (accessToken, refreshToken, expiresIn) => {
    const expirationTimestamp = Date.now() + expiresIn * 1000; // Convert expiresIn to milliseconds and add to current time
    console.log("login:", accessToken, refreshToken, expiresIn, expirationTimestamp);
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("accessTokenExpiry", expirationTimestamp.toString());

    setIsAuthenticated(true);
    return Promise.resolve(); // Ensure the function returns a promise that resolves after state is set
  };

  const logout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("accessTokenExpiry");

    setIsAuthenticated(false);
  };

  return <AuthContext.Provider value={{ isAuthenticated, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
