import React, { useContext, useEffect, useRef, useState } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./i18n";
import createTheme from "./theme";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import SignIn from "./components/auth/SignIn";
import { useAuth } from "./components/auth/AuthContext";
import GlobalProvider, { GlobalContext } from "./contexts/GlobalContext";
import LoadingSpinner from "./components/LoadingSpinner";

initializeFileTypeIcons();

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const { theme } = useTheme();
  const auth = useAuth();
  const { isAuthenticated } = auth;
  const { fetchUserData, isAuthorized } = useContext(GlobalContext);
  const content = useRoutes(routes);

  const [authState, setAuthState] = useState("checking"); // 'checking', 'authorized', 'unauthorized'
  const fetchUserDataRef = useRef(fetchUserData);

  useEffect(() => {
    fetchUserDataRef.current = fetchUserData; // Update current value each render
  });

  useEffect(() => {
    if (!isAuthenticated) {
      setAuthState("unauthorized");
      return;
    }

    fetchUserDataRef
      .current()
      .then(() => {
        setAuthState(isAuthorized ? "authorized" : "unauthorized");
      })
      .catch(() => {
        setAuthState("unauthorized");
      });
  }, [isAuthenticated, isAuthorized]);

  const renderContent = () => {
    switch (authState) {
      case "checking":
        return <LoadingSpinner />;
      case "unauthorized":
        return <SignIn />;
      case "authorized":
        if (!isAuthorized) {
          return <UnauthorizedComponent />;
        }
        return content;
      default:
        return <div>Unexpected state</div>;
    }
  };

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | DWS" defaultTitle="DWS - Delta Refund Services" />
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <GlobalProvider>{renderContent()}</GlobalProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;

const UnauthorizedComponent = () => {
  return <div>You do not have permission to view this content.</div>;
};
