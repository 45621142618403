import React from "react";
import IconButton from "@mui/material/IconButton";
import * as XLSX from "xlsx";
import Tooltip from "@mui/material/Tooltip";
import XlsxIcon from "../svg/XlsxIcon"; // Excel icon
import CsvIcon from "../svg/CsvIcon"; // CSV icon

const DataGridExport = ({ tableData, columns }) => {
  // Filter out columns with unwanted field names
  const filteredColumns = columns.filter(
    (column) => column.field && column.field !== "undefined" && column.field !== "fileId" && column.field !== "actions"
  );

  const handleExportCSV = () => {
    // Create CSV data
    const csvData = [
      // Create header row with headerName
      filteredColumns.map((column) => column.headerName || column.field),
      // Create data rows
      ...tableData.map((row) => filteredColumns.map((column) => row[column.field])),
    ];

    // Trigger download using CSVLink
    const blob = new Blob([csvData.map((row) => row.join(";")).join("\n")], {
      type: "text/csv",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleExportXLSX = () => {
    // Create XLSX data
    const xlsxData = tableData.map((row) =>
      filteredColumns.reduce((acc, column) => {
        acc[column.headerName || column.field] = row[column.field];
        return acc;
      }, {})
    );

    // Convert to XLSX format
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(xlsxData);
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    const xlsxBuffer = XLSX.write(workbook, {
      type: "buffer",
      bookType: "xlsx",
    });

    // Trigger download
    const blob = new Blob([xlsxBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Tooltip title="Export to CSV">
        <IconButton color="primary" onClick={handleExportCSV}>
          <CsvIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Export to XLSX">
        <IconButton color="primary" onClick={handleExportXLSX}>
          <XlsxIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DataGridExport;
