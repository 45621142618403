import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import SelectWrapper from "./SelectBox";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import apiUtils from "../utils/apiUtils";
// import { set } from "date-fns";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const createValidationSchema = (showExtraFields) => {
  let schema = Yup.object().shape({
    customerId: Yup.string().required("Required"),
    invoiceNumber: Yup.string().required("Required"),
    invoiceDate: Yup.string().required("Required"),
    supplierId: Yup.string().required("Required"),
    countryCode: Yup.string().required("Required"),
    claimProductTypeCode: Yup.string().required("Required"),
    currencyId: Yup.string().required("Required"),
    invoiceGrossAmount: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),
    invoiceVATAmountLocalCurrency: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),

    claimId: Yup.string().required("Required"),
    invoiceLabelId: Yup.string().required("Required"),
  });

  if (showExtraFields) {
    schema = schema.concat(
      Yup.object().shape({
        languageCode: Yup.string().required("Language is required"),
        freeText: Yup.string().required("Free text is required"),
      })
    );
  }

  return schema;
};

export default function ViewCustomerInvoiceDialog(props) {
  const { viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog, refreshGrid } = props;

  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  const [isNewFile, setIsNewFile] = useState(false);
  const [isRemovedFile, setIsRemovedFile] = useState(false);
  const [isUpdatedFile, setIsUpdatedFile] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [customerId, setCustomerId] = useState({});
  const [currentCountryCode, setCurrentCountryCode] = useState(viewCustomerInvoiceDialog?.params?.countryCode);
  const [currentInvoiceState, setCurrentInvoiceState] = useState(viewCustomerInvoiceDialog?.params?.invoiceStateId);
  // var initialProductCode =
  //   viewCustomerInvoiceDialog?.productInfo[0]?.productCode;
  // var initialProductSubCode =
  //   viewCustomerInvoiceDialog?.productInfo[0]?.productSubCode;
  const [showExtraFields, setShowExtraFields] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const productTypeData = apiUtils.useApiData("/api/v1/productTypes");
  const [selectedCustomProductLanguage, setSelectedCustomProductLanguage] = useState("");
  var initialCustomProductLanguage = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry;
  var initialProductCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription;

  var initialProductTypeId = viewCustomerInvoiceDialog?.params?.claimProductTypeId;

  // console.log("productTypeData:", productTypeData);

  var initialProductCode;
  var initialProductSubCode;

  const [initialInvoiceGrossAmount, setInitialInvoiceGrossAmount] = useState(viewCustomerInvoiceDialog?.params?.invoiceGrossAmount);
  const [initialInvoiceVATAmountLocalCurrency, setInitialInvoiceVATAmountLocalCurrency] = useState(
    viewCustomerInvoiceDialog?.params?.invoiceVATAmountLocalCurrency
  );

  if (viewCustomerInvoiceDialog?.params?.claimProductTypeId === -1) {
    // console.log("In the if");
    initialProductCode = "10";
    initialProductSubCode = "99";
  } else {
    // console.log("In the else");
    initialProductCode = productTypeData?.find((product) => product.productTypeId === initialProductTypeId)?.categoryCode;

    initialProductSubCode = productTypeData?.find((product) => product.productTypeId === initialProductTypeId)?.subCode;
    initialProductSubCode = initialProductSubCode === "" ? "98" : initialProductSubCode;
  }
  // console.log("initialProductTypeId:", initialProductTypeId);
  // console.log("initialProductCode:", initialProductCode);
  // console.log("initialProductSubCode:", initialProductSubCode);

  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const handleRemoveFileClick = () => {
    setShowConfirmationDialog(true);
  };

  // var customerId = viewCustomerInvoiceDialog?.custInfo[0]?.customerId;

  // var customerNumber = viewCustomerInvoiceDialog?.custInfo[0]?.customerNumber;

  // useEffect(() => {
  //   setInitialProductCode(
  //     viewCustomerInvoiceDialog?.productInfo[0]?.productCode
  //   );
  // }, [viewCustomerInvoiceDialog?.productInfo[0]?.productCode]);

  // useEffect(() => {
  //   setInitialProductSubCode(
  //     viewCustomerInvoiceDialog?.productInfo[0]?.productSubCode
  //   );
  // }, [viewCustomerInvoiceDialog?.productInfo[0]?.productSubCode]);

  useEffect(() => {
    setCustomerId(viewCustomerInvoiceDialog?.params?.entityId);
    // console.log("customerId:", customerId);
  }, [viewCustomerInvoiceDialog?.params?.entityId]);

  useEffect(() => {
    setCurrentCountryCode(viewCustomerInvoiceDialog?.params?.countryCode);
  }, [viewCustomerInvoiceDialog?.params?.countryCode]);

  useEffect(() => {
    setCurrentInvoiceState(viewCustomerInvoiceDialog?.params?.invoiceStateId);
  }, [viewCustomerInvoiceDialog?.params?.invoiceStateId]);

  useEffect(() => {
    setSelectedClaimDate(moment(viewCustomerInvoiceDialog?.params?.invoiceDate).format("YYYY-MM-DD"));
  }, [viewCustomerInvoiceDialog]);

  useEffect(() => {
    if (viewCustomerInvoiceDialog.isOpen) {
      setCurrentInvoiceState(viewCustomerInvoiceDialog.params.invoiceStateId);
    }
  }, [viewCustomerInvoiceDialog]);

  const [fileUrl, setFileUrl] = useState(null);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      const href = URL.createObjectURL(selectedFile);
      setFileUrl(href);
      if (isRemovedFile) {
        if (!isNewFile) {
          setIsUpdatedFile(true);
        }
      } else {
        setIsNewFile(true);
      }
      setFileContent(selectedFile);
      setIsRemovedFile(false);
      refreshGrid();
    },
    [isRemovedFile, isNewFile, refreshGrid]
  );

  const downloadFile = async (fileId) => {
    setFileUrl("");
    if (fileId >= 1) {
      try {
        const response = await axios({
          url: "/api/v1/azureblobstorage/" + fileId,
          method: "GET",
          responseType: "blob",
        });
        const href = URL.createObjectURL(response.data);
        setFileUrl(href);
      } catch (error) {
        setFileUrl("NoFile");
        console.error("Error downloading file:", error);
      }
    } else {
      setFileUrl("NoFile");
    }
  };

  if (viewCustomerInvoiceDialog?.downloadFile) {
    const fileId = viewCustomerInvoiceDialog?.params?.fileId;
    if (fileId === 0 || fileId === null) {
      setFileUrl("NoFile");
    } else {
      downloadFile(fileId);
    }
    viewCustomerInvoiceDialog.downloadFile = false;
  }

  var errorMessage = "";
  var submitInError = false;

  const validationSchema = createValidationSchema(showExtraFields);

  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
    } catch (validationErrors) {
      const errors = {};

      validationErrors.inner.forEach((error) => {
        if (!errors[error.path]) {
          // Only capture the first error message for each path
          errors[error.path] = error.message;
        }
      });

      setErrors(errors); // Assuming `setErrors` updates the component's state or Formik's errors
      return;
    }

    setSubmitting(true);
    await timeOut(1500);
    let fileId = 0;
    let invoiceId = viewCustomerInvoiceDialog?.params?.customerInvoiceId;

    try {
      if (isNewFile || isUpdatedFile) {
        const fileInfo = {
          documentType: 1,
          creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
          createdBy: 1,
          fileName: fileContent.name,
          fileSize: fileContent.size,
          mimeType: fileContent.type,
        };

        if (isRemovedFile) {
          fileId = 0;
        } else {
          const response = await axios.post("/api/v1/file", fileInfo);
          fileId = response.data.fileId;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", fileContent, fileId);
          await axios.post("/api/v1/azureblobstorage", data);
          const fileIdInfo = {
            fileId: fileId,
          };
          if (isUpdatedFile || isNewFile) {
            await axios.put("/api/v1/customerInvoices/updateFileId/" + invoiceId, fileIdInfo);
          }
        }
      } else {
        if (isRemovedFile) {
          fileId = 0;
        } else {
          fileId = viewCustomerInvoiceDialog?.params?.fileId;
        }
      }

      setIsNewFile(false);
      setIsRemovedFile(false);
      setIsUpdatedFile(false);
      // console.log("before");
      // console.log(values.claimProductTypeCode);
      // console.log(values.claimProductSubCodeId);
      // console.log(values.freeText);
      // console.log(values.languageCode);
      // console.log(productTypeData);

      let claimProductTypeId;
      if (values.claimProductTypeCode === "10" && (values.claimProductSubCodeId === "99" || values.claimProductSubCodeId === 99)) {
        claimProductTypeId = "-1";
      } else {
        values.freeText = "";
        values.languageCode = "";
        claimProductTypeId = productTypeData.filter(
          (x) =>
            x.countryCode === values.countryCode &&
            x.categoryCode === values.claimProductTypeCode &&
            x.subCode ===
              (values.claimProductSubCodeId === 98 || values.claimProductSubCodeId === "98" ? "" : values.claimProductSubCodeId)
        )[0].productTypeId;
      }

      // console.log("after");

      const updatedInvoice = {
        entityId: customerId,
        invoiceNumber: values.invoiceNumber,
        invoiceDate: selectedClaimDate,
        supplierId: values.supplierId,
        countryCode: values.countryCode,
        claimProductTypeId: claimProductTypeId,
        currencyId: values.currencyId,
        invoiceGrossAmount: values.invoiceGrossAmount,
        invoiceVATAmountLocalCurrency: values.invoiceVATAmountLocalCurrency,
        claimId: values.claimId,
        invoiceLabelId: values.invoiceLabelId,
        fileId: fileId,
        invoiceStateId: currentInvoiceState,
        productCodeDescription: values.freeText,
        productCodeDescriptionLanguageCountry: values.languageCode,
      };
      // console.log(updatedInvoice);
      // return;
      setEditedData(updatedInvoice);
      axios
        .put("/api/v1/customerInvoices/updateInvoiceEditForm/" + viewCustomerInvoiceDialog?.params?.customerInvoiceId, updatedInvoice)
        .then((response) => {
          viewCustomerInvoiceDialog.handleFile(updatedInvoice);
          // console.log("Update OK!");
        })
        .catch(function (error) {
          console.log("Update error!");
        })
        .finally(() => {
          refreshGrid();
        });

      viewCustomerInvoiceDialog.params.fileId = fileId;
      viewCustomerInvoiceDialog.handleFile(editedData);
      setSubmitting(false);
    } catch (error) {
      alert(error);
      setSubmitting(false);
    } finally {
      refreshGrid();
    }

    let invoiceAmountsChanged = false;

    if (initialInvoiceGrossAmount !== values.invoiceGrossAmount) {
      if (viewCustomerInvoiceDialog?.params?.claimId) {
        invoiceAmountsChanged = true;
      }
      setInitialInvoiceGrossAmount(values.invoiceGrossAmount);
    }
    if (initialInvoiceVATAmountLocalCurrency !== values.invoiceVATAmountLocalCurrency) {
      if (viewCustomerInvoiceDialog?.params?.claimId) {
        invoiceAmountsChanged = true;
      }
      setInitialInvoiceVATAmountLocalCurrency(values.invoiceVATAmountLocalCurrency);
    }
    // console.log("invoiceAmountsChanged: " + invoiceAmountsChanged);
    if (invoiceAmountsChanged) {
      axios
        .post("/api/v1/claims/updateInvoiceAmounts/" + viewCustomerInvoiceDialog?.params?.claimId)
        .catch(function (error) {
          console.log("Update error!: " + error);
        })
        .finally(() => {
          refreshGrid();
        });
    }
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "300px",
    minWidth: "600px",
    marginTop: "200px",
    marginLeft: "200px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  function StyledDropzone(props) {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      accept: { "application/pdf": [] },
      onDropAccepted,
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          <p
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            No files are currently attached to this invoice. <br />
            Drag 'n' drop the invoice file here, or click to select
          </p>
        </div>
      </div>
    );
  }

  const handleRemoveFile = () => {
    setIsRemovedFile(true);
    setFileUrl("NoFile");
    refreshGrid();
    setShowConfirmationDialog(false);
  };

  const handleSelectedClaimDateValue = (event) => {
    setSelectedClaimDate(event.target.value);
  };

  const handleSelectedCountryChange = (event) => {
    setCurrentCountryCode(event.target.value);
  };

  const handleSelectedInvoiceStateChange = (event) => {
    setCurrentInvoiceState(event.target.value);
  };

  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [selectedProductSubCode, setSelectedProductSubCode] = useState("");
  const [groupedProductCodes, setGroupedProductCodes] = useState([]);
  const [groupedProductSubCodes, setGroupedProductSubCodes] = useState([]);

  useEffect(() => {
    if (viewCustomerInvoiceDialog?.params?.claimProductTypeId) {
      const currentProductCode = props.productTypeArr.find(
        (item) => item.productTypeId === viewCustomerInvoiceDialog?.params?.claimProductTypeId
      )?.categoryCode;
      setSelectedProductCode(currentProductCode);
      // console.log(currentProductCode);
    }
  }, [props.productTypeArr, productTypeData, viewCustomerInvoiceDialog]);

  useEffect(() => {
    const currentProductSubCode = productTypeData.find(
      (item) => item.productTypeId === viewCustomerInvoiceDialog?.params?.claimProductTypeId
    )?.subCode;
    setSelectedProductSubCode(currentProductSubCode);
    // console.log(currentProductSubCode);
  }, [props.productTypeArr, productTypeData, viewCustomerInvoiceDialog, selectedProductCode]);

  useEffect(() => {
    const groupedProductTypes = productTypeData
      .filter((item) => item.countryCode === currentCountryCode)
      .reduce((acc, item) => {
        const key = item.categoryCode;
        acc[key] = acc[key] || [];
        acc[key].push(item.categoryCode + ". " + item.productShortDescription);
        return acc;
      }, {});

    const groupedOptions = Object.entries(groupedProductTypes).map(([key, values]) => ({
      key: key,
      value: values[0],
    }));

    setGroupedProductCodes(groupedOptions);
    // console.log(groupedOptions);
  }, [productTypeData, currentCountryCode]);

  useEffect(() => {
    if (!selectedProductCode) return;

    const groupedSubCodeOptions = productTypeData
      .filter((item) => item.countryCode === currentCountryCode && item.categoryCode === selectedProductCode)
      .reduce((acc, item) => {
        if (item.subCode.trim() === "") {
          return acc;
        }
        const key = item.subCode;
        acc[key] = acc[key] || [];
        acc[key].push(item.subCode + ". " + item.productLongDescription);

        if (selectedProductCode === "10") {
          acc[99] = acc[99] || [];
          acc[99].push("99. Custom Value");
        }

        return acc;
      }, {});

    const groupedSubCodeOptionsArray = Object.entries(groupedSubCodeOptions)
      .map(([key, values]) => ({
        key: key,
        value: values[0],
      }))
      .sort((a, b) => {
        const numA = parseFloat(a.key);
        const numB = parseFloat(b.key);

        return !isNaN(numA) && !isNaN(numB) ? numA - numB : a.key.localeCompare(b.key);
      });

    if (groupedSubCodeOptionsArray.length === 0) {
      groupedSubCodeOptionsArray.push({ key: 98, value: "N/A" });
      if (selectedProductCode === "10") {
        groupedSubCodeOptionsArray.push({ key: 99, value: "Custom Value" });
      }
    }

    setGroupedProductSubCodes(groupedSubCodeOptionsArray);
    if (groupedSubCodeOptionsArray.length === 1) {
      setSelectedProductSubCode(groupedSubCodeOptionsArray[0].key);
    }
  }, [productTypeData, currentCountryCode, selectedProductCode]);

  useEffect(() => {
    const productCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription;
    const productCodeDescriptionLanguageCountry = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry;

    if (productCodeDescription && productCodeDescriptionLanguageCountry) {
      setShowExtraFields(true);
      setSelectedProductCode("10");
      setSelectedProductSubCode("99");
    } else {
      setShowExtraFields(false);
    }
  }, [viewCustomerInvoiceDialog]);

  const handleProductSubCodeChange = (e) => {
    setSelectedProductSubCode(e.target.value);
    setShowExtraFields(false);
    // console.log(e.target.value);
    if (e.target.value === "99" || e.target.value === 99) {
      setShowExtraFields(true);
    }
  };

  const handleCustomProductLanguageChange = (e) => {
    setSelectedCustomProductLanguage(e.target.value);
  };

  const customSelectableLanguages = [
    { key: "en", value: "English" },
    { key: "ro", value: "Romanian" },
    { key: "cz", value: "Czech" },
    { key: "sk", value: "Slovak" },
  ];

  // const [isLoading, setIsLoading] = useState(false);
  // const [initialData, setInitialData] = useState([]);
  // useEffect(() => {
  // console.log("viewCustomerInvoiceDialog.params:", viewCustomerInvoiceDialog.params);
  // console.log("customerId:", customerId);
  // console.log("selectedProductCode:", selectedProductCode);
  // console.log("initialProductCode:", initialProductCode);
  // console.log("initialProductCodeDescription:", initialProductCodeDescription);
  // console.log("selectedProductSubCode:", selectedProductSubCode);
  // console.log("initialProductSubCode:", initialProductSubCode);
  // console.log("initialCustomProductLanguage:", initialCustomProductLanguage);
  // console.log("props.supplierArr:", props.supplierArr);
  const initialValues = {
    customerId: customerId,
    invoiceNumber: viewCustomerInvoiceDialog?.params?.invoiceNumber,
    invoiceDate: viewCustomerInvoiceDialog?.params?.invoiceDate,
    supplierId: viewCustomerInvoiceDialog?.params?.supplierId,
    countryCode: viewCustomerInvoiceDialog?.params?.countryCode,
    productTypeId: viewCustomerInvoiceDialog?.params?.productTypeId,
    claimProductTypeCode: selectedProductCode ? selectedProductCode : initialProductCode,
    currencyId: viewCustomerInvoiceDialog?.params?.currencyId,
    invoiceGrossAmount: viewCustomerInvoiceDialog?.params?.invoiceGrossAmount,
    invoiceVATAmountLocalCurrency: viewCustomerInvoiceDialog?.params?.invoiceVATAmountLocalCurrency,
    invoiceVATAmountEUR: viewCustomerInvoiceDialog?.params?.invoiceVATAmountEUR,
    claimId: viewCustomerInvoiceDialog?.params?.claimId,
    invoiceLabelId: viewCustomerInvoiceDialog?.params?.invoiceLabelId,
    languageCode: initialCustomProductLanguage,
    freeText: viewCustomerInvoiceDialog?.params?.freeText
      ? viewCustomerInvoiceDialog?.params?.freeText
      : initialProductCodeDescription,
    claimProductSubCodeId: selectedProductSubCode ? selectedProductSubCode : initialProductSubCode,
  };
  //   setInitialData(initialValues);
  //   setIsLoading(false);
  // }, [
  //   viewCustomerInvoiceDialog.params,
  //   customerId,
  //   selectedProductCode,
  //   initialProductCode,
  //   initialProductCodeDescription,
  //   selectedProductSubCode,
  //   initialProductSubCode,
  //   initialCustomProductLanguage,
  //   props.supplierArr,
  // ]);

  return (
    <Dialog
      open={viewCustomerInvoiceDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">{viewCustomerInvoiceDialog.title}</DialogTitle>
      <Divider />
      <DialogContent>
        {/* {isLoading ? (
          <CircularProgress /> // Show a loading indicator while data is loading
        ) : ( */}
        <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
            <Card mb={6}>
              <CardContent>
                {submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : null}

                {isSubmitting ? (
                  <Box minHeight="700px" display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={3} xs={12}>
                        <Grid item xs={12}>
                          <TextField
                            name="invoiceNumber"
                            label="Invoice number"
                            value={values.invoiceNumber}
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="invoiceDate"
                            name="invoiceDate"
                            label="Invoice date"
                            type="date"
                            value={selectedClaimDate}
                            onChange={handleSelectedClaimDateValue}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            name="invoiceStateId"
                            label="Invoice State"
                            value={currentInvoiceState ? currentInvoiceState : viewCustomerInvoiceDialog.params.invoiceStateId}
                            onChange={handleSelectedInvoiceStateChange}
                            options={JSON.parse(
                              JSON.stringify(
                                JSON.parse(JSON.stringify(props.invoiceStateArr).split('"invoiceStateId":').join('"key":'))
                              )
                                .split('"invoiceStateDescription":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            name="supplierId"
                            label="Supplier"
                            options={JSON.parse(
                              JSON.stringify(JSON.parse(JSON.stringify(props.supplierArr).split('"supplierId":').join('"key":')))
                                .split('"supplierName":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            name="countryCode"
                            label="Country"
                            onChange={handleSelectedCountryChange}
                            options={JSON.parse(
                              JSON.stringify(JSON.parse(JSON.stringify(props.countryArr).split('"countryId":').join('"key":')))
                                .split('"countryCode":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            name="claimProductTypeCode"
                            label="Product Type"
                            options={groupedProductCodes}
                            // value={
                            //   selectedProductCode
                            //     ? selectedProductCode
                            //     : initialProductCode
                            // }
                            onChange={(e) => setSelectedProductCode(e.target.value)}
                            error={Boolean(touched.claimProductTypeId && errors.claimProductTypeId)}
                            fullWidth
                            helperText={touched.claimProductTypeId && errors.claimProductTypeId ? errors.claimProductTypeId : ""}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            name="claimProductSubCodeId"
                            label="Product SubCode"
                            options={groupedProductSubCodes}
                            // value={
                            //   selectedProductSubCode
                            //     ? selectedProductSubCode
                            //     : initialProductSubCode
                            // }
                            onChange={handleProductSubCodeChange}
                          />
                        </Grid>
                        {showExtraFields && (
                          <>
                            <Grid xs={12}>
                              <SelectWrapper
                                name="languageCode"
                                label="FreeText Language"
                                options={customSelectableLanguages}
                                value={selectedCustomProductLanguage ? selectedCustomProductLanguage : initialCustomProductLanguage}
                                onChange={handleCustomProductLanguageChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                name="freeText"
                                label="FreeText"
                                value={values.freeText}
                                error={Boolean(touched.freeText && errors.freeText)}
                                fullWidth
                                helperText={touched.freeText && errors.freeText ? errors.freeText : ""}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="freeText"
                                variant="outlined"
                                my={2}
                              />
                            </Grid>
                          </>
                        )}

                        <Grid xs={12}>
                          <SelectWrapper
                            name="currencyId"
                            label="Currency"
                            options={JSON.parse(
                              JSON.stringify(JSON.parse(JSON.stringify(props.currencyArr).split('"currencyId":').join('"key":')))
                                .split('"isoCode":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="invoiceGrossAmount"
                            label="Invoice gross amount"
                            value={values.invoiceGrossAmount}
                            error={Boolean(touched.invoiceGrossAmount && errors.invoiceGrossAmount)}
                            fullWidth
                            helperText={touched.invoiceGrossAmount && errors.invoiceGrossAmount ? errors.invoiceGrossAmount : ""}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="invoiceVATAmountLocalCurrency"
                            label="Invoice VAT amount"
                            value={values.invoiceVATAmountLocalCurrency}
                            error={Boolean(touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency)}
                            fullWidth
                            helperText={
                              touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency
                                ? errors.invoiceVATAmountLocalCurrency
                                : ""
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Grid item md={9} xs={12}>
                          {fileUrl === "" ? (
                            <div className="App">
                              <Typography
                                sx={{
                                  fontSize: "21px",
                                  mt: 2,
                                  pt: "394px",
                                  pb: "394px",
                                  display: "flex",
                                  justifyContent: "center",
                                  border: 1,
                                  borderColor: "lightgray",
                                  width: "1000px",
                                }}
                              >
                                <CircularProgress size={30} sx={{ mr: 2 }} />
                                Loading file, please wait!
                              </Typography>
                            </div>
                          ) : fileUrl === "NoFile" || fileUrl === null ? (
                            <div className="App">
                              <StyledDropzone />
                            </div>
                          ) : (
                            <div className="App">
                              <div style={{ display: "flex", width: "1015px" }}>
                                <Button
                                  display="flex"
                                  type="button"
                                  variant="contained"
                                  color="error"
                                  mt={3}
                                  my={2}
                                  mr={4}
                                  onClick={handleRemoveFileClick}
                                  style={{ marginLeft: "auto" }}
                                >
                                  Remove file
                                </Button>
                                <Dialog open={showConfirmationDialog} onClose={handleConfirmationDialogClose}>
                                  <DialogTitle>Confirmation</DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>Are you sure you want to delete the invoice copy?</DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleConfirmationDialogClose} color="primary">
                                      Cancel
                                    </Button>
                                    <Button onClick={handleRemoveFile} color="primary">
                                      Delete
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                              <object
                                width={1000}
                                height={800}
                                data={fileUrl}
                                type="application/pdf"
                                style={{
                                  marginTop: "8px",
                                }}
                              >
                                <iframe title="file" src={fileUrl + "&embedded=true"}></iframe>
                              </object>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Button type="submit" variant="contained" color="primary" mt={3} my={2} mr={4}>
                      Update Invoice
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      onClick={() =>
                        setViewCustomerInvoiceDialog({
                          ...viewCustomerInvoiceDialog,
                          isOpen: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
        {/* )} */}
      </DialogContent>
    </Dialog>
  );
}
