import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

export const GlobalContext = createContext();

const hasRequiredRole = (roles, requiredRole) => {
  // console.log("Checking roles:", roles, "for required role:", requiredRole);
  return roles.includes(requiredRole);
};

const GlobalProvider = ({ children }) => {
  // Initialize state with values from local storage, if available
  const [userId, setUserId] = useState(sessionStorage.getItem("userId"));
  const [displayName, setDisplayName] = useState(sessionStorage.getItem("displayName"));
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [roles, setRoles] = useState(() => {
    const roles = sessionStorage.getItem("roles");
    return roles ? JSON.parse(roles) : []; // Ensure it defaults to an array if null
  });
  const [userName, setUserName] = useState(sessionStorage.getItem("username"));
  const [isAuthorized, setIsAuthorized] = useState(hasRequiredRole(roles, "DWSUser"));

  const fetchUserData = async () => {
    try {
      const response = await axios.get("/api/v1/GetAppUserInformation/");
      // console.log("API Response:", response.data); // This will help verify what you receive from the API
      const { id, firstname, lastname, userName, email, roles } = response.data;

      setUserId(id);
      setUserName(userName);
      setDisplayName(`${firstname} ${lastname}`);
      setEmail(email);
      setRoles(roles);

      sessionStorage.setItem("userId", id);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("roles", JSON.stringify(roles));
      sessionStorage.setItem("displayName", `${firstname} ${lastname}`);
      sessionStorage.setItem("userName", userName);

      setIsAuthorized(hasRequiredRole(roles, "DWSUser"));
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  useEffect(() => {
    // Check if essential user info is already in session storage or needs to be fetched
    const storedUserId = sessionStorage.getItem("userId");
    if (!storedUserId) {
      fetchUserData();
    } else {
      // Initialize all from session storage if present
      setUserId(storedUserId);
      setDisplayName(sessionStorage.getItem("displayName"));
      setEmail(sessionStorage.getItem("email"));
      setRoles(JSON.parse(sessionStorage.getItem("roles") || "[]"));
      setUserName(sessionStorage.getItem("username"));
      setIsAuthorized(hasRequiredRole(JSON.parse(sessionStorage.getItem("roles") || "[]"), "DWSUser"));
    }
  }, [userId]);

  const [globalData, setGlobalData] = useState({
    userId: userId,
    email: email,
    roles: roles,
    displayName: displayName,
    userName: userName,
    isAuthorized: hasRequiredRole(roles, "DWSUser"),
    // ...other initial states
  });

  // return <GlobalContext.Provider value={([globalData, setGlobalData], isAuthorized)}>{children}</GlobalContext.Provider>;
  return (
    <GlobalContext.Provider value={{ globalData, setGlobalData, isAuthorized: isAuthorized, fetchUserData }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
