import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddProductType from "../../../components/AddProductType";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
// import { ThemeProvider } from "@material-ui/core/styles";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import ViewProductTypeDialog from "../../../components/ViewProductTypeDialog";
import apiUtils from "../../../utils/apiUtils";
import DataGridExport from "../../../components/DataGridExport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const ProductTypesDataGrid = () => {
  const columns = [
    {
      field: "productTypeId",
      headerName: "ProductTypeId",
      width: 50,
      hide: false,
    },
    { field: "categoryCode", headerName: "Category Code", flex: 0.5 },
    { field: "subCode", headerName: "Sub Code", flex: 0.5 },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.5,
      valueGetter: (params) =>
        countryData.find(
          (country) => country.countryId === params.row.countryCode
        )?.countryCode || "",
    },
    {
      field: "productShortDescription",
      headerName: "Short Description",
      flex: 1,
    },
    {
      field: "productLongDescription",
      headerName: "Long Description",
      flex: 2,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Product State",
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.productCode +
                    "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
                // onButtonClick(e, params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewProductTypeDialog({
                  isOpen: true,
                  title: "Delete ProductType",
                  params: params.row,
                  test: [],
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.productCode +
                    "?",
                  // onConfirm: () => {
                  //   onEditConfirmed(e, params.row);
                  // },
                });
                // onButtonClick(e, params.row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);

  const fetchProductTypes = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/productTypes");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/productTypes");
        if (!isCancelled) {
          setTableData(response.data);
        }
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchProductTypes]);

  const countryData = apiUtils.useApiData("/api/v1/countries");

  // console.table(tableData);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewProductTypeDialog, setViewProductTypeDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  function onDeleteConfirmed(e, params) {
    var errorMessage = "";
    var submitInError = false;

    axios
      .delete("/api/v1/productTypes/" + params.productTypeId)
      .then((response) => {
        errorMessage = "Your data has been submitted successfully!";
        submitInError = false;
      })
      .catch(function (error) {
        if (error.response) {
          submitInError = true;
          if (
            error.response.data.includes(
              "Cannot insert duplicate key row in object"
            )
          ) {
          }
          var errTxt = error.response.data;
          var sb = errTxt.substring(
            errTxt.indexOf("The duplicate key value is"),
            errTxt.length - errTxt.indexOf("The duplicate key value is")
          );
          errorMessage =
            "Error submitting new Entity. There is already an existing value found in the database: " +
            sb.substring(28, sb.indexOf(")."));
        } else if (error.request) {
          submitInError = true;
        } else {
          submitInError = true;
        }
      });
    console.log(errorMessage);
    console.log(submitInError);
    if (!submitInError) {
      var tableData2 = tableData.filter(
        (productType) => productType.productTypeId !== params.productTypeId
      );
      // console.table(tableData2);
      setTableData(tableData2);
      setNotify({
        isOpen: true,
        message: params.productCode + " successfully deleted",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Cannot delete product state",
        type: "error",
      });
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Product Types
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Product Types</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid item xs={12} md={6}></Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Product Types Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view Product Types. You can use the
            below options to customize the grid.
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <AddProductType refreshGrid={fetchProductTypes} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <ViewProductTypeDialog
            viewProductTypeDialog={viewProductTypeDialog}
            setViewProductTypeDialog={setViewProductTypeDialog}
            refreshGrid={fetchProductTypes}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.productTypeId}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default ProductTypesDataGrid;
