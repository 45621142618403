import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ErrorPopup = ({ errorMessages, onClose, open }) => {
  // State to store the error data
  const [errorData, setErrorData] = React.useState([]);
  // State to store the error columns
  const [errorColumns, setErrorColumns] = React.useState([]);

  // Update the error data and columns when the errorMessages prop changes
  useEffect(() => {
    if (errorMessages.length > 0) {
      // console.table(errorMessages);
      // Extract columns from the first error message to create dynamic columns
      setErrorColumns(
        Object.keys(JSON.parse(errorMessages[0] || "{}"))
          .filter((header) => header !== "entityId")
          .filter((header) => header !== "dataEntryDate")
          .filter((header) => header !== "dataEntryOperator")
          .filter((header) => header !== "fileId")
          .filter((header) => header !== "currencyId")
          .filter((header) => header !== "invoiceVATAmountEUR")
          .filter((header) => header !== "invoiceGrossAmount")
          .map((header) => ({
            field: header,
            headerName:
              header === "invoiceVATAmountLocalCurrency" ? "VAT" : header,
            flex:
              header === "invoiceNumber" || header === "invoiceDate" ? 0.5 : 1,
          }))
      );

      // Update errorData with the parsed error messages
      setErrorData(
        errorMessages.map((message, index) => ({
          id: index,
          ...JSON.parse(message), // Parse the JSON string and spread it into the row object
        }))
      );
    } else {
      // If there are no error messages, reset the error data and columns
      setErrorData([]);
      setErrorColumns([]);
    }
  }, [errorMessages]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      {/* Set a minimum width for the popup */}
      <DialogTitle>Error Messages</DialogTitle>
      <DialogContent sx={{ height: 800, width: "100%" }}>
        {/* Adjust width of DialogContent */}
        {errorMessages.length > 0 ? ( // Check if there are error messages
          <div style={{ height: 725, width: "100%" }}>
            <DataGrid
              rows={errorData}
              columns={errorColumns}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              pageSize={10}
            />
          </div>
        ) : (
          <Typography>No error messages.</Typography> // Show a message when no errors
        )}
        <Button sx={{ mt: 4 }} variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorPopup;
