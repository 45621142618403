import React from "react";
import {
  Autocomplete,
  TextField,
  FormControl as MuiFormControl,
  MenuItem,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)``;

const SelectWrapper = ({
  name,
  rowIndex,
  selectedKey,
  optional1,
  options,
  handleChange,
  ...otherProps
}) => {
  const handleChangeInt = (_, newValue) => {
    if (newValue) {
      handleChange(newValue.key, rowIndex);
    }
  };

  const getOptionSelected = (option, value) => option.key === value;

  const getOptionLabel = (option) => option.value;

  const renderOption = (props, option) => (
    <MenuItem {...props} key={option.key} value={option.key}>
      {option.value}
    </MenuItem>
  );

  const selectedOption = options.find((option) => option.key === selectedKey);

  return (
    <React.Fragment>
      <FormControl fullWidth my={2}>
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          renderOption={renderOption}
          onChange={handleChangeInt}
          value={selectedOption || null}
          PaperComponent={({ children }) => (
            <div style={{ width: "fit-content" }}>
              <Paper>{children}</Paper>
            </div>
          )}
          renderInput={(params) => (
            <TextField {...params} {...otherProps} variant="outlined" />
          )}
        />
      </FormControl>
    </React.Fragment>
  );
};

export default SelectWrapper;
