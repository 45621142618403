import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import Select from "./SelectBox";
import styled from "@emotion/styled";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  supplierName: Yup.string().required("Required"),
  supplierStreet: Yup.string().required("Required"),
  supplierBuildingIdentifier: Yup.string().required("Required"),
  supplierPostalCode: Yup.string().required("Required"),
  supplierCity: Yup.string().required("Required"),
  // supplierProvince: Yup.string().required("Required"),
  countryId: Yup.number().required("Required"),
  // supplierCoC: Yup.number().required("Required"),
  supplierVATnr: Yup.string().required("Required"),
  supplierCode: Yup.number().required("Required"),
});

export default function ViewSupplierDialog(props) {
  const { viewSupplierDialog, setViewSupplierDialog, refreshTable } = props;
  const [countryData, setCountryData] = useState([]);
  const initialValues = {
    supplierId: viewSupplierDialog?.params?.supplierId,
    supplierName: viewSupplierDialog?.params?.supplierName,
    supplierStreet: viewSupplierDialog?.params?.supplierStreet,
    supplierBuildingIdentifier:
      viewSupplierDialog?.params?.supplierBuildingIdentifier,
    supplierPostalCode: viewSupplierDialog?.params?.supplierPostalCode,
    supplierCity: viewSupplierDialog?.params?.supplierCity,
    supplierProvince: viewSupplierDialog?.params?.supplierProvince,
    countryId: viewSupplierDialog?.params?.countryCode,
    supplierCoC: viewSupplierDialog?.params?.supplierCoC,
    supplierVATnr: viewSupplierDialog?.params?.supplierVATnr,
    supplierCode: viewSupplierDialog?.params?.supplierCode,
  };

  // console.table(viewSupplierDialog);
  // console.table(initialValues);

  useEffect(() => {
    axios
      .get("/api/v1/countries")
      .then((response) => setCountryData(response.data));
  }, []);

  // const [open, setOpen] = useState(false);
  // const [values, setValues] = useState(initialValues);
  // const [errors, setErrors] = useState({});
  // const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTimeout, setAlertTimeout] = useState(null);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    const updateSupplier = {
      supplierName: values.supplierName,
      supplierStreet: values.supplierStreet,
      supplierBuildingIdentifier: values.supplierBuildingIdentifier,
      supplierPostalCode: values.supplierPostalCode,
      supplierCity: values.supplierCity,
      supplierProvince: values.supplierProvince,
      countryCode: values.countryId,
      supplierCoC: values.supplierCoC,
      supplierVATnr: values.supplierVATnr,
      supplierCode: values.supplierCode,
    };

    // console.table(updateSupplier);

    axios
      .put("/api/v1/suppliers/" + values.supplierId, updateSupplier)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Refresh the table data after successful submission.
          refreshTable();
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          setShowAlert(true);
          setAlertTimeout(
            setTimeout(() => {
              setShowAlert(false);
            }, 5000)
          );
          // setValues(initialValues);
          // setOpen(false);
        } else {
          // Handle unsuccessful request, e.g. show error message.
          console.error("Error updating supplier.");
          setErrorMessage("Error updating supplier.");
          setSubmitInError(true);
          setShowAlert(true);
          setAlertTimeout(
            setTimeout(() => {
              setShowAlert(false);
            }, 5000)
          );
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setErrorMessage("Error: " + error.message);
        setSubmitInError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(alertTimeout);
    };
  }, [alertTimeout]);

  return (
    <Dialog
      open={viewSupplierDialog.isOpen}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        {viewSupplierDialog.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {showAlert && submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : showAlert && errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : (
                  ""
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <TextField
                        name="supplierId"
                        // inputProps={{ maxLength: 10 }}
                        label="supplierId"
                        value={values.supplierId}
                        type="hidden"
                      />
                      <Grid item md={8} xs={12}>
                        <TextField
                          name="supplierName"
                          label="Supplier Name"
                          value={values.supplierName}
                          error={Boolean(
                            touched.supplierName && errors.supplierName
                          )}
                          fullWidth
                          helperText={
                            touched.supplierName && errors.supplierName
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="supplierName"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          name="supplierCode"
                          label="Supplier Code"
                          value={values.supplierCode}
                          error={Boolean(
                            touched.supplierCode && errors.supplierCode
                          )}
                          fullWidth
                          helperText={
                            touched.supplierCode && errors.supplierCode
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="supplierCode"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={7} xs={12}>
                        <TextField
                          name="supplierStreet"
                          label="Supplier Street"
                          value={values.supplierStreet}
                          error={Boolean(
                            touched.supplierStreet && errors.supplierStreet
                          )}
                          fullWidth
                          helperText={
                            touched.supplierStreet && errors.supplierStreet
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="supplierStreet"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          name="supplierBuildingIdentifier"
                          label="Supplier Building Number"
                          value={values.supplierBuildingIdentifier}
                          error={Boolean(
                            touched.supplierBuildingIdentifier &&
                              errors.supplierBuildingIdentifier
                          )}
                          fullWidth
                          helperText={
                            touched.supplierBuildingIdentifier &&
                            errors.supplierBuildingIdentifier
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="supplierBuildingIdentifier"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name="supplierPostalCode"
                          label="Supplier Postal Code"
                          value={values.supplierPostalCode}
                          error={Boolean(
                            touched.supplierPostalCode &&
                              errors.supplierPostalCode
                          )}
                          fullWidth
                          helperText={
                            touched.supplierPostalCode &&
                            errors.supplierPostalCode
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={5} xs={12}>
                        <TextField
                          name="supplierCity"
                          label="Supplier City"
                          value={values.supplierCity}
                          error={Boolean(
                            touched.supplierCity && errors.supplierCity
                          )}
                          fullWidth
                          helperText={
                            touched.supplierCity && errors.supplierCity
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          name="supplierProvince"
                          label="Supplier Province"
                          value={values.supplierProvince}
                          error={Boolean(
                            touched.supplierProvince && errors.supplierProvince
                          )}
                          fullWidth
                          helperText={
                            touched.supplierProvince && errors.supplierProvince
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Select
                          name="countryId"
                          label="Country"
                          options={JSON.parse(
                            JSON.stringify(
                              JSON.parse(
                                JSON.stringify(countryData)
                                  .split('"countryId":')
                                  .join('"key":')
                              )
                            )
                              .split('"countryCode":')
                              .join('"value":')
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="supplierCoC"
                          label="supplier Chamber of Commerce"
                          value={values.supplierCoC}
                          error={Boolean(
                            touched.supplierCoC && errors.supplierCoC
                          )}
                          fullWidth
                          helperText={touched.supplierCoC && errors.supplierCoC}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="supplierVATnr"
                          label="Supplier VAT Number"
                          value={values.supplierVATnr}
                          error={Boolean(
                            touched.supplierVATnr && errors.supplierVATnr
                          )}
                          fullWidth
                          helperText={
                            touched.supplierVATnr && errors.supplierVATnr
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      // onClick={() => setOpen(false)}
                      my={2}
                      mr={4}
                      onClick={handleSubmit}
                    >
                      Submit Supplier
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      //   onClick={() => window.location.reload(false)}
                      my={2}
                      // marginLeft={2}
                      onClick={() =>
                        setViewSupplierDialog({
                          ...viewSupplierDialog,
                          isOpen: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
