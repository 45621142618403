import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Box,
  Tabs,
  Tab,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import CustomerEditForm from "./customereditform";
import CustomerContacts from "./customercontacts";
import CustomerNotes from "./customernotes";
import CustomerLogins from "./customerLogins";
import CustomerInvoices from "./customerinvoices";
import CustomerClaims from "./customerClaims";
import CustomerExciseClaims from "./customerExciseClaims";
import CustomerFinancial from "./customerFinancial";
import axios from "axios";
// import { tab } from "@testing-library/user-event/dist/tab";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const CustomerDataGrid = () => {
  const [searchParams] = useSearchParams();
  let customerId = searchParams.get("customerId");
  let customerNumber = searchParams.get("customerNumber");

  console.log("refgerger");

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/entities/" + customerId)
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching entities:", error);
      });
  }, [customerId]);

  let customerName = tableData?.name;
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {customerName}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Customer Overview</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 5,
          }}
        >
          <Typography variant="body2" gutterBottom>
            Click the tabs for different views.
          </Typography>

          <Box>
            <Box>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Customer Card" />
                <Tab label="Notes" />
                <Tab label="Contacts" />
                <Tab label="Services" />
                <Tab label="Logins" />
                <Tab label="Documents" />
                <Tab label="Claims" />
                <Tab label="Invoices" />
                <Tab label="Excise Claims" />
                <Tab label="License Plates" />
                <Tab label="Financial" />
              </Tabs>
            </Box>
            <Box sx={{ padding: 2 }}>
              {tabIndex === 0 && (
                <Box>
                  <Typography>
                    <CustomerEditForm customerId={customerId} />
                  </Typography>
                </Box>
              )}
              {tabIndex === 1 && (
                <Box>
                  <CustomerNotes customerId={customerId} />
                </Box>
              )}
              {tabIndex === 2 && (
                <Box>
                  <CustomerContacts customerId={customerId} />
                </Box>
              )}
              {tabIndex === 3 && (
                <Box>
                  <Typography>Services</Typography>
                </Box>
              )}
              {tabIndex === 4 && (
                <Box>
                  <CustomerLogins customerId={customerId} />
                </Box>
              )}
              {tabIndex === 5 && (
                <Box>
                  <Typography>Documents</Typography>
                </Box>
              )}
              {tabIndex === 6 && (
                <Box>
                  <CustomerClaims customerId={customerId} customerNumber={customerNumber} />
                </Box>
              )}
              {tabIndex === 7 && (
                <Box>
                  <CustomerInvoices customerId={customerId} customerNumber={customerNumber} />
                </Box>
              )}
              {tabIndex === 8 && (
                <Box>
                  <CustomerExciseClaims customerId={customerId} customerNumber={customerNumber} />
                </Box>
              )}
              {tabIndex === 9 && (
                <Box>
                  <Typography>License plates</Typography>
                </Box>
              )}
              {tabIndex === 10 && (
                <Box>
                  <CustomerFinancial customerId={customerId} customerNumber={customerNumber} />
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default CustomerDataGrid;
