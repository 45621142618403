import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import Select from "./SelectBox";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const initialValues = {
  supplierId: "",
  supplierName: "",
  supplierStreet: "",
  supplierBuildingIdentifier: "",
  supplierPostalCode: "",
  supplierCity: "",
  supplierProvince: "",
  countryId: "",
  supplierCoC: "",
  supplierVATnr: "",
  supplierCode: "",
};

const validationSchema = Yup.object().shape({
  supplierName: Yup.string().required("Required"),
  supplierStreet: Yup.string().required("Required"),
  supplierBuildingIdentifier: Yup.string().required("Required"),
  supplierPostalCode: Yup.string().required("Required"),
  supplierCity: Yup.string().required("Required"),
  // supplierProvince: Yup.string().required("Required"),
  countryId: Yup.number().required("Required"),
  // supplierCoC: Yup.number().required("Required"),
  supplierVATnr: Yup.string().required("Required"),
  supplierCode: Yup.number().required("Required"),
});

const AddCountryForm = ({ refreshTable }) => {
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/countries")
      .then((response) => setCountryData(response.data));
  }, []);

  // console.log(countryData);
  const [open, setOpen] = useState(false);
  // const [values, setValues] = useState(initialValues);
  // const [errors, setErrors] = useState({});
  // const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);
  // const [values, setValues] = useState(initialValues);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    const NewSupplier = {
      supplierName: values.supplierName,
      supplierStreet: values.supplierStreet,
      supplierBuildingIdentifier: values.supplierBuildingIdentifier,
      supplierPostalCode: values.supplierPostalCode,
      supplierCity: values.supplierCity,
      supplierProvince: values.supplierProvince,
      countryCode: values.countryId,
      supplierCoC: values.supplierCoC,
      supplierVATnr: values.supplierVATnr,
      supplierCode: values.supplierCode,
    };

    // console.log(NewSupplier);

    axios
      .post("/api/v1/suppliers", NewSupplier)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Refresh the table data after successful submission.
          refreshTable();
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          // setValues(initialValues);
          setOpen(false);
        } else {
          // Handle unsuccessful request, e.g. show error message.
          console.error("Error adding new payment type.");
          setErrorMessage("Error adding new payment type.");
          setSubmitInError(true);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setErrorMessage("Error: " + error.message);
        setSubmitInError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  //   console.log(values);
  // };

  return (
    <Card mb={0}>
      <CardContent>
        <Paper mt={0}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Supplier
            </Button>
            <Dialog
              open={open}
              onClose={() => window.location.reload(false)}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="md"
            >
              <DialogTitle id="form-dialog-title">Add Supplier</DialogTitle>
              <Divider />
              <DialogContent>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                  }) => (
                    <Card mb={6}>
                      <CardContent>
                        {submitInError ? (
                          <Alert severity="error" my={3}>
                            {errorMessage}
                          </Alert>
                        ) : errorMessage.length > 0 ? (
                          <Alert severity="success" my={3}>
                            Your data has been submitted successfully!
                          </Alert>
                        ) : (
                          ""
                        )}

                        {isSubmitting ? (
                          <Box
                            display="flex"
                            justifyContent="center"
                            my={20}
                            mx={40}
                          >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={6}>
                              <Grid item md={8} xs={12}>
                                <TextField
                                  name="supplierName"
                                  label="Supplier Name"
                                  value={values.supplierName}
                                  error={Boolean(
                                    touched.supplierName && errors.supplierName
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierName && errors.supplierName
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="supplierName"
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  name="supplierCode"
                                  label="Supplier Code"
                                  value={values.supplierCode}
                                  error={Boolean(
                                    touched.supplierCode && errors.supplierCode
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierCode && errors.supplierCode
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="supplierCode"
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={6}>
                              <Grid item md={7} xs={9}>
                                <TextField
                                  name="supplierStreet"
                                  label="Supplier Street"
                                  value={values.supplierStreet}
                                  error={Boolean(
                                    touched.supplierStreet &&
                                      errors.supplierStreet
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierStreet &&
                                    errors.supplierStreet
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="supplierStreet"
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={2} xs={3}>
                                <TextField
                                  name="supplierBuildingIdentifier"
                                  label="Supplier Building Number"
                                  value={values.supplierBuildingIdentifier}
                                  error={Boolean(
                                    touched.supplierBuildingIdentifier &&
                                      errors.supplierBuildingIdentifier
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplisupplierBuildingIdentifiererStreet &&
                                    errors.supplierBuildingIdentifier
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="supplierBuildingIdentifier"
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <TextField
                                  name="supplierPostalCode"
                                  label="Supplier Postal Code"
                                  value={values.supplierPostalCode}
                                  error={Boolean(
                                    touched.supplierPostalCode &&
                                      errors.supplierPostalCode
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierPostalCode &&
                                    errors.supplierPostalCode
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={6}>
                              <Grid item md={5} xs={12}>
                                <TextField
                                  name="supplierCity"
                                  label="Supplier City"
                                  value={values.supplierCity}
                                  error={Boolean(
                                    touched.supplierCity && errors.supplierCity
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierCity && errors.supplierCity
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  name="supplierProvince"
                                  label="Supplier Province"
                                  value={values.supplierProvince}
                                  error={Boolean(
                                    touched.supplierProvince &&
                                      errors.supplierProvince
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierProvince &&
                                    errors.supplierProvince
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <Select
                                  name="countryId"
                                  label="Country"
                                  options={JSON.parse(
                                    JSON.stringify(
                                      JSON.parse(
                                        JSON.stringify(countryData)
                                          .split('"countryId":')
                                          .join('"key":')
                                      )
                                    )
                                      .split('"countryCode":')
                                      .join('"value":')
                                  )}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={6}>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  name="supplierCoC"
                                  label="Supplier Chamber of Commerce"
                                  value={values.supplierCoC}
                                  error={Boolean(
                                    touched.supplierCoC && errors.supplierCoC
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierCoC && errors.supplierCoC
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  name="supplierVATnr"
                                  label="Supplier VAT Number"
                                  value={values.supplierVATnr}
                                  error={Boolean(
                                    touched.supplierVATnr &&
                                      errors.supplierVATnr
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.supplierVATnr &&
                                    errors.supplierVATnr
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              mt={3}
                              // onClick={() => setOpen(false)}
                              my={2}
                              mr={4}
                              // onClick={handleSubmit}
                            >
                              Submit Supplier
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              mt={3}
                              //   onClick={() => window.location.reload(false)}
                              my={2}
                              // marginLeft={2}
                              onClick={() => setOpen(false)}
                            >
                              Close
                            </Button>
                          </form>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>
          </div>
        </Paper>
      </CardContent>
    </Card>
  );
};
export default AddCountryForm;
