import React from "react";
import {
  TextField,
  MenuItem,
  FormControl as MuiFormControl,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const SelectWrapperNoFormik = ({
  name,
  options,
  handleChange,
  ...otherProps
}) => {
  const handleChangeInt = (event) => {
    const { value } = event.target;
    handleChange(value, name);
  };

  const configSelect = {
    // ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    onChange: handleChangeInt,
    fullWidth: true,
  };

  return (
    <React.Fragment>
      <FormControl fullWidth my={2}>
        <TextField {...configSelect}>
          {options.map(({ key, value }, index) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </React.Fragment>
  );
};

export default SelectWrapperNoFormik;
