import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  DataGrid,
  // GridEditModes,
  GridToolbar,
  // GridToolbarContainer,
  // GridToolbarExport,
  // GridToolbarFilterButton,
  // GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import {
  // Checkbox,
  Grid,
  // IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  // Divider as MuiDivider,
  // Paper as MuiPaper,
  // Table,
  // TableBody,
  // TableContainer,
  // TableCell,
  // TableHead,
  // TablePagination,
  Card as MuiCard,
  CardContent as MuiCardContent,
  // TableRow,
  // TableSortLabel,
  // Toolbar,
  // Tooltip,
  Typography,
  // FormControlLabel,
  // Switch,
  Divider,
} from "@mui/material";
// import {
//   Delete as DeleteIcon,
//   FilterList as FilterListIcon,
// } from "@mui/icons-material";
import { spacing } from "@mui/system";
// import axios from "../../../utils/axios";
// import { red } from "@mui/material/colors";
// import { rgba } from "polished";
import axios from "axios";

const columns = [
  { field: "entityId", headerName: "EntityID", width: 50, hide: true },
  { field: "entityNumber", headerName: "EntityNumber", flex: 1 },
  { field: "typeRelation", headerName: "TypeRelation", flex: 0.5 },
  { field: "name", headerName: "Name", flex: 1 },
];

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const CustomerDataGrid = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/entities")
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching entities:", error);
      });
  }, []);

  // console.log(tableData);

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Invoices
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Invoices</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              New Invoice
            </Button>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 5,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Customers Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view customers. You can use the
            below options to customize the customer grid.
          </Typography>
        </CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <DataGrid
              getRowId={(row) => row.entityId}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 10,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerDataGrid;
