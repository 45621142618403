import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";
import styled from "@emotion/styled";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  TextField,
  Collapse,
  Alert,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
// import apiUtils from "../utils/apiUtils";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../components/ConfirmDialog";
import DataGridExport from "../components/DataGridExport";

// Styled components
const Card = styled(MuiCard)``;
const Button = styled(MuiButton)``;

export default function ViewPreFinanceDialog(props) {
  const { viewPreFinanceDialog, setViewPreFinanceDialog, refreshPreFinanced } =
    props;
  // const customerId = viewPreFinanceDialog?.custInfo[0]?.customerId;

  const [invoiceGridData, setInvoiceGridData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [updatingClaim, setUpdatingClaim] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  // const [alertInfo, setAlertInfo] = useState({ message: "", type: "" });
  const [checked, setChecked] = React.useState(
    viewPreFinanceDialog?.params?.payedOut === 1
  );
  const [payoutAmount, setPayoutAmount] = React.useState(
    viewPreFinanceDialog?.params?.payoutAmount || ""
  );

  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info"); // can be 'error', 'info', 'success', 'warning'
  const [alertMessage, setAlertMessage] = useState("");

  const gridRef = useRef(null);

  // useEffect(() => {
  //   console.table(viewPreFinanceDialog.params.preFinanceId);
  // }, [viewPreFinanceDialog]);

  useEffect(() => {
    // Check if preFinanceId exists
    if (
      viewPreFinanceDialog.params &&
      viewPreFinanceDialog.params.preFinanceId
    ) {
      // console.table(viewPreFinanceDialog.params);

      // Fetch data using Axios
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `/api/v1/customerInvoicesPrefinanced/${viewPreFinanceDialog.params.preFinanceId}`
          );
          setInvoiceGridData(response.data);
          // setPayoutAmount(String(viewPreFinanceDialog.params.payoutAmount));
          setPayoutAmount(viewPreFinanceDialog.params.payoutAmount || "");
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
      // console.table(invoiceGridData);
    }
  }, [viewPreFinanceDialog.isOpen, viewPreFinanceDialog.params]);

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 1,
      hide: false,
    },
    {
      field: "payedOut",
      headerName: "Payed Out",
      flex: 1,
      hide: false,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
    },
    {
      field: "invoiceGrossAmount",
      headerName: "Gross Amount",
      flex: 1,
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "5px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
      align: "right",
    },
    {
      field: "invoiceVATAmountLocalCurrency",
      headerName: "Local VAT Amount",
      flex: 1,
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "5px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
      align: "right",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Invoice",
                  subTitle:
                    "Are you sure you want to delete invoice: " +
                    params.row.invoiceNumber +
                    "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
              disabled={viewPreFinanceDialog.params?.payedOut === 1} // Disable button if payedOut is 1
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {}, // Empty function for now, will be updated when needed
  });

  function onDeleteConfirmed(e, params) {
    var submitInError = false;

    let rowsToBePreFinanced = [
      {
        customerInvoiceId: params.customerInvoiceId,
        preFinanceId: 0,
      },
    ];
    const jsonRowsToBePreFinanced = JSON.stringify(rowsToBePreFinanced);

    axios
      .put(
        "/api/v1//customerInvoices/updateInvoicePreFinance/",
        jsonRowsToBePreFinanced,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        submitInError = false;
      })
      .catch(function (error) {
        if (error.response) {
          submitInError = true;
          if (
            error.response.data.includes(
              "Cannot insert duplicate key row in object"
            )
          ) {
          }
        } else if (error.request) {
          submitInError = true;
        } else {
          submitInError = true;
        }
      });
    if (!submitInError) {
      var tableData = invoiceGridData.filter(
        (invoice) => invoice.customerInvoiceId !== params.customerInvoiceId
      );
      setInvoiceGridData(tableData);
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }

  const FooterRow = () => {
    var gridTotalGrossAmount = 0.0;
    var gridTotalVATAmount = 0.0;
    invoiceGridData.forEach((row) => {
      gridTotalGrossAmount += row.invoiceGrossAmount;
      gridTotalVATAmount += row.invoiceVATAmountLocalCurrency;
    });
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontWeight: "bold", marginRight: 10 }}>
            Total Gross Amount:
          </div>
          <div>
            {gridTotalGrossAmount.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
          <div style={{ fontWeight: "bold", marginLeft: 20, marginRight: 10 }}>
            Total VAT Amount:
          </div>
          <div>
            {gridTotalVATAmount.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div>
          <GridFooter
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[15, 50, 100]}
          />
        </div>
      </div>
    );
  };

  const handleUpdatePreFinance = async () => {
    // Check if payoutDate is set when Finalized Payout is not checked
    if (!checked && selectedDate !== "0001-01-01") {
      setAlertMessage(
        "Date cannot be set while Finalized Payout is not checked"
      );
      setAlertSeverity("error");
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return;
    }

    // Only validate selectedDate if payedOut checkbox is checked
    if (checked) {
      const selectedDateObj = new Date(selectedDate);
      const creationDateObj = new Date(
        viewPreFinanceDialog?.params?.creationDate
      );

      // Check if selectedDate is a valid date and not older than creationDate
      if (
        isNaN(selectedDateObj.getTime()) ||
        selectedDateObj < creationDateObj
      ) {
        setAlertMessage(
          "Selected date is invalid or older than the creation date"
        );
        setAlertSeverity("error");
        setOpenAlert(true);
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
        return;
      }
    }

    // Validate payoutAmount
    const payoutAmountSanitized = String(payoutAmount).replace(",", ".");
    const payoutAmountFloat = parseFloat(payoutAmountSanitized);

    if (isNaN(payoutAmountFloat) || payoutAmountFloat <= 0.01) {
      setAlertMessage("Payout amount should be a decimal and higher than 0.01");
      setAlertSeverity("error");
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return;
    }

    setUpdatingClaim(true);
    // Your update logic here
    let updatedPreFinance = {
      entityId: viewPreFinanceDialog.params.entityId,
      creationDate: viewPreFinanceDialog.params.creationDate,
      payedOut: checked ? 1 : 0,
      payoutDate: checked ? selectedDate : "0001-01-01",
      payoutAmount: payoutAmountFloat,
    };
    axios
      .put(
        "/api/v1/preFinance/" + viewPreFinanceDialog.params.preFinanceId,
        updatedPreFinance
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setAlertMessage("Error updating pre-finance data");
        setAlertSeverity("error");
        setOpenAlert(true);
        setTimeout(() => {
          setOpenAlert(false);
        }, 5000);
      });

    // Assume the update was successful
    setUpdatingClaim(false);

    // Display a success message
    setAlertMessage("Pre-Finance data successfully updated.");
    setAlertSeverity("success");
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 5000);
  };

  const getRowClassName = (params) => {
    const invoiceGrossAmount = params.row.invoiceGrossAmount;
    const invoiceStateId = params.row.invoiceStateId;

    if (invoiceStateId === 4) {
      return "highlight-row-removed";
    }

    if (invoiceStateId === 3) {
      return "highlight-row-ready-for-claim";
    }

    if (invoiceStateId === 2) {
      return "highlight-row-not-claimable";
    }

    if (invoiceGrossAmount > 3000) {
      return "highlight-row";
    }

    return "";
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handlePayoutAmountChange = (event) => {
    setPayoutAmount(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [selectedDate, setSelectedDate] = useState("0001-01-01");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    // console.log(event.target.value);
  };

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Dialog
        open={viewPreFinanceDialog.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {viewPreFinanceDialog.title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Card>
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Collapse in={openAlert}>
                  <Alert
                    severity={alertSeverity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    {alertMessage}
                  </Alert>
                </Collapse>
              </Box>
              {updatingClaim ? (
                <CircularProgress />
              ) : (
                <div>
                  <Grid container xs={12} sx={{ mb: 5 }}>
                    <Grid container xs={6} spacing={1} sx={{ pr: 2 }}>
                      <Grid item xs={5} height={30}>
                        <Typography>Creation Date:</Typography>
                      </Grid>
                      <Grid item xs={5} height={30}>
                        <Typography>
                          {formatDate(
                            viewPreFinanceDialog?.params?.creationDate
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} height={30}>
                        <Typography>Payout Date:</Typography>
                      </Grid>
                      <Grid item xs={5} height={30}>
                        <Typography>
                          {viewPreFinanceDialog?.params?.payedOut === 0 ? (
                            <TextField
                              id="payoutDate"
                              type="date"
                              value={selectedDate}
                              onChange={handleDateChange}
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  padding: 1.0,
                                },
                              }}
                            />
                          ) : (
                            <Typography>
                              {new Date(
                                viewPreFinanceDialog?.params?.payoutDate
                              ) <
                              new Date(
                                viewPreFinanceDialog?.params?.creationDate
                              )
                                ? "00-00-0000"
                                : new Date(
                                    viewPreFinanceDialog?.params?.payoutDate
                                  ).toLocaleDateString("nl-NL", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} height={30}>
                        <Typography>Payout Amount:</Typography>
                      </Grid>
                      <Grid item xs={5} height={30}>
                        <Typography>
                          <TextField
                            id="payoutAmount"
                            disabled={
                              viewPreFinanceDialog?.params?.payedOut === 1
                                ? true
                                : false
                            }
                            value={payoutAmount}
                            onChange={handlePayoutAmountChange}
                            sx={{
                              "& .MuiOutlinedInput-input": {
                                padding: 1.0,
                              },
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={5} height={30}>
                        <Typography>Finalized payout:</Typography>
                      </Grid>
                      <Grid item xs={5} height={30}>
                        <Typography>
                          <Checkbox
                            id="payedOut"
                            disabled={
                              viewPreFinanceDialog?.params?.payedOut === 1
                                ? true
                                : false
                            }
                            checked={
                              viewPreFinanceDialog?.params?.payedOut === 1
                                ? true
                                : checked
                            }
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            style={{ padding: 0, margin: 0 }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <DataGridExport
                      tableData={invoiceGridData}
                      columns={columns}
                    />
                  </Grid>
                  <Grid container>
                    <DataGrid
                      getRowId={(row) => row.customerInvoiceId}
                      disableSelectionOnClick
                      disableColumnSelector
                      components={{ Footer: FooterRow, Toolbar: GridToolbar }}
                      density="compact"
                      componentsProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: false },
                          printOptions: { disableToolbarButton: false },
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 250 },
                        },
                      }}
                      pagination
                      paginationMode="client"
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[15, 50, 100]}
                      rows={invoiceGridData}
                      columns={columns}
                      ref={gridRef}
                      getRowClassName={getRowClassName}
                      sx={{
                        height: 500,
                        width: "100%",
                        borderRadius: 1,
                        backgroundColor: "background.paper",
                        boxShadow: 2,
                        // "& .MuiDataGrid-cell:hover": {
                        //   color: "primary.main",
                        // },
                        // marginTop: 0,
                        // ".highlight-row": {
                        //   bgcolor: "#ffeeba",
                        // },
                        // ".highlight-row-removed": {
                        //   bgcolor: "#fae7e7",
                        // },
                        // ".highlight-row-ready-for-claim": {
                        //   bgcolor: "#c8e6c9",
                        // },
                        // ".highlight-row-not-claimable": {
                        //   bgcolor: "#d7ccc8",
                        // },
                      }}
                    />
                  </Grid>
                </div>
              )}
              <Grid container justifyContent="flex-start" sx={{ marginTop: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdatePreFinance}
                  disabled={updatingClaim}
                >
                  Update Pre-Financed Data
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setViewPreFinanceDialog({
                      ...viewPreFinanceDialog,
                      isOpen: false,
                    });
                    refreshPreFinanced(); // Call the refreshPreFinanced function
                  }}
                  sx={{ marginLeft: 2 }}
                >
                  Close
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
