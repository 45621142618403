import React, { useState, useMemo, useCallback, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import SelectWrapper from "./SelectBox";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import apiUtils from "../utils/apiUtils";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  claimCode: Yup.string().required("Required"),
  referenceNumber: Yup.string().required("Required"),
  countryId: Yup.string().required("Required"),
  startMonth: Yup.string().required("Required"),
  endMonth: Yup.string().required("Required"),
  year: Yup.string().required("Required"),
  claimDate: Yup.date().required("Required"),
  claimStateId: Yup.string().required("Required"),
  liters: Yup.string()
    .required("Required")
    .matches(
      /^-?[0-9]+([.,][0-9]+)?$/,
      "Invalid format. Only numbers, dots, commas, and an optional negative sign are allowed."
    ),
  claimAmountEUR: Yup.string()
    .required("Required")
    .matches(
      /^-?[0-9]+([.,][0-9]+)?$/,
      "Invalid format. Only numbers, dots, commas, and an optional negative sign are allowed."
    ),
  claimAmountLocal: Yup.string()
    .required("Required")
    .matches(
      /^-?[0-9]+([.,][0-9]+)?$/,
      "Invalid format. Only numbers, dots, commas, and an optional negative sign are allowed."
    ),
});

export default function AddExciseClaimForm(props) {
  const { customerId, customerNumber, refreshGrid, isOpen, onClose } = props;

  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  const [fileContent, setFileContent] = useState(null);
  const [currentCountryCode, setCurrentCountryCode] = useState(0);
  const [currentClaimState, setCurrentClaimState] = useState("");
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const claimStateData = apiUtils.useApiData("/api/v1/claimStates");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };
  const handleRemoveFileClick = () => {
    setShowConfirmationDialog(true);
  };
  const [fileUrl, setFileUrl] = useState(null);
  useEffect(() => {
    if (isOpen) {
      setSelectedClaimDate("");
      setCurrentClaimState("");
      setFileUrl(null);
      setFileContent(null);
    }
  }, [isOpen]);

  const initialValues = {
    customerId: customerId,
    claimCode: null,
    referenceNumber: null,
    claimDate: null,
    countryId: null,
    claimAmountEUR: null,
    claimAmountLocal: null,
    startMonth: null,
    endMonth: null,
    year: null,
    liters: null,
    claimStateId: null,
  };

  var errorMessage = "";
  var submitInError = false;

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      console.log("Validation started.");
      await validationSchema.validate(values, { abortEarly: false });
    } catch (validationErrors) {
      const errors = {};
      validationErrors.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      console.log(errors);
      setErrors(errors);
      return; // Stop the form submission
    }

    if (!fileContent) {
      const shouldContinue = window.confirm(
        "No file has been uploaded. Do you want to continue without uploading a file?"
      );

      if (!shouldContinue) {
        return; // Stop the form submission
      }
    }

    console.log("Validation passed, proceeding with form submission.");
    setSubmitting(true);
    await timeOut(1500);
    // console.log("ergreg erg erg er");
    let fileId = 0;
    // let claimId = viewExciseClaimDialog?.params?.claimId;

    // console.log(invoiceId);
    // console.table(values);

    try {
      if (fileContent) {
        const fileInfo = {
          documentType: 1,
          creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
          createdBy: 1,
          fileName: fileContent.name,
          fileSize: fileContent.size,
          mimeType: fileContent.type,
        };

        const response = await axios.post("/api/v1/file", fileInfo);
        // console.table(response);
        fileId = response.data.fileId;
        var FormData = require("form-data");
        var data = new FormData();
        data.append("file", fileContent, fileId);
        await axios.post("/api/v1/azureblobstorage", data);
        // const fileIdInfo = {
        //   fileId: fileId,
        // };
        // await axios.put("/api/v1/exciseClaims/updateFileId/" + 1, fileIdInfo);

        // setIsNewFile(false);
        // setIsRemovedFile(false);
        // setIsUpdatedFile(false);
      }

      const newExciseClaim = {
        entityId: customerId,
        claimCode: values.claimCode,
        taxOfficeRefNo: values.referenceNumber,
        countryId: currentCountryCode,
        claimDate: selectedClaimDate,
        startMonth: values.startMonth,
        endMonth: values.endMonth,
        year: values.year,
        claimAmountEUR: values.claimAmountEUR.replace(/,/g, "."),
        claimAmountLocal: values.claimAmountLocal.replace(/,/g, "."),
        liters: values.liters.replace(/,/g, "."),
        fileId: fileId,
        claimStateId: currentClaimState,
      };

      console.table(newExciseClaim);
      // setEditedData(newExciseClaim);
      axios
        .post("/api/v1/exciseClaims", newExciseClaim)
        .then((response) => {
          // viewExciseClaimDialog.handleFile(newExciseClaim);
          console.log("Update OK!");
        })
        .catch(function (error) {
          console.log("Update error!");
        })
        .finally(() => {
          refreshGrid();
          onClose();
        });

      // viewExciseClaimDialog.params.fileId = fileId;
      // viewExciseClaimDialog.handleFile(editedData);
      setSubmitting(false);
    } catch (error) {
      alert(error);
      setSubmitting(false);
    } finally {
      resetForm();
      refreshGrid();
    }
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "300px",
    minWidth: "600px",
    marginTop: "150px",
    marginLeft: "200px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      const href = URL.createObjectURL(selectedFile);
      setFileUrl(href);
      // setIsNewFile(true);
      setFileContent(selectedFile);
      // setIsRemovedFile(false);
      refreshGrid();
    },
    [refreshGrid]
  );

  function StyledDropzone(props) {
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({ accept: { "application/pdf": [] }, onDropAccepted });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          <p
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            No files are currently attached to this invoice. <br />
            Drag 'n' drop the invoice file here, or click to select
          </p>
        </div>
      </div>
    );
  }

  const handleRemoveFile = () => {
    // setIsRemovedFile(true);
    setFileUrl("NoFile");
    refreshGrid();
    setShowConfirmationDialog(false);
  };

  const handleSelectedClaimDateValue = (event, setFieldValue) => {
    const newDate = event.target.value;
    setSelectedClaimDate(newDate);
    setFieldValue("claimDate", newDate);
  };

  const handleSelectedCountryChange = (event) => {
    setCurrentCountryCode(event.target.value);
  };

  const handleSelectedClaimStateChange = (event) => {
    setCurrentClaimState(event.target.value);
  };

  // console.log(viewExciseClaimDialog.params);
  console.log(props.invoiceStateArr);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">Add Excise Claim</DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          // key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : null}

                {isSubmitting ? (
                  <Box
                    minHeight="700px"
                    display="flex"
                    justifyContent="center"
                    my={20}
                    mx={40}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={3} xs={12}>
                        <Grid item xs={12}>
                          <TextField
                            disabled
                            name="entityId"
                            label="Customer number"
                            value={customerNumber}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="name"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="claimCode"
                            label="Claim code"
                            value={values.claimCode}
                            error={Boolean(
                              touched.claimCode && errors.claimCode
                            )}
                            fullWidth
                            // helperText={touched.claimCode && errors.claimCode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="claimCode"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="referenceNumber"
                            label="Reference number"
                            value={values.referenceNumber}
                            error={Boolean(
                              touched.referenceNumber && errors.referenceNumber
                            )}
                            fullWidth
                            // helperText={touched.referenceNumber && errors.referenceNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="referenceNumber"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            name="countryId"
                            label="Country"
                            onChange={handleSelectedCountryChange}
                            options={JSON.parse(
                              JSON.stringify(
                                JSON.parse(
                                  JSON.stringify(countryData)
                                    .split('"countryId":')
                                    .join('"key":')
                                )
                              )
                                .split('"countryCode":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="startMonth"
                            label="Start month"
                            value={values.startMonth}
                            error={Boolean(
                              touched.startMonth && errors.startMonth
                            )}
                            fullWidth
                            // helperText={touched.startMonth && errors.startMonth}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="startMonth"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="endMonth"
                            label="End month"
                            value={values.endMonth}
                            error={Boolean(touched.endMonth && errors.endMonth)}
                            fullWidth
                            // helperText={touched.endMonth && errors.endMonth}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="endMonth"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="year"
                            label="Year"
                            value={values.year}
                            error={Boolean(touched.year && errors.year)}
                            fullWidth
                            // helperText={touched.year && errors.year}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="year"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="claimDate"
                            name="claimDate"
                            label="Claim date"
                            type="date"
                            error={Boolean(
                              touched.claimDate && errors.claimDate
                            )}
                            // helperText={touched.claimDate && errors.claimDate}
                            value={selectedClaimDate}
                            onChange={(event) =>
                              handleSelectedClaimDateValue(event, setFieldValue)
                            }
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectWrapper
                            name="claimStateId"
                            label="Claim state"
                            value={currentClaimState}
                            onChange={handleSelectedClaimStateChange}
                            options={JSON.parse(
                              JSON.stringify(
                                JSON.parse(
                                  JSON.stringify(claimStateData)
                                    .split('"claimStateId":')
                                    .join('"key":')
                                )
                              )
                                .split('"description":')
                                .join('"value":')
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="liters"
                            label="Liters"
                            value={values.liters}
                            error={Boolean(touched.liters && errors.liters)}
                            fullWidth
                            // helperText={
                            //   touched.liters && errors.liters
                            //     ? errors.liters
                            //     : ""
                            // }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="liters"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="claimAmountEUR"
                            label="Claim amount"
                            value={values.claimAmountEUR}
                            error={Boolean(
                              touched.claimAmountEUR && errors.claimAmountEUR
                            )}
                            fullWidth
                            // helperText={
                            //   touched.claimAmountEUR && errors.claimAmountEUR
                            //     ? errors.claimAmountEUR
                            //     : ""
                            // }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="claimAmountEUR"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="claimAmountLocal"
                            label="Amount local"
                            value={values.claimAmountLocal}
                            error={Boolean(
                              touched.claimAmountLocal &&
                                errors.claimAmountLocal
                            )}
                            fullWidth
                            // helperText={
                            //   touched.claimAmountLocal &&
                            //   errors.claimAmountLocal
                            //     ? errors.claimAmountLocal
                            //     : ""
                            // }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="claimAmountLocal"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>{" "}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Grid item md={9} xs={12}>
                          {fileUrl === "" ? (
                            <div className="App">
                              <Typography
                                sx={{
                                  fontSize: "21px",
                                  mt: 2,
                                  pt: "394px",
                                  pb: "394px",
                                  display: "flex",
                                  justifyContent: "center",
                                  border: 1,
                                  borderColor: "lightgray",
                                  width: "1000px",
                                }}
                              >
                                <CircularProgress size={30} sx={{ mr: 2 }} />
                                Loading file, please wait!
                              </Typography>
                            </div>
                          ) : fileUrl === "NoFile" || fileUrl === null ? (
                            <div className="App">
                              <StyledDropzone />
                            </div>
                          ) : (
                            <div className="App">
                              <div style={{ display: "flex", width: "1015px" }}>
                                <Button
                                  display="flex"
                                  type="button"
                                  variant="contained"
                                  color="error"
                                  mt={3}
                                  my={2}
                                  mr={4}
                                  onClick={handleRemoveFileClick}
                                  style={{ marginLeft: "auto" }}
                                >
                                  Remove file
                                </Button>
                                <Dialog
                                  open={showConfirmationDialog}
                                  onClose={handleConfirmationDialogClose}
                                >
                                  <DialogTitle>Confirmation</DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                      Are you sure you want to delete the
                                      invoice copy?
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={handleConfirmationDialogClose}
                                      color="primary"
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={handleRemoveFile}
                                      color="primary"
                                    >
                                      Delete
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                              <object
                                width={1000}
                                height={800}
                                data={fileUrl}
                                type="application/pdf"
                                style={{
                                  marginTop: "8px",
                                }}
                              >
                                <iframe
                                  title="file"
                                  src={fileUrl + "&embedded=true"}
                                ></iframe>
                              </object>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      mr={4}
                    >
                      Add Excise Claim
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
