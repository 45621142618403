import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "./contexts/ThemeContext";
import { BrowserRouter } from "react-router-dom";
import { ConfirmDialogProvider } from "react-mui-confirm";
import axios from "axios";
import { AuthProvider } from "./components/auth/AuthContext";
import GlobalProvider from "./contexts/GlobalContext";

const container = document.getElementById("root");
const root = createRoot(container);

axios.interceptors.request.use(
  async (config) => {
    let accessToken = sessionStorage.getItem("accessToken");
    let accessTokenExpiry = sessionStorage.getItem("accessTokenExpiry");

    const now = Date.now();
    const isAccessTokenExpired = accessTokenExpiry ? now >= parseInt(accessTokenExpiry, 10) : true;

    // console.log("isAccessTokenExpired:", now, accessTokenExpiry, isAccessTokenExpired);
    // Refresh the token if it is expired
    if (isAccessTokenExpired) {
      // console.log("Refreshing token...");
      const refreshToken = sessionStorage.getItem("refreshToken");
      // console.log("refreshToken:", refreshToken);
      try {
        const response = await fetch("/api/refresh", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
        });

        const responseData = await response.json(); // Parse the JSON in the response
        // console.log("Token refreshed:", responseData);
        const accessTokenNew = responseData.accessToken;
        const refreshTokenNew = responseData.refreshToken;
        const expiresInNew = responseData.expiresIn;

        // Calculate new expiration time
        const newExpiryTime = now + expiresInNew * 1000; // Assuming expiresInNew is in seconds
        sessionStorage.setItem("accessToken", accessTokenNew);
        sessionStorage.setItem("refreshToken", refreshTokenNew);
        sessionStorage.setItem("accessTokenExpiry", newExpiryTime.toString());

        accessToken = accessTokenNew; // Use the new access token for the current request
      } catch (error) {
        console.error("Error refreshing token:", error);
        // Handle the error appropriately
        return Promise.reject(error);
      }
    }

    // Set the Authorization header with the access token
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <ConfirmDialogProvider>
        <AuthProvider>
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </AuthProvider>
      </ConfirmDialogProvider>
    </ThemeProvider>
  </BrowserRouter>
);
