import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewContactsDialog from "../../components/ViewContactsDialog";
import AddCompanyContact from "../../components/AddCompanyContact";
import DataGridExport from "../../components/DataGridExport";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const ContactsDataGrid = (props) => {
  const { customerId } = props;

  const columns = [
    {
      field: "contactId",
      headerName: "contactId",
      width: 50,
      hide: true,
    },
    { field: "contactName", headerName: "Name", flex: 0.5 },
    { field: "contactJobTitle", headerName: "Job Title", flex: 0.5 },
    { field: "contactEmailAddress", headerName: "Email", flex: 0.5 },
    { field: "contactMobileNumber", headerName: "Mobile Number", flex: 0.5 },
    { field: "contactDirectNumber", headerName: "Direct Number", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Contact",
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.contactName +
                    "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewContactsDialog({
                  isOpen: true,
                  title: "Edit Contact",
                  params: params.row,
                  test: [],
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.contactName +
                    "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [viewContactsDialog, setViewContactsDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const fetchContacts = useCallback(async () => {
    try {
      const response = await axios.get(
        "/api/v1/contactsByEntityId/" + customerId
      );
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching contacts by entity ID:", error);
    }
  }, [customerId]);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get(
          "/api/v1/contactsByEntityId/" + customerId
        );
        if (!isCancelled) {
          setTableData(response.data);
        }
      } catch (error) {
        console.error("Error fetching contacts by entity ID:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchContacts, customerId]);

  // console.log(tableData);
  // console.log(customerId);

  const onDeleteConfirmed = useCallback(
    (e, params) => {
      axios
        .delete("/api/v1/contacts/" + params.contactId)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
          fetchContacts();
        })
        .catch(function (error) {
          if (error.response) {
            // submitInError = true;
          }
        });

      setConfirmDialog({ ...confirmDialog, isOpen: false });
    },
    [fetchContacts, confirmDialog]
  );

  const onEditConfirmed = (e, params) => {
    setViewContactsDialog({ ...viewContactsDialog, isOpen: false });
  };

  return (
    <React.Fragment>
      <Helmet title="Company Contacts" />
      <Divider my={6} />
      <Grid item xs={12} md={6}>
        {/* <FormDialog /> */}
      </Grid>
      <Card mb={0}>
        <CardContent pb={1} sx={{ marginBottom: 0 }}>
          <Typography variant="h6" gutterBottom>
            Company Contacts
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <AddCompanyContact
            onDataRefresh={fetchContacts}
            customerId={customerId}
          />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <ViewContactsDialog
            viewContactsDialog={viewContactsDialog}
            setViewContactsDialog={setViewContactsDialog}
            onContactUpdate={fetchContacts}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.contactId}
              disableColumnFilter
              disableSelectionOnClick
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default ContactsDataGrid;
