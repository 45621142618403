import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddClaimState from "../../../components/AddClaimState";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import ViewClaimStateDialog from "../../../components/ViewClaimStateDialog";
import DataGridExport from "../../../components/DataGridExport";
// import useUserRoles from "../../../components/auth/useUserRoles";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const ClaimStatesDataGrid = () => {
  // const { roles, fetchRoles } = useUserRoles();
  // const hasFetchedRoles = useRef(false);

  // useEffect(() => {
  //   if (!hasFetchedRoles.current) {
  //     fetchRoles();
  //     hasFetchedRoles.current = true;
  //   }
  // }, [fetchRoles]);

  const columns = [
    {
      field: "claimStateId",
      headerName: "ClaimStateId",
      width: 50,
      hide: false,
    },
    { field: "shortCode", headerName: "ShortCode", flex: 0.5 },
    { field: "description", headerName: "Description", flex: 2.5 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Claim State",
                  subTitle: "Are you sure you want to delete " + params.row.shortCode + "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
                // onButtonClick(e, params.row);
              }}
              disabled={!JSON.parse(sessionStorage.getItem("roles")).includes("Admin")}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewClaimStateDialog({
                  isOpen: true,
                  title: "Delete ClaimState",
                  params: params.row,
                  test: [],
                  subTitle: "Are you sure you want to delete " + params.row.shortCode + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
                // onButtonClick(e, params.row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);

  const fetchClaimStates = useCallback(async () => {
    const response = await axios.get("/api/v1/claimStates");
    setTableData(response.data);
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      const response = await axios.get("/api/v1/claimStates");
      if (!isCancelled) {
        setTableData(response.data);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchClaimStates]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewClaimStateDialog, setViewClaimStateDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  function onEditConfirmed(e, params) {
    setViewClaimStateDialog({ ...viewClaimStateDialog, isOpen: false });
  }

  function onDeleteConfirmed(e, params) {
    axios
      .delete("/api/v1/claimstates/" + params.claimStateId)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
        fetchClaimStates();
      })
      .catch(function (error) {
        if (error.response) {
          // submitInError = true;
        }
      });

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Claim States
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Claim States</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              New Invoice
            </Button>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />
      <Grid item xs={12} md={6}>
        {/* <FormDialog /> */}
      </Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Claim States Table {/* {roles.includes("Admin") && <div>You are an admin!</div>} */}
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view Claim States. You can use the below options to customize the grid.
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <AddClaimState refreshTable={fetchClaimStates} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewClaimStateDialog
            viewClaimStateDialog={viewClaimStateDialog}
            setViewClaimStateDialog={setViewClaimStateDialog}
            refreshTable={fetchClaimStates}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.claimStateId}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              // experimentalFeatures={{ newEditingApi: true }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
            {/* <TableEditColumn showAddCommand showEditCommand /> */}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default ClaimStatesDataGrid;
