import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import Select from "./SelectBox";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const initialValues = {
  bankAccountId: "",
  entityId: "",
  shortDescription: "",
  extendedDescription: "",
  bankAccountTypeId: "",
  iban: "",
  bic: "",
  bankName: "",
  bankAddress: "",
  bankPostalCode: "",
  bankCity: "",
  countryId: "",
};

const validationSchema = Yup.object().shape({
  shortDescription: Yup.string().required("Required"),
  extendedDescription: Yup.string().required("Required"),
  bankAccountTypeId: Yup.string().required("Required"),
  iban: Yup.string().required("Required"),
  bic: Yup.string().required("Required"),
  bankName: Yup.string().required("Required"),
  bankAddress: Yup.string().required("Required"),
  bankPostalCode: Yup.string().required("Required"),
  bankCity: Yup.string().required("Required"),
  countryId: Yup.string().required("Required"),
});

const AddBankAccount = ({ onDataRefresh, customerId }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);
  const [bankAccountTypeData, setBankAccountTypeData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBankAccountType = await axios.get(
          "/api/v1/bankaccounttypes"
        );
        const responseCountry = await axios.get("/api/v1/countries");

        setBankAccountTypeData(responseBankAccountType.data);
        setCountryData(responseCountry.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    const newBankAccount = {
      entityId: customerId,
      shortDescription: values.shortDescription,
      extendedDescription: values.extendedDescription,
      bankAccountType: values.bankAccountTypeId,
      iban: values.iban,
      bic: values.bic,
      bankName: values.bankName,
      bankAddress: values.bankAddress,
      bankPostalCode: values.bankPostalCode,
      bankCity: values.bankCity,
      countryCode: values.countryId,
    };

    // console.log(newBankAccount);

    try {
      const response = await axios.post("/api/v1/bankaccounts", newBankAccount);
      if (response.status >= 200 && response.status < 300) {
        onDataRefresh();
        setErrorMessage("Your data has been submitted successfully!");
        setSubmitInError(false);
        setOpen(false);
      } else {
        console.error("Error adding new payment type.");
        setErrorMessage("Error adding new payment type.");
        setSubmitInError(true);
      }
    } catch (error) {
      console.error("Error: ", error);
      setErrorMessage("Error: " + error.message);
      setSubmitInError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card mb={0}>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        p={0}
        m={0}
      >
        <IconButton
          style={{ margin: 0, padding: 0 }}
          color="primary"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Dialog
        open={open}
        onClose={() => window.location.reload(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Add Bank Account</DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <Card mb={6}>
                <CardContent>
                  {submitInError ? (
                    <Alert severity="error" my={3}>
                      {errorMessage}
                    </Alert>
                  ) : errorMessage.length > 0 ? (
                    <Alert severity="success" my={3}>
                      Your data has been submitted successfully!
                    </Alert>
                  ) : (
                    ""
                  )}

                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={20} mx={40}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={6}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            name="iban"
                            label="IBAN"
                            value={values.iban}
                            error={Boolean(touched.iban && errors.iban)}
                            fullWidth
                            helperText={touched.iban && errors.iban}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            name="bic"
                            label="BIC"
                            value={values.bic}
                            error={Boolean(touched.bic && errors.bic)}
                            fullWidth
                            helperText={touched.bic && errors.bic}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Select
                            name="bankAccountTypeId"
                            label="Account Type"
                            options={bankAccountTypeData.map((item) => ({
                              key: item.bankAccountTypeId,
                              value: item.shortCode,
                            }))}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="shortDescription"
                            label="Short Description"
                            value={values.shortDescription}
                            error={Boolean(
                              touched.shortDescription &&
                                errors.shortDescription
                            )}
                            fullWidth
                            helperText={
                              touched.shortDescription &&
                              errors.shortDescription
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="shortDescription"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="extendedDescription"
                            label="Long Description"
                            multiline
                            rows={5}
                            value={values.extendedDescription}
                            error={Boolean(
                              touched.extendedDescription &&
                                errors.extendedDescription
                            )}
                            fullWidth
                            helperText={
                              touched.extendedDescription &&
                              errors.extendedDescription
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="extendedDescription"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="bankName"
                            label="Bank Name"
                            value={values.bankName}
                            error={Boolean(touched.bankName && errors.bankName)}
                            fullWidth
                            helperText={touched.bankName && errors.bankName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="bankName"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            name="bankAddress"
                            label="Bank Address"
                            value={values.bankAddress}
                            error={Boolean(
                              touched.bankAddress && errors.bankAddress
                            )}
                            fullWidth
                            helperText={
                              touched.bankAddress && errors.bankAddress
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            name="bankPostalCode"
                            label="Bank Postal Code"
                            value={values.bankPostalCode}
                            error={Boolean(
                              touched.bankPostalCode && errors.bankPostalCode
                            )}
                            fullWidth
                            helperText={
                              touched.bankPostalCode && errors.bankPostalCode
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            name="bankCity"
                            label="Bank City"
                            value={values.bankCity}
                            error={Boolean(touched.bankCity && errors.bankCity)}
                            fullWidth
                            helperText={touched.bankCity && errors.bankCity}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Select
                            name="countryId"
                            label="Country"
                            options={JSON.parse(
                              JSON.stringify(
                                JSON.parse(
                                  JSON.stringify(countryData)
                                    .split('"countryId":')
                                    .join('"key":')
                                )
                              )
                                .split('"countryCode":')
                                .join('"value":')
                            )}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mt={3}
                        // onClick={() => setOpen(false)}
                        my={2}
                        mr={4}
                        onClick={handleSubmit}
                      >
                        Submit Bank Account
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        mt={3}
                        //   onClick={() => window.location.reload(false)}
                        my={2}
                        // marginLeft={2}
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </Button>
                    </form>
                  )}
                </CardContent>
              </Card>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
export default AddBankAccount;
