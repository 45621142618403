import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
// import Select from "./SelectBox";
// import InputAdornment from "@mui/material/InputAdornment";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  givenName: Yup.string().required("Required"),
  surName: Yup.string().required("Required"),
  // userPrincipalName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  // companyName: Yup.string().required("Required"),
});

export default function ViewPortalLoginDialog(props) {
  const { viewPortalLoginDialog, setViewPortalLoginDialog, onPortalLoginUpdate } = props;

  console.log(viewPortalLoginDialog);

  const initialValues = {
    givenName: viewPortalLoginDialog?.params?.firstname,
    surName: viewPortalLoginDialog?.params?.lastname,
    // userName: viewPortalLoginDialog?.params?.userName,
    // companyName: viewPortalLoginDialog?.params?.entityId,
    email: viewPortalLoginDialog?.params?.email,
  };

  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      console.log(values);
      console.log(viewPortalLoginDialog);

      const updatedPortalLogin = {
        id: viewPortalLoginDialog?.params?.id,
        // companyName: values.shortDescription,
        // userPrincipalName: values.userPrincipalName + "@deltarefundsolutions.com",
        firstName: values.givenName,
        lastName: values.surName,
        mail: values.email,
      };
      console.log(updatedPortalLogin);
      const updatePortalLoginResult = await axios.put(`/api/v1/UpdateUser/${viewPortalLoginDialog?.params?.id}`, updatedPortalLogin);
      if (updatePortalLoginResult.status >= 200 && updatePortalLoginResult.status < 300) {
      }
      setStatus({ sent: true });
      setSubmitting(false);

      onPortalLoginUpdate();
      setViewPortalLoginDialog({
        ...viewPortalLoginDialog,
        isOpen: false,
      });
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={viewPortalLoginDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{viewPortalLoginDialog.title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
            <Card mb={6}>
              <CardContent>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                )}

                {status && status.submit && (
                  <Alert severity="error" my={3}>
                    {status.submit}
                  </Alert>
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" alignItems="center" my={20} mx={40} height={375}>
                    <Typography variant="h6" mr={2}>
                      Updating Portal Login
                    </Typography>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="givenName"
                          label="Firstname"
                          value={values.givenName}
                          error={Boolean(touched.givenName && errors.givenName)}
                          fullWidth
                          helperText={touched.givenName && errors.givenName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="surName"
                          label="Lastname"
                          value={values.surName}
                          error={Boolean(touched.surName && errors.surName)}
                          fullWidth
                          helperText={touched.surName && errors.surName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          disabled
                          name="userPrincipalName"
                          label="Loginname"
                          value={values.userPrincipalName}
                          error={Boolean(touched.userPrincipalName && errors.userPrincipalName)}
                          fullWidth
                          helperText={touched.userPrincipalName && errors.userPrincipalName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text" // Changed from 'shortDescription' to 'text' as it should be a valid input type
                          variant="outlined"
                          my={2}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">@deltarefundsolutions.com</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid> */}
                    {/* <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="companyName"
                          label="Company Name"
                          value={values.companyName}
                          error={Boolean(touched.companyName && errors.companyName)}
                          fullWidth
                          helperText={touched.companyName && errors.companyName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="email"
                          label="Emailaddress"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      // onClick={() => setOpen(false)}
                      my={2}
                      mr={4}
                      onClick={handleSubmit}
                    >
                      Update Portal Login
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      //   onClick={() => window.location.reload(false)}
                      my={2}
                      // marginLeft={2}
                      onClick={() => {
                        setViewPortalLoginDialog({
                          ...viewPortalLoginDialog,
                          isOpen: false,
                        });
                      }}
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
