import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddCurrency from "../../../components/AddCurrency";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
// import { ThemeProvider } from "@material-ui/core/styles";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import ViewCurrencyDialog from "../../../components/ViewCurrencyDialog";
import DataGridExport from "../../../components/DataGridExport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const CurrenciesDataGrid = () => {
  const columns = [
    {
      field: "currencyId",
      headerName: "currencyId",
      width: 50,
      hide: false,
    },
    { field: "name", headerName: "name", flex: 0.5 },
    { field: "description", headerName: "Description", flex: 2.5 },
    { field: "isoCode", headerName: "ISO Code", flex: 0.5 },
    { field: "symbol", headerName: "Symbol", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Currency",
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.shortCode +
                    "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
                // onButtonClick(e, params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewCurrencyDialog({
                  isOpen: true,
                  title: "Delete Currency",
                  params: params.row,
                  test: [],
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.shortCode +
                    "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
                // onButtonClick(e, params.row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/currencies")
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // console.table(tableData);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewCurrencyDialog, setViewCurrencyDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  function onEditConfirmed(e, params) {
    // console.log("start return params");
    // console.table(viewCurrencyDialog.test);
    // console.log("end return params");

    window.location.reload(false);

    // var tableData2 = tableData.filter(
    //   (country) => country.countryId !== params.countryId
    // );
    // console.table(tableData2);
    // setTableData(tableData2);

    setViewCurrencyDialog({ ...viewCurrencyDialog, isOpen: false });
  }

  function onDeleteConfirmed(e, params) {
    var errorMessage = "";
    var submitInError = false;

    const headers = {
      "Access-control-allow-origin": "*",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "Access-Control-Allow-Methods": "GET,POST,PUT",
    };

    axios
      .delete("/api/v1/currencies/" + params.currencyId, {
        headers: headers,
      })
      .then((response) => {
        errorMessage = "Your data has been submitted successfully!";
        submitInError = false;
      })
      .catch(function (error) {
        if (error.response) {
          submitInError = true;
          if (
            error.response.data.includes(
              "Cannot insert duplicate key row in object"
            )
          ) {
          }
          var errTxt = error.response.data;
          var sb = errTxt.substring(
            errTxt.indexOf("The duplicate key value is"),
            errTxt.length - errTxt.indexOf("The duplicate key value is")
          );
          errorMessage =
            "Error submitting new Entity. There is already an existing value found in the database: " +
            sb.substring(28, sb.indexOf(")."));
        } else if (error.request) {
          submitInError = true;
        } else {
          submitInError = true;
        }
      });
    console.log(errorMessage);
    console.log(submitInError);
    if (!submitInError) {
      var tableData2 = tableData.filter(
        (currency) => currency.currencyId !== params.currencyId
      );
      // console.table(tableData2);
      setTableData(tableData2);
      setNotify({
        isOpen: true,
        message: params.shortCode + " successfully deleted",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Cannot delete currency",
        type: "error",
      });
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
    // console.table(params);
    // console.log(params.currencyId);
    // console.table(e);
    // console.table(tableData);
    // var tableData2 = tableData.filter(
    //   (country) => country.countryId !== params.countryId
    // );
    // console.table(tableData2);
    // setTableData(tableData2);
    // var tempArr = [...this.state.tableData];
    // console.table(tempArr);

    // alert(params);
  }

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Currencies
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Currencies</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              New Invoice
            </Button>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />
      <Grid item xs={12} md={6}>
        {/* <FormDialog /> */}
      </Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Currencies Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view Currencies. You can use the
            below options to customize the grid.
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <AddCurrency />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <ViewCurrencyDialog
            viewCurrencyDialog={viewCurrencyDialog}
            setViewCurrencyDialog={setViewCurrencyDialog}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.currencyId}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              // experimentalFeatures={{ newEditingApi: true }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
            {/* <TableEditColumn showAddCommand showEditCommand /> */}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CurrenciesDataGrid;
