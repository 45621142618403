import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  // DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  // IconButton,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
// import Select from "./SelectBox";
import styled from "@emotion/styled";
// import { CalculateSharp } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  isoCode: Yup.string().required("Required"),
  symbol: Yup.string().required("Required"),
});
export default function ViewCurrencyDialog(props) {
  const { viewCurrencyDialog, setViewCurrencyDialog } = props;
  // const [open, setOpen] = useState(false);

  // console.table(viewCurrencyDialog.params);
  // console.log(viewCurrencyDialog?.params?.shortCode);

  const initialValues = {
    currencyId: viewCurrencyDialog?.params?.currencyId,
    name: viewCurrencyDialog?.params?.name,
    description: viewCurrencyDialog?.params?.description,
    isoCode: viewCurrencyDialog?.params?.isoCode,
    symbol: viewCurrencyDialog?.params?.symbol,
  };

  var errorMessage = "";
  var submitInError = false;

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const test = {
        name: values.name,
        description: values.description,
        isoCode: values.isoCode,
        symbol: values.symbol,
      };

      // console.table(test);
      // console.log(values.currencyId);
      const headers = {
        "Access-control-allow-origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        "Access-Control-Allow-Methods": "GET,POST,PUT",
      };

      axios
        .put("/api/v1/currencies/" + values.currencyId, test, {
          headers: headers,
        })
        .then((response) => {
          errorMessage = "Your data has been submitted successfully!";
          submitInError = false;
        })
        .catch(function (error) {
          if (error.response) {
            submitInError = true;
            if (
              error.response.data.includes(
                "Cannot insert duplicate key row in object"
              )
            ) {
            }
            var errTxt = error.response.data;
            var sb = errTxt.substring(
              errTxt.indexOf("The duplicate key value is"),
              errTxt.length - errTxt.indexOf("The duplicate key value is")
            );
            errorMessage =
              "Error submitting new Entity. There is already an existing value found in the database: " +
              sb.substring(28, sb.indexOf(")."));
          } else if (error.request) {
            submitInError = true;
          } else {
            submitInError = true;
          }
        });

      await timeOut(1500);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // const handleClick = () => {
  //   console.log("1");
  //   console.table(viewCurrencyDialog);
  //   console.log("2");
  //   setOpen(true);
  // };

  return (
    <Dialog
      open={viewCurrencyDialog.isOpen}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {viewCurrencyDialog.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {/* <Typography
                            variant="h6"
                            gutterBottom
                            marginBottom={10}
                          >
                            New Country Information
                          </Typography> */}

                {submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : (
                  ""
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    {/* <Typography variant="body2" gutterBottom>
                                Company Information
                              </Typography> */}
                    <Grid container spacing={6}>
                      <Grid item md={9} xs={12}>
                        <TextField
                          name="name"
                          label="name"
                          value={values.name}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="name"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name="isoCode"
                          label="ISO Code"
                          value={values.isoCode}
                          error={Boolean(touched.isoCode && errors.isoCode)}
                          fullWidth
                          helperText={touched.isoCode && errors.isoCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="isoCode"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={3} xs={12}>
                        <TextField
                          name="symbol"
                          label="Symbol"
                          value={values.symbol}
                          error={Boolean(touched.symbol && errors.symbol)}
                          fullWidth
                          helperText={touched.symbol && errors.symbol}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="symbol"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={9} xs={12}>
                        <TextField
                          name="description"
                          label="Description"
                          value={values.description}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          fullWidth
                          helperText={touched.description && errors.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="description"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      // onClick={() => setOpen(false)}
                      my={2}
                      mr={4}
                      onClick={viewCurrencyDialog.onConfirm}
                    >
                      Change Currency
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      //   onClick={() => window.location.reload(false)}
                      my={2}
                      // marginLeft={2}
                      onClick={() =>
                        setViewCurrencyDialog({
                          ...viewCurrencyDialog,
                          isOpen: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

//   <IconButton onClick={(e) => onButtonClick(e, params.row)}>
//     <DeleteIcon />
//   </IconButton>;
