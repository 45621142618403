import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "@mui/icons-material/Password";
import ContentCopy from "@mui/icons-material/ContentCopy";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const initialValues = {
  givenName: "",
  surName: "",
  mail: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  givenName: Yup.string().required("Required"),
  surName: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  mail: Yup.string().required("Required"),
});

const AddCustomerPortalLogin = ({ onDataRefresh, customerId }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    const newPortalLogin = {
      firstname: values.givenName,
      lastname: values.surName,
      email: values.mail,
      username: values.mail,
      password: values.password,
      active: 1,
      entityId: customerId,
    };

    try {
      const response = await axios.post("/api/v1/CreateAppUser", newPortalLogin);
      if (response.status >= 200 && response.status < 300) {
        onDataRefresh();
        setErrorMessage("Your data has been submitted successfully!");
        setSubmitInError(false);
        setOpen(false);
      } else {
        console.error("Error adding new user.");
        setErrorMessage("Error adding new user.");
        setSubmitInError(true);
      }
    } catch (error) {
      console.error("Error: ", error);
      setErrorMessage("Error: " + error.message);
      setSubmitInError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const generatePassword = () => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*_+-=?";
    let password = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
    return password;
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  return (
    <Card mb={0}>
      <Box display="flex" justifyContent="flex-start" alignItems="center" p={0} m={0}>
        <IconButton style={{ margin: 0, padding: 0 }} color="primary" onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">Add Customer Portal Login</DialogTitle>
        <Divider />
        <DialogContent>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
              <Card mb={6}>
                <CardContent>
                  {submitInError ? (
                    <Alert severity="error" my={3}>
                      {errorMessage}
                    </Alert>
                  ) : errorMessage.length > 0 ? (
                    <Alert severity="success" my={3}>
                      Your data has been submitted successfully!
                    </Alert>
                  ) : (
                    ""
                  )}

                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={20} mx={40}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={6}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            name="givenName"
                            label="Firstname"
                            value={values.givenName}
                            error={Boolean(touched.givenName && errors.givenName)}
                            fullWidth
                            helperText={touched.givenName && errors.givenName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            name="surName"
                            label="Lastname"
                            value={values.surName}
                            error={Boolean(touched.surName && errors.surName)}
                            fullWidth
                            helperText={touched.surName && errors.surName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="mail"
                            label="Email Address"
                            value={values.mail}
                            error={Boolean(touched.mail && errors.mail)}
                            fullWidth
                            helperText={touched.mail && errors.mail}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="password"
                            label="Password"
                            value={values.password}
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            my={2}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="generate password"
                                    onClick={() => {
                                      const newPassword = generatePassword();
                                      handleChange({
                                        target: {
                                          name: "password",
                                          value: newPassword,
                                        },
                                      });
                                    }}
                                    edge="end"
                                  >
                                    <PasswordIcon />
                                  </IconButton>
                                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                  <IconButton
                                    aria-label="copy to clipboard"
                                    onClick={() => copyToClipboard(values.password)}
                                    edge="end"
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Button type="submit" variant="contained" color="primary" mt={3} my={2} mr={4}>
                        Submit Portal Login
                      </Button>
                      <Button type="button" variant="contained" color="primary" mt={3} my={2} onClick={() => setOpen(false)}>
                        Close
                      </Button>
                    </form>
                  )}
                </CardContent>
              </Card>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default AddCustomerPortalLogin;
