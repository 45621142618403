import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddCountry from "../../../components/AddCountry";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import ViewCountryDialog from "../../../components/ViewCountryDialog";
import DataGridExport from "../../../components/DataGridExport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const CountryDataGrid = () => {
  const columns = [
    { field: "countryId", headerName: "CountryId", width: 50, hide: false },
    { field: "countryName", headerName: "CountryName", flex: 1 },
    { field: "countryCode", headerName: "CountryCode", flex: 1 },
    // { field: "currency", headerName: "CurrencyId", flex: 0.5 },
    // {
    //   field: "currency",
    //   headerName: "Currency",
    //   flex: 0.5,
    // },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.5,
      valueGetter: (params) =>
        currencyData.length > 0
          ? currencyData.find((cur) => cur.currencyId === params.row.currency)
              .isoCode
          : "",
    },
    { field: "exchangeRate", headerName: "ExchangeRate", flex: 1 },
    {
      field: "minQuarter",
      headerName: "MinQuarter",
      flex: 1,
      valueGetter: (params) =>
        formatCurrency({ ...params, value: params.value }),
    },
    {
      field: "minYear",
      headerName: "MinYear",
      flex: 1,
      valueGetter: (params) =>
        formatCurrency({ ...params, value: params.value }),
    },
    { field: "vatPercentage", headerName: "VATPercentage", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Country",
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.countryName +
                    "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewCountryDialog({
                  isOpen: true,
                  title: "View Country",
                  params: params.row,
                  test: [],
                  subTitle:
                    "Are you sure you want to update " +
                    params.row.countryName +
                    "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [viewCountryDialog, setViewCountryDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const fetchCountries = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/countries");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }, []);

  const fetchCurrencies = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/currencies");
      setCurrencyData(response.data);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/countries");
        if (!isCancelled) {
          setTableData(response.data);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchCountries]);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/currencies");
        if (!isCancelled) {
          setCurrencyData(response.data);
        }
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchCurrencies]);

  const formatCurrency = useCallback(
    (params) => {
      if (currencyData.length === 0) {
        return "";
      }
      const currencySymbol = currencyData.find(
        (element) => element.currencyId === params.row.currency
      ).symbol;
      return currencySymbol + " " + params.value.toFixed(2);
    },
    [currencyData]
  );

  // const getCurrencySymbol = useCallback(
  //   (currencyId) => {
  //     const currencySymbol = currencyData.find(
  //       (element) => element.currencyId === currencyId
  //     ).symbol;
  //     return currencySymbol;
  //   },
  //   [currencyData]
  // );

  const onDeleteConfirmed = useCallback(
    (e, params) => {
      axios
        .delete("/api/v1/countries/" + params.countryId)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
          fetchCountries();
        })
        .catch(function (error) {
          if (error.response) {
            // submitInError = true;
          }
        });

      setConfirmDialog({ ...confirmDialog, isOpen: false });
    },
    [fetchCountries, confirmDialog]
  );

  // const handleDeleteButtonClick = useCallback(
  //   (e, params) => {
  //     setConfirmDialog({
  //       isOpen: true,
  //       title: "Delete Country",
  //       subTitle: `Are you sure you want to delete ${params.row.countryName}?`,
  //       onConfirm: () => onDeleteConfirmed(e, params.row),
  //     });
  //   },
  //   [onDeleteConfirmed]
  // );

  // const handleViewButtonClick = useCallback((e, params) => {
  //   setViewCountryDialog({
  //     isOpen: true,
  //     title: "View Country",
  //     params: params.row,
  //     test: [],
  //     subTitle: `Are you sure you want to update ${params.row.countryName}?`,
  //     onConfirm: () => {
  //       onEditConfirmed(e, params.row);
  //     },
  //   });
  // }, []);

  function onEditConfirmed(e, params) {
    setViewCountryDialog({ ...viewCountryDialog, isOpen: false });
  }

  return (
    <React.Fragment>
      <Helmet title="Countries" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Countries
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Countries</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid item xs={12} md={6}></Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Countries Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table, you can search and view Countries. You can use the
            below options to customize the Country grid.
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <AddCountry refreshTable={fetchCountries} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <ViewCountryDialog
            viewCountryDialog={viewCountryDialog}
            setViewCountryDialog={setViewCountryDialog}
            refreshTable={fetchCountries}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.countryId}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
                rowsContainer: {
                  onClick: (e) => {
                    if (e.target.closest("button") || e.target.closest("a")) {
                      e.stopPropagation();
                    }
                  },
                },
              }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CountryDataGrid;
