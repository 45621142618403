import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import * as moment from "moment";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Grid,
  Box,
  IconButton,
  Divider as MuiDivider,
  CardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  Alert as MuiAlert,
  CircularProgress,
  Card as MuiCard,
  Typography,
  Collapse,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddCustomerNote from "./addCustomerNote";
import Notification from "../../components/Notification";
import { Icon } from "@fluentui/react/lib/Icon";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import ConfirmDialog from "../../components/ConfirmDialog";
import CloseIcon from "@mui/icons-material/Close";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const CustomerNotes = (props) => {
  const customerId = props.customerId;
  // const customerNumber = props.customerNumber;
  const timeOut = (time) => new Promise((res) => setTimeout(res, time));

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const fetchNoteData = useCallback(async () => {
    try {
      const response = await axios.get(
        "/api/v1/customerNoteByEntityId/" + customerId
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching customer note by entity ID:", error);
      throw error; // or return some default/error data if needed
    }
  }, [customerId]);

  useEffect(() => {
    setLoading(true);
    fetchNoteData()
      .then((data) => {
        setTableData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching note data:", error);
        setLoading(false);
      });
  }, [fetchNoteData, customerId]);

  const columns = [
    { field: "noteId", headerName: "NoteId", width: 50, hide: true },
    {
      field: "creationDate",
      headerName: "Date",
      flex: 0.5,
      valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
    },
    {
      field: "shortDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Invoice",
                  subTitle: "Are you sure you want to delete this note?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
                // onButtonClick(e, params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
    { field: "fileId", headerName: "FileId", width: 50, hide: true },
  ];
  const [fileData, setFileData] = useState([]);

  async function getFileInfo(fileId) {
    try {
      const response = await axios.get("/api/v1/file/" + fileId);
      setFileData(response.data);
    } catch (error) {
      console.error("Error fetching file info:", error);
    }
  }

  const [getNoteId, setNoteId] = useState([]);
  const [getNoteContent, setNoteContent] = useState([]);
  const [getShortDescription, setShortDescription] = useState([]);
  const [getFileId, setFileId] = useState([]);

  const doOnRowClick = (props, e) => {
    // console.log("Begin");
    // console.log(props);
    // console.log("End");
    // console.log(e);
    setNoteId(props.row.noteId);
    setShortDescription(props.row.shortDescription);
    setNoteContent(props.row.content);
    setFileId(props.row.fileId);
    getFileInfo(props.row.fileId);
    // console.table(fileData);

    // initialValues.content = getNoteContent;
    // initialValues.shortDescription = getShortDescription;
  };

  function downloadFile(props) {
    axios({
      url: "/api/v1/azureblobstorage/" + getFileId, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileData.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  // async function getNoteData() {
  //   await fetch("/api/v1/customernote")
  //     .then((data) => data.json())
  //     .then((data) => setTableData(data));
  // }

  function onDeleteConfirmed(e, params) {
    // console.table(params);

    // var errorMessage = "";
    // var submitInError = false;

    axios
      .delete("/api/v1/customernote/" + params.noteId)
      .then((response) => {
        // errorMessage = "Your data has been submitted successfully!";
        // submitInError = false;
        handleDataRefresh();
      })
      .catch(function (error) {
        if (error.response) {
          // submitInError = true;
        }
      });

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }

  // var errorMessage = "";
  // var submitInError = false;
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const test = {
        entityId: customerId,
        shortDescription: values.shortDescription,
        content: values.content,
      };

      axios
        .put("/api/v1/customerNote/" + getNoteId, test)
        .then((response) => {
          // errorMessage = "Your data has been submitted successfully!";
          // submitInError = false;
          setOpenAlert(true);
          setAlertSeverity("success");
          setAlertMessage("Note has been updated successfully!");
          setTimeout(() => {
            setOpenAlert(false);
          }, 5000);
        })
        .catch(function (error) {
          if (error.response) {
            // submitInError = true;
            setOpenAlert(true);
            setAlertSeverity("error");
            setAlertMessage("Updating note failed!");
            setTimeout(() => {
              setOpenAlert(false);
            }, 10000);
          }
        });

      // resetForm();

      // console.table(values);
      setNoteId(values.noteId);
      setShortDescription(values.shortDescription);
      setNoteContent(values.content);
      setFileId(values.fileId);

      await timeOut(1500);
      fetchNoteData();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues = {
    shortDescription: getShortDescription,
    content: getNoteContent,
    fileId: getFileId,
    noteId: getNoteId,
  };

  const validationSchema = Yup.object().shape({
    shortDescription: Yup.string().required("Required"),
    content: Yup.string().required("Required"),
    fileId: Yup.string().required("Required"),
    noteId: Yup.string().required("Required"),
  });

  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  // const [refreshData, setRefreshData] = useState(false);

  const handleDataRefresh = () => {
    setLoading(true);
    fetchNoteData()
      .then((data) => {
        setTableData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching note data:", error);
        setLoading(false);
      });
  };

  function NoteContent() {
    return (
      <Card mb={6}>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CardContent>
          {/* <Typography variant="h6" gutterBottom>
            Note info
          </Typography> */}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <Card mb={6}>
                <Box sx={{ width: "100%" }}>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={alertSeverity}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {alertMessage}
                    </Alert>
                  </Collapse>
                </Box>

                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id="shortDescription"
                      label="Short Description"
                      variant="outlined"
                      value={values.shortDescription}
                      error={Boolean(
                        touched.shortDescription && errors.shortDescription
                      )}
                      helperText={
                        touched.shortDescription && errors.shortDescription
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // defaultValue={getShortDescription}
                      fullWidth
                      my={2}
                    />
                    <TextField
                      id="content"
                      label="Content"
                      variant="outlined"
                      value={values.content}
                      error={Boolean(touched.content && errors.content)}
                      helperText={touched.content && errors.content}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // defaultValue={getNoteContent}
                      fullWidth
                      multiline
                      rows={20}
                      my={2}
                    />

                    <Divider />
                    {getFileId > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 40,
                        }}
                      >
                        Attachment:
                        <IconButton onClick={downloadFile}>
                          <Icon
                            {...getFileTypeIconProps({
                              extension: "pdf",
                              size: 24,
                            })}
                          />
                        </IconButton>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 40,
                        }}
                      >
                        <Typography>No Attachments</Typography>
                      </div>
                    )}
                    <Divider />

                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginRight: "10px" }}>Save changes</span>
                      {isSubmitting && <CircularProgress size={20} />}
                    </Button>
                    <input name="fileId" value={values.noteId} type="hidden" />
                    <input name="noteId" value={values.noteId} type="hidden" />
                  </form>
                </CardContent>
              </Card>
            )}
          </Formik>
        </CardContent>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Divider my={6} />
      <Card mb={6}>
        <AddCustomerNote
          onDataRefresh={handleDataRefresh}
          customerId={customerId}
        />
        <Notification notify={notify} setNotify={setNotify} />
        <Divider />
        <Grid container spacing={2}>
          <Grid item md={5} xs={12}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                getRowId={(row) => row.noteId}
                disableColumnFilter
                // components={{ Toolbar: GridToolbar }}
                density="compact"
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: false },
                    printOptions: { disableToolbarButton: false },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                rows={tableData}
                columns={columns}
                pageSize={12}
                onRowClick={doOnRowClick}
                sx={{
                  height: 700,
                  width: "100%",
                  borderRadius: 1,
                  backgroundColor: "background.paper",
                  boxShadow: 2,
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  marginTop: 10,
                }}
              />
            )}
          </Grid>

          <Grid item md={7} xs={12}>
            <NoteContent />
          </Grid>

          {/* <Grid item md={6} xs={12}>
            Grid 3
          </Grid> */}
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerNotes;
