import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import Select from "./SelectBox";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const initialValues = {
  countryName: "",
  countryCode: "",
  currency: "",
  exchangeRate: "",
  minQuarter: "",
  minYear: "",
  vatPercentage: "",
};

const validationSchema = Yup.object().shape({
  countryName: Yup.string().required("Required"),
  countryCode: Yup.string().required("Required"),
  currency: Yup.string().required("Required"),
  exchangeRate: Yup.number().required("Required"),
  minQuarter: Yup.number().required("Required"),
  minYear: Yup.number().required("Required"),
  vatPercentage: Yup.number().required("Required"),
});

const AddCountryForm = ({ refreshTable }) => {
  const [currencyData, setCountryData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/currencies")
      .then((response) => setCountryData(response.data));
  }, []);

  // console.log(currencyData);
  const [open, setOpen] = useState(false);
  // const [values, setValues] = useState(initialValues);
  // const [errors, setErrors] = useState({});
  // const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    const newCountry = {
      countryCode: values.countryCode,
      countryName: values.countryName,
      currency: values.currency,
      exchangeRate: values.exchangeRate,
      minQuarter: values.minQuarter,
      minYear: values.minYear,
      vatPercentage: values.vatPercentage,
    };

    axios
      .post("/api/v1/countries", newCountry)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Refresh the table data after successful submission.
          refreshTable();
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          // setValues(initialValues);
          setOpen(false);
        } else {
          // Handle unsuccessful request, e.g. show error message.
          console.error("Error adding new payment type.");
          setErrorMessage("Error adding new payment type.");
          setSubmitInError(true);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setErrorMessage("Error: " + error.message);
        setSubmitInError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card mb={0}>
      <CardContent>
        <Paper mt={0}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Country
            </Button>
            <Dialog
              open={open}
              onClose={() => window.location.reload(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Add Country</DialogTitle>
              <Divider />
              <DialogContent>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                  }) => (
                    <Card mb={6}>
                      <CardContent>
                        {submitInError ? (
                          <Alert severity="error" my={3}>
                            {errorMessage}
                          </Alert>
                        ) : errorMessage.length > 0 ? (
                          <Alert severity="success" my={3}>
                            Your data has been submitted successfully!
                          </Alert>
                        ) : (
                          ""
                        )}

                        {isSubmitting ? (
                          <Box
                            display="flex"
                            justifyContent="center"
                            my={20}
                            mx={40}
                          >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <form onSubmit={handleSubmit}>
                            {/* <Typography variant="body2" gutterBottom>
                                Company Information
                              </Typography> */}
                            <Grid container spacing={6}>
                              <Grid item md={8} xs={12}>
                                <TextField
                                  name="countryName"
                                  label="Country Name"
                                  value={values.countryName}
                                  error={Boolean(
                                    touched.countryName && errors.countryName
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.countryName && errors.countryName
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="countryName"
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  name="countryCode"
                                  label="Shortcode"
                                  value={values.countryCode}
                                  error={Boolean(
                                    touched.companyName && errors.companyName
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.companyName && errors.companyName
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="countryCode"
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={6}>
                              <Grid item md={8} xs={12}>
                                <Select
                                  name="currency"
                                  label="currency"
                                  options={JSON.parse(
                                    JSON.stringify(
                                      JSON.parse(
                                        JSON.stringify(currencyData)
                                          .split('"currencyId":')
                                          .join('"key":')
                                      )
                                    )
                                      .split('"name":')
                                      .join('"value":')
                                  )}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  name="exchangeRate"
                                  label="Exchange Rate"
                                  value={values.exchangeRate}
                                  error={Boolean(
                                    touched.exchangeRate && errors.exchangeRate
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.exchangeRate && errors.exchangeRate
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={6}>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  name="minQuarter"
                                  label="Minimum Quarter"
                                  value={values.minQuarter}
                                  error={Boolean(
                                    touched.minQuarter && errors.minQuarter
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.minQuarter && errors.minQuarter
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  name="minYear"
                                  label="Minimum Year"
                                  value={values.minYear}
                                  error={Boolean(
                                    touched.minYear && errors.minYear
                                  )}
                                  fullWidth
                                  helperText={touched.minYear && errors.minYear}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                            </Grid>

                            <Grid container spacing={6}>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  name="vatPercentage"
                                  label="VAT Percentage"
                                  value={values.vatPercentage}
                                  error={Boolean(
                                    touched.vatPercentage &&
                                      errors.vatPercentage
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.vatPercentage &&
                                    errors.vatPercentage
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="vatPercentage"
                                  variant="outlined"
                                  my={2}
                                />
                              </Grid>
                            </Grid>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              mt={3}
                              // onClick={() => setOpen(false)}
                              my={2}
                              mr={4}
                            >
                              Submit Country
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              mt={3}
                              onClick={() => setOpen(false)}
                              my={2}
                              // marginLeft={2}
                            >
                              Close
                            </Button>
                          </form>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>
          </div>
        </Paper>
      </CardContent>
    </Card>
  );
};
export default AddCountryForm;
