import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const initialValues = {
  contactName: "",
  contactJobTitle: "",
  contactEmailAddress: "",
  contactMobileNumber: "",
  contactDirectNumber: "",
};

const validationSchema = Yup.object().shape({
  contactName: Yup.string().required("Required"),
  contactJobTitle: Yup.string().required("Required"),
  contactEmailAddress: Yup.string().required("Required"),
  contactMobileNumber: Yup.string().required("Required"),
  contactDirectNumber: Yup.string().required("Required"),
});

const AddCompanyContactForm = ({ onDataRefresh, customerId }) => {
  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const newContact = {
        entityId: customerId,
        contactName: values.contactName,
        contactJobTitle: values.contactJobTitle,
        contactEmailAddress: values.contactEmailAddress,
        contactMobileNumber: values.contactMobileNumber,
        contactDirectNumber: values.contactDirectNumber,
      };

      await axios.post("/api/v1/contacts", newContact);

      setStatus({ sent: true });
      setSubmitting(false);

      // Trigger data refresh in the parent component
      onDataRefresh();
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={0}>
      <CardContent>
        <Paper mt={0}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add Contact
            </Button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Add Contact</DialogTitle>
              <Divider />
              <DialogContent>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                  }) => (
                    <Card mb={6}>
                      <CardContent>
                        {status && status.sent && (
                          <Alert severity="success" my={3}>
                            Your data has been submitted successfully!
                          </Alert>
                        )}

                        {status && status.submit && (
                          <Alert severity="error" my={3}>
                            {status.submit}
                          </Alert>
                        )}

                        {isSubmitting ? (
                          <Box
                            display="flex"
                            justifyContent="center"
                            my={20}
                            mx={40}
                          >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  name="contactName"
                                  label="Name"
                                  value={values.contactName}
                                  error={Boolean(
                                    touched.contactName && errors.contactName
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.contactName && errors.contactName
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  name="contactJobTitle"
                                  label="Job Title"
                                  value={values.contactJobTitle}
                                  error={Boolean(
                                    touched.contactJobTitle &&
                                      errors.contactJobTitle
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.contactJobTitle &&
                                    errors.contactJobTitle
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  name="contactEmailAddress"
                                  label="Email Address"
                                  value={values.contactEmailAddress}
                                  error={Boolean(
                                    touched.contactEmailAddress &&
                                      errors.contactEmailAddress
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.contactEmailAddress &&
                                    errors.contactEmailAddress
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  name="contactMobileNumber"
                                  label="Mobile Number"
                                  value={values.contactMobileNumber}
                                  error={Boolean(
                                    touched.contactMobileNumber &&
                                      errors.contactMobileNumber
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.contactMobileNumber &&
                                    errors.contactMobileNumber
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  name="contactDirectNumber"
                                  label="Direct Number"
                                  value={values.contactDirectNumber}
                                  error={Boolean(
                                    touched.contactDirectNumber &&
                                      errors.contactDirectNumber
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.contactDirectNumber &&
                                    errors.contactDirectNumber
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              mt={3}
                              disabled={isSubmitting}
                            >
                              Submit Contact
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              mt={3}
                              ml={3}
                              onClick={() => setOpen(false)}
                            >
                              Close
                            </Button>
                          </form>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>
          </div>
        </Paper>
      </CardContent>
    </Card>
  );
};

export default AddCompanyContactForm;
