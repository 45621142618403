import React from "react";
import {
  TextField,
  MenuItem,
  FormControl as MuiFormControl,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const SmallSelectBox = ({
  name,
  options,
  handleChange,
  value,
  disabled = false,
  enableSpecialBehavior = false,
  selectedClaimPreviewClaimStateId = null,
  ...otherProps
}) => {
  // Function to handle select changes
  const handleSelectChange = (event) => {
    const { value } = event.target;
    handleChange(value, name);
  };

  // Function to determine if the entire dropdown should be disabled
  const isDropdownDisabled = () => {
    if (enableSpecialBehavior) {
      return selectedClaimPreviewClaimStateId === 16;
    }
    return false;
  };

  // Function to determine if a specific option should be disabled
  const isOptionDisabled = (key) => {
    if (enableSpecialBehavior) {
      if (selectedClaimPreviewClaimStateId === 1) {
        return key === 4 || key === 16;
      } else if (selectedClaimPreviewClaimStateId === 3) {
        return key === 16;
      } else if (selectedClaimPreviewClaimStateId === 4) {
        return key === 1 || key === 3;
      }
      return false;
    }
  };

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <TextField
          select
          variant="outlined"
          label=""
          onChange={handleSelectChange}
          fullWidth
          value={value}
          disabled={isDropdownDisabled()}
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: 1.0,
            },
          }}
          {...otherProps}
        >
          {options.map(({ key, value }) => (
            <MenuItem key={key} value={key} disabled={isOptionDisabled(key)}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </React.Fragment>
  );
};

export default SmallSelectBox;
