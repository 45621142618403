import {
  // BookOpen,
  Briefcase,
  // Calendar,
  // CheckSquare,
  // LogIn,
  // CreditCard,
  // Grid,
  // Heart,
  // List,
  // Map,
  // ShoppingCart,
  // PieChart,
  Home,
  FileText,
  Globe,
  Settings,
  Sliders,
  Users,
} from "react-feather";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Home,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
    ],
  },
  {
    href: "/customers",
    icon: Users,
    title: "Customers",
    children: [
      // {
      //   href: "/customers/search",
      //   title: "Search",
      // },
      {
        href: "/customers/list",
        title: "List",
      },
      {
        href: "/customers/addcustomer",
        title: "Add Customer",
      },
      {
        href: "/customers/CustomerUploadedFiles",
        title: "Customer Uploaded Files",
      },
      // {
      //   href: "/customers/customeroverview",
      //   title: "Customer Overview",
      // },
    ],
  },
  {
    href: "/Invoices",
    icon: FileText,
    title: "Invoices",
    children: [
      {
        href: "/invoices/AllInvoices",
        title: "All Invoices",
      },
      {
        href: "/invoices/addinvoices",
        title: "Add Invoices",
      },
      {
        href: "/invoices/unassignedinvoices",
        title: "Unassigned Invoices",
      },
    ],
  },
  {
    href: "/Claims",
    icon: Briefcase,
    title: "Claims",
    children: [
      {
        href: "/claims/AllClaims",
        title: "All Claims",
      },
    ],
  },
];
const SettingsSection = [
  {
    href: "/companysettings",
    icon: Settings,
    title: "Company Settings",
    children: [
      {
        href: "/settings/companysettings/default",
        title: "Default",
      },
    ],
  },
  {
    href: "/portalsettings",
    icon: Users,
    title: "Portal Settings",
    children: [
      {
        href: "/settings/portalsettings/AccountManagers",
        title: "Account Managers",
      },
    ],
  },
  {
    href: "/globalsettings",
    icon: Globe,
    title: "Global Settings",
    children: [
      {
        href: "/settings/globalsettings/bankaccounttypes",
        title: "Bank Account Types",
      },
      {
        href: "/settings/globalsettings/claimstates",
        title: "Claim States",
      },
      {
        href: "/settings/globalsettings/countries",
        title: "Countries",
      },
      {
        href: "/settings/globalsettings/currencies",
        title: "Currencies",
      },
      {
        href: "/settings/globalsettings/invoicelabels",
        title: "Invoice Labels",
      },
      {
        href: "/settings/globalsettings/invoicestates",
        title: "Invoice States",
      },
      {
        href: "/settings/globalsettings/paymenttypes",
        title: "Payment Types",
      },
      {
        href: "/settings/globalsettings/producttypes",
        title: "Product Types",
      },
      {
        href: "/settings/globalsettings/relationtypes",
        title: "Relation Types",
      },
      {
        href: "/settings/globalsettings/suppliers",
        title: "Suppliers",
      },
    ],
  },
  // {
  //   href: "/projects",
  //   icon: Briefcase,
  //   title: "Projects",
  //   badge: "8",
  // },
  // {
  //   href: "/orders",
  //   icon: ShoppingCart,
  //   title: "Orders",
  // },
  // {
  //   href: "/invoices",
  //   icon: CreditCard,
  //   title: "Invoices",
  //   children: [
  //     {
  //       href: "/invoices",
  //       title: "List",
  //     },
  //     {
  //       href: "/invoices/detail",
  //       title: "Detail",
  //     },
  //   ],
  // },
  // {
  //   href: "/tasks",
  //   icon: CheckSquare,
  //   title: "Tasks",
  //   badge: "17",
  // },
  // {
  //   href: "/calendar",
  //   icon: Calendar,
  //   title: "Calendar",
  // },
  // {
  //   href: "/auth/sign-in",
  //   icon: LogIn,
  //   title: "Sign In",
  // },
  // {
  //   href: "/auth",
  //   icon: Users,
  //   title: "Auth",
  //   children: [
  //     {
  //       href: "/auth/sign-in",
  //       title: "Sign In",
  //     },
  //     {
  //       href: "/auth/sign-up",
  //       title: "Sign Up",
  //     },
  //     {
  //       href: "/auth/reset-password",
  //       title: "Reset Password",
  //     },
  //     {
  //       href: "/auth/404",
  //       title: "404 Page",
  //     },
  //     {
  //       href: "/auth/500",
  //       title: "500 Page",
  //     },
  //   ],
  // },
];

// const elementsSection = [
//   {
//     href: "/components",
//     icon: Grid,
//     title: "Components",
//     children: [
//       {
//         href: "/components/alerts",
//         title: "Alerts",
//       },
//       {
//         href: "/components/accordion",
//         title: "Accordion",
//       },
//       {
//         href: "/components/avatars",
//         title: "Avatars",
//       },
//       {
//         href: "/components/badges",
//         title: "Badges",
//       },
//       {
//         href: "/components/buttons",
//         title: "Buttons",
//       },
//       {
//         href: "/components/cards",
//         title: "Cards",
//       },
//       {
//         href: "/components/chips",
//         title: "Chips",
//       },
//       {
//         href: "/components/dialogs",
//         title: "Dialogs",
//       },
//       {
//         href: "/components/lists",
//         title: "Lists",
//       },
//       {
//         href: "/components/menus",
//         title: "Menus",
//       },
//       {
//         href: "/components/pagination",
//         title: "Pagination",
//       },
//       {
//         href: "/components/progress",
//         title: "Progress",
//       },
//       {
//         href: "/components/snackbars",
//         title: "Snackbars",
//       },
//       {
//         href: "/components/tooltips",
//         title: "Tooltips",
//       },
//     ],
//   },
//   {
//     href: "/charts",
//     icon: PieChart,
//     title: "Charts",
//     children: [
//       {
//         href: "/charts/chartjs",
//         title: "Chart.js",
//       },
//       {
//         href: "/charts/apexcharts",
//         title: "ApexCharts",
//       },
//     ],
//   },
//   {
//     href: "/forms",
//     icon: CheckSquare,
//     title: "Forms",
//     children: [
//       {
//         href: "/forms/pickers",
//         title: "Pickers",
//       },
//       {
//         href: "/forms/selection-controls",
//         title: "Selection Controls",
//       },
//       {
//         href: "/forms/selects",
//         title: "Selects",
//       },
//       {
//         href: "/forms/text-fields",
//         title: "Text Fields",
//       },
//       {
//         href: "/forms/editors",
//         title: "Editors",
//       },
//       {
//         href: "/forms/formik",
//         title: "Formik",
//       },
//     ],
//   },
//   {
//     href: "/tables",
//     icon: List,
//     title: "Tables",
//     children: [
//       {
//         href: "/tables/simple-table",
//         title: "Simple Table",
//       },
//       {
//         href: "/tables/advanced-table",
//         title: "Advanced Table",
//       },
//       {
//         href: "/tables/data-grid",
//         title: "Data Grid",
//       },
//     ],
//   },
//   {
//     href: "/icons",
//     icon: Heart,
//     title: "Icons",
//     children: [
//       {
//         href: "/icons/material-icons",
//         title: "Material Icons",
//       },
//       {
//         href: "/icons/feather-icons",
//         title: "Feather Icons",
//       },
//     ],
//   },
//   {
//     href: "/maps",
//     icon: Map,
//     title: "Maps",
//     children: [
//       {
//         href: "/maps/google-maps",
//         title: "Google Maps",
//       },
//       {
//         href: "/maps/vector-maps",
//         title: "Vector Maps",
//       },
//     ],
//   },
// ];

// const docsSection = [
//   {
//     href: "/documentation/welcome",
//     icon: BookOpen,
//     title: "Documentation",
//   },
//   {
//     href: "/changelog",
//     icon: List,
//     title: "Changelog",
//     badge: "v4.2.0",
//   },
// ];

const navItems = [
  {
    title: "Menu",
    pages: pagesSection,
  },
  {
    title: "Settings",
    pages: SettingsSection,
  },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default navItems;
