import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  // Alert as MuiAlert,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const Card = styled(MuiCard)(spacing);
// const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  shortCode: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

export default function ViewPaymentTypeDialog(props) {
  const { viewPaymentTypeDialog, setViewPaymentTypeDialog, refreshTable } =
    props;

  const initialValues = {
    paymentTypeId: viewPaymentTypeDialog?.params?.paymentTypeId,
    shortCode: viewPaymentTypeDialog?.params?.shortCode,
    description: viewPaymentTypeDialog?.params?.description,
  };

  const handleSubmit = async (values) => {
    try {
      const updatedPaymentType = {
        shortCode: values.shortCode,
        description: values.description,
      };

      axios
        .put("/api/v1/paymentTypes/" + values.paymentTypeId, updatedPaymentType)
        .then((response) => {
          // console.log("Your data has been submitted successfully!");
          refreshTable();
        })
        .catch(function (error) {
          console.error("Error: ", error);
        });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Dialog
      open={viewPaymentTypeDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {viewPaymentTypeDialog.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Card mb={6}>
              <CardContent>
                {/* {submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : (
                  ""
                )} */}
                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <TextField
                        name="paymentTypeId"
                        label="paymentTypeId"
                        value={values.paymentTypeId}
                        type="hidden"
                      />
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="shortCode"
                          label="Short Code"
                          value={values.shortCode}
                          error={Boolean(touched.shortCode && errors.shortCode)}
                          fullWidth
                          helperText={touched.shortCode && errors.shortCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="shortCode"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="description"
                          label="Description"
                          value={values.description}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          fullWidth
                          helperText={touched.description && errors.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="description"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      mr={4}
                      onClick={viewPaymentTypeDialog.onConfirm}
                    >
                      Change Payment Type
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      onClick={() =>
                        setViewPaymentTypeDialog({
                          ...viewPaymentTypeDialog,
                          isOpen: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
