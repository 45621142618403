import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddClaimForm from "../../components/AddClaimForm";
import { Grid, Box, Card as MuiCard, Typography, Divider, IconButton, CircularProgress } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewClaimDialog from "../../components/ViewClaimDialog";
import DataGridExport from "../../components/DataGridExport";

const Card = styled(MuiCard)(spacing);

const CustomerClaims = (props) => {
  // console.table(props);
  const customerId = props.customerId;
  const customerNumber = props.customerNumber;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const fetchClaimsByEntityId = useCallback(() => {
    setLoading(true);
    axios
      .get("/api/v1/claimsByEntityId/" + customerId)
      .then((response) => {
        setTableData(response.data);
        console.log("Response:", response);
      })
      .catch((error) => {
        console.error("Error fetching claims by entity ID:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerId]);

  useEffect(() => {
    fetchClaimsByEntityId();
  }, [fetchClaimsByEntityId]);

  //  const refreshTable = () => {
  //    fetchClaimsByEntityId();
  //  };

  // console.table(tableData);

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("/api/v1/countries");
      setCountryData(response.data);
    }
    fetchData();
  }, []);

  const columns = [
    {
      field: "claimId",
      headerName: "Claim ID",
      width: 50,
      hide: true,
    },
    { field: "claimCode", headerName: "Claim Code", flex: 1 },
    {
      field: "TaxOfficeRefNo",
      headerName: "Ref. number",
      flex: 0.7,
      valueGetter: (params) => params.row.taxOfficeRefNo,
    },
    { field: "year", headerName: "Year", flex: 0.3 },
    {
      field: "startMonth",
      headerName: "Start Month",
      flex: 0.3,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "endMonth",
      headerName: "End Month",
      flex: 0.3,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "countryId",
      headerName: "Country",
      flex: 0.3,
      valueGetter: (params) => countryData.find((country) => country.countryId === params.row.countryId).countryCode,
    },
    {
      field: "claimGrossAmount",
      headerName: "Gross Amount",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimVATAmountLocCur",
      headerName: "VAT Local",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimVATAmountEUR",
      headerName: "VAT EUR",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              disabled={params.row.claimStateId !== 1}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Claim",
                  subTitle: "Are you sure you want to delete " + params.row.shortCode + "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewClaimDialog({
                  isOpen: true,
                  title: "View Claim",
                  params: params.row,
                  countryData: countryData,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  subTitle: "Are you sure you want to delete " + params.row.claimCode + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewClaimDialog, setViewClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  function onEditConfirmed(e, params) {
    // console.log("start return params");
    // console.table(viewClaimDialog.test);
    // console.log("end return params");

    window.location.reload(false);

    setViewClaimDialog({ ...viewClaimDialog, isOpen: false });
  }

  function onDeleteConfirmed(e, params) {
    // return false;
    // Delete Claim
    axios
      .delete("/api/v1/claims/" + params.claimId)
      .then((response) => {
        // console.log(response);

        axios.put("/api/v1/customerInvoices/updateInvoiceRemoveClaimId/" + params.claimId).then((response) => {
          // console.log(response);
        });

        setNotify({
          isOpen: true,
          message: "Claim deleted successfully",
          type: "success",
        });
        fetchClaimsByEntityId();
      })
      .catch((error) => {
        console.log(error);
        setNotify({
          isOpen: true,
          message: "Error deleting claim",
          type: "error",
        });
      })
      .finally(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      });
  }

  const getRowClassName = (params) => {
    const { claimSequenceNumber, uploadStatus } = params.row;
    if (claimSequenceNumber > 0) {
      if (uploadStatus === "acknowledgement") {
        return "pastelGreen";
      } else if (uploadStatus !== "" && uploadStatus !== "acknowledgement") {
        return "pastelRed";
      } else if (uploadStatus === "") {
        return "pastelBlue";
      }
    }
    return "";
  };

  function addDynamicStyles(rules) {
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;

    rules.forEach((rule) => {
      styleSheet.insertRule(rule, styleSheet.cssRules.length);
    });
  }

  useEffect(() => {
    addDynamicStyles([
      `.pastelGreen { background-color: #b2f2bb; }`, // pastel green
      `.pastelRed { background-color: #ffc9c9; }`, // pastel red
      `.pastelBlue { background-color: #a5d8ff; }`, // pastel blue
    ]);
  }, []);

  const ColorLegend = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#b2f2bb" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload status OK
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#a5d8ff" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload in progress
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={15} height={15} bgcolor="#ffc9c9" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Error while uploading claim
        </Typography>
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      <Helmet title="Claims" />
      <Divider my={6} />
      <Card mb={6}>
        <AddClaimForm customerId={customerId} customerNumber={customerNumber} refreshTable={fetchClaimsByEntityId} />
        <Notification notify={notify} setNotify={setNotify} />
        <Divider />
        <ColorLegend />
        <Grid container spacing={2} mt={0}>
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewClaimDialog
            viewClaimDialog={viewClaimDialog}
            setViewClaimDialog={setViewClaimDialog}
            refreshTable={fetchClaimsByEntityId}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              countryData.length > 0 && (
                <DataGrid
                  getRowId={(row) => row.claimId}
                  getRowClassName={getRowClassName}
                  disableSelectionOnClick
                  disableColumnSelector
                  components={{ Toolbar: GridToolbar }}
                  density="compact"
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: false },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  rows={tableData}
                  columns={columns}
                  pageSize={15}
                  sx={{
                    height: 667,
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    marginTop: 0,
                  }}
                />
              )
            )}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerClaims;
