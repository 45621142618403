import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  // DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  // IconButton,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import Select from "./SelectBox";
import styled from "@emotion/styled";
// import { CalculateSharp } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  countryName: Yup.string().required("Required"),
  countryCode: Yup.string().required("Required"),
  currency: Yup.string().required("Required"),
  exchangeRate: Yup.number().required("Required"),
  minQuarter: Yup.number().required("Required"),
  minYear: Yup.number().required("Required"),
  vatPercentage: Yup.number().required("Required"),
});

export default function ViewCountryDialog(props) {
  const { viewCountryDialog, setViewCountryDialog, refreshTable } = props;
  const [currencyData, setCountryData] = useState([]);
  const initialValues = {
    countryId: viewCountryDialog?.params?.countryId,
    countryName: viewCountryDialog?.params?.countryName,
    countryCode: viewCountryDialog?.params?.countryCode,
    currency: viewCountryDialog?.params?.currency,
    exchangeRate: viewCountryDialog?.params?.exchangeRate,
    minQuarter: viewCountryDialog?.params?.minQuarter,
    minYear: viewCountryDialog?.params?.minYear,
    vatPercentage: viewCountryDialog?.params?.vatPercentage,
  };

  useEffect(() => {
    axios
      .get("/api/v1/currencies")
      .then((response) => setCountryData(response.data));
  }, []);

  // const [open, setOpen] = useState(false);
  // const [values, setValues] = useState(initialValues);
  // const [errors, setErrors] = useState({});
  // const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    const updateCountry = {
      countryCode: values.countryCode,
      countryName: values.countryName,
      currency: values.currency,
      exchangeRate: values.exchangeRate,
      minQuarter: values.minQuarter,
      minYear: values.minYear,
      vatPercentage: values.vatPercentage,
    };

    axios
      .put("/api/v1/countries/" + values.countryId, updateCountry)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Refresh the table data after successful submission.
          refreshTable();
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          // setValues(initialValues);
          // setOpen(false);
        } else {
          // Handle unsuccessful request, e.g. show error message.
          console.error("Error adding new payment type.");
          setErrorMessage("Error adding new payment type.");
          setSubmitInError(true);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setErrorMessage("Error: " + error.message);
        setSubmitInError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // const handleClick = () => {
  //   console.log("1");
  //   console.table(viewCountryDialog);
  //   console.log("2");
  //   setOpen(true);
  // };

  return (
    <Dialog
      open={viewCountryDialog.isOpen}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {viewCountryDialog.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {/* <Typography
                            variant="h6"
                            gutterBottom
                            marginBottom={10}
                          >
                            New Country Information
                          </Typography> */}

                {submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : (
                  ""
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    {/* <Typography variant="body2" gutterBottom>
                                Company Information
                              </Typography> */}

                    <Grid container spacing={6}>
                      <TextField
                        name="countryId"
                        // inputProps={{ maxLength: 10 }}
                        label="countryId"
                        value={values.countryId}
                        type="hidden"
                      />
                      <Grid item md={8} xs={12}>
                        <TextField
                          name="countryName"
                          label="Country Name"
                          value={values.countryName}
                          error={Boolean(
                            touched.countryName && errors.countryName
                          )}
                          fullWidth
                          helperText={touched.countryName && errors.countryName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="countryName"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          name="countryCode"
                          label="Shortcode"
                          value={values.countryCode}
                          error={Boolean(
                            touched.companyName && errors.companyName
                          )}
                          fullWidth
                          helperText={touched.companyName && errors.companyName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="countryCode"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={8} xs={12}>
                        <Select
                          name="currency"
                          label="currency"
                          options={JSON.parse(
                            JSON.stringify(
                              JSON.parse(
                                JSON.stringify(currencyData)
                                  .split('"currencyId":')
                                  .join('"key":')
                              )
                            )
                              .split('"name":')
                              .join('"value":')
                          )}
                        />
                        {/* <FormControl fullWidth my={2}>
                                    <InputLabel id="demo-simple-select-label">
                                      Currency
                                    </InputLabel>
                                    <Select
                                      name="currency"
                                      labelId="currency"
                                      id="currencye-select"
                                      value={selectCurrency}
                                      label="Currency"
                                      error={Boolean(
                                        touched.currency && errors.currency
                                      )}
                                      // helperText={touched.currency && errors.currency}
                                      onBlur={handleBlur}
                                      onChange={handleSelectChange}
                                    >
                                      {currencyData.map(
                                        ({ currencyId, name }, index) => (
                                          <MenuItem
                                            key={currencyId}
                                            value={name}
                                          >
                                            {name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl> */}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          name="exchangeRate"
                          label="Exchange Rate"
                          value={values.exchangeRate}
                          error={Boolean(
                            touched.exchangeRate && errors.exchangeRate
                          )}
                          fullWidth
                          helperText={
                            touched.exchangeRate && errors.exchangeRate
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="minQuarter"
                          label="Minimum Quarter"
                          value={values.minQuarter}
                          error={Boolean(
                            touched.minQuarter && errors.minQuarter
                          )}
                          fullWidth
                          helperText={touched.minQuarter && errors.minQuarter}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="minYear"
                          label="Minimum Year"
                          value={values.minYear}
                          error={Boolean(touched.minYear && errors.minYear)}
                          fullWidth
                          helperText={touched.minYear && errors.minYear}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="vatPercentage"
                          label="VAT Percentage"
                          value={values.vatPercentage}
                          error={Boolean(
                            touched.vatPercentage && errors.vatPercentage
                          )}
                          fullWidth
                          helperText={
                            touched.vatPercentage && errors.vatPercentage
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="vatPercentage"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      // onClick={() => setOpen(false)}
                      my={2}
                      mr={4}
                      onClick={viewCountryDialog.onConfirm}
                    >
                      Submit Country
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      //   onClick={() => window.location.reload(false)}
                      my={2}
                      // marginLeft={2}
                      onClick={() =>
                        setViewCountryDialog({
                          ...viewCountryDialog,
                          isOpen: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={viewCountryDialog.onConfirm} color="primary">
          Yes
        </Button>
        <Button
          onClick={() =>
            setViewCountryDialog({ ...viewCountryDialog, isOpen: false })
          }
          color="primary"
          autoFocus
        >
          No
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

//   <IconButton onClick={(e) => onButtonClick(e, params.row)}>
//     <DeleteIcon />
//   </IconButton>;
