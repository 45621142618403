import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  // Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  Button as MuiButton,
  Divider,
  Typography,
  // IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import SelectWrapper from "./SelectBoxNoFormik";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import ViewCustomerInvoiceDialog from "../components/ViewCustomerInvoiceDialog";
// import { ro } from "date-fns/locale";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

const initialValues = {
  name: "",
  jobTitle: "",
  email: "",
  mobile: "",
  directNumber: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  jobTitle: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  mobile: Yup.string().required("Required"),
  directNumber: Yup.string().required("Required"),
});

const AddClaimForm = (props) => {
  const { customerId, customerNumber, refreshTable } = props;
  const [supplierData, setSupplierData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/suppliers").then((response) => setSupplierData(response.data));
    }
    fetchData();
  }, []);

  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/currencies").then((response) => setCurrencyData(response.data));
  }, []);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/productTypes").then((response) => setProductData(response.data));
    }
    fetchData();
  }, []);

  function generateArrayOfYears() {
    var max = new Date().getFullYear() + 1;
    var min = max - 5;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push({ yearId: i, yearText: i });
    }
    return years;
  }

  let yearArr = generateArrayOfYears().reverse();

  // console.table(yearArr);

  const monthArr = [
    { monthId: 1, monthText: "Jan" },
    { monthId: 2, monthText: "Feb" },
    { monthId: 3, monthText: "Mar" },
    { monthId: 4, monthText: "Apr" },
    { monthId: 5, monthText: "May" },
    { monthId: 6, monthText: "Jun" },
    { monthId: 7, monthText: "Jul" },
    { monthId: 8, monthText: "Aug" },
    { monthId: 9, monthText: "Sep" },
    { monthId: 10, monthText: "Oct" },
    { monthId: 11, monthText: "Nov" },
    { monthId: 12, monthText: "Dec" },
  ];

  function FormDialog() {
    // Consts
    const [invoiceGridData, setInvoiceGridData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedStartMonth, setSelectedStartMonth] = useState("");
    const [selectedEndMonth, setSelectedEndMonth] = useState("");
    const [selectedClaimPreview, setSelectedClaimPreview] = useState([]);

    const [open, setOpen] = useState(false);
    const [claimAdded, setClaimAdded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // var submitInError = false;

    var invoiceCount = selectedClaimPreview.invoiceCount ? selectedClaimPreview.invoiceCount : 0;
    var totalGrossAmount = selectedClaimPreview.totalGrossAmount ? selectedClaimPreview.totalGrossAmount : 0;
    var totalVATAmount = selectedClaimPreview.totalVATAmount ? selectedClaimPreview.totalVATAmount : 0;
    var totalVATAmountEur = selectedClaimPreview.totalVATAmountEUR ? selectedClaimPreview.totalVATAmountEUR : 0;
    var missingInvoiceCopies = selectedClaimPreview.missingInvoiceCopies ? selectedClaimPreview.missingInvoiceCopies : 0;

    const columns = [
      {
        field: "invoiceNumber",
        headerName: "Invoice Number",
        flex: 0.5,
        hide: false,
      },
      {
        field: "invoiceDate",
        headerName: "Invoice Date",
        flex: 0.5,
        valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
      },
      {
        field: "supplierId",
        headerName: "Supplier",
        flex: 0.5,
        valueGetter: (params) => supplierData.find((supplier) => supplier.supplierId === params.row.supplierId).supplierName,
      },
      {
        field: "countryCode",
        headerName: "Country",
        flex: 0.5,
        valueGetter: (params) => countryData.find((country) => country.countryId === params.row.countryCode).countryCode,
      },
      {
        field: "currencyId",
        headerName: "Currency",
        flex: 0.5,
        valueGetter: (params) => currencyData.find((cur) => cur.currencyId === params.row.currencyId).isoCode,
      },
      {
        field: "invoiceGrossAmount",
        headerName: "Gross Amount",
        flex: 0.5,
        valueFormatter: ({ value }) => value.toFixed(2).replace(".", ","),
      },
      {
        field: "invoiceVATAmountLocalCurrency",
        headerName: "VAT Amount",
        flex: 0.5,
        valueFormatter: ({ value }) => value.toFixed(2).replace(".", ","),
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   flex: 0.5,
      //   renderCell: (params) => {
      //     return (
      //       <Box>
      //         <IconButton
      //           onClick={(e) => {
      //             setViewCustomerInvoiceDialog({
      //               isOpen: true,
      //               downloadFile: true,
      //               title: "Manage Invoice",
      //               params: params.row,
      //               custInfo: [
      //                 {
      //                   customerId: customerId,
      //                   customerNumber: customerNumber,
      //                 },
      //               ],
      //               subTitle:
      //                 "Are you sure you want to manage " +
      //                 params.row.contactName +
      //                 "?",
      //               onConfirm: () => {
      //                 onEditConfirmed(e, params.row);
      //               },
      //             });
      //           }}
      //         >
      //           <VisibilityIcon />
      //         </IconButton>
      //       </Box>
      //     );
      //   },
      // },
    ];

    const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
      custInfo: [],
    });

    // function onEditConfirmed(e, params) {
    //   e.preventDefault();
    //   setViewCustomerInvoiceDialog({
    //     ...viewCustomerInvoiceDialog,
    //     isOpen: false,
    //   });
    // }

    const [countryData, setCountryData] = useState([]);

    useEffect(() => {
      async function fetchData() {
        axios.get("/api/v1/countries").then((response) => setCountryData(response.data));
      }
      fetchData();
    }, []);

    const handleSubmit = () => {};

    const handleCountryValue = (value, name) => {
      // console.log(value);
      setSelectedCountry(value);
    };
    const handleYearValue = (value, name) => {
      // console.log(value);
      setSelectedYear(value);
    };
    const handleStartMonthValue = (value, name) => {
      // console.log(value);
      setSelectedStartMonth(value);
    };
    const handleEndMonthValue = (value, name) => {
      // console.log(value);
      setSelectedEndMonth(value);
    };

    const handlePreview = async () => {
      getPreviewData();
      // console.table(selectedClaimPreview);
    };
    // console.table(selectedClaimPreview);
    // console.table(invoiceGridData);
    const getPreviewData = async () => {
      const test = {
        entityId: parseInt(customerId, 0),
        country: selectedCountry,
        year: selectedYear,
        startMonth: selectedStartMonth,
        endMonth: selectedEndMonth,
      };

      await axios.post("/api/v1/claims/claimPreview", test).then((response) => {
        setSelectedClaimPreview(response.data);

        // Filter the invoices list based on claimCode and claimStateId
        // const filteredInvoices = response.data.customerInvoices.filter(
        //   (invoice) => invoice.claimId === null || invoice.claimId === 0
        // );

        setInvoiceGridData(response.data.customerInvoices);
      });
    };

    const handleAddClaim = () => {
      let hasErrors = false;
      if (!selectedCountry) {
        hasErrors = true;
      }
      if (!selectedYear) {
        hasErrors = true;
      }
      if (!selectedStartMonth) {
        hasErrors = true;
      }
      if (!selectedEndMonth) {
        hasErrors = true;
      }
      if (invoiceGridData.length === 0) {
        hasErrors = true;
      }
      if (hasErrors) {
        return false;
      }

      //Create claim in DB
      // console.table(invoiceGridData);
      const preFinanceState = invoiceGridData.some((invoice) => invoice.preFinanceId && invoice.preFinanceId > 0) ? "11" : "00";

      let claimArray = {
        entityId: parseInt(customerId, 0),
        claimCode:
          customerNumber +
          selectedCountry.toString().padStart(2, "0") +
          selectedYear.toString().slice(2) +
          selectedStartMonth.toString().padStart(2, "0") +
          selectedEndMonth.toString().padStart(2, "0") +
          "0" +
          preFinanceState,
        startMonth: selectedStartMonth,
        endMonth: selectedEndMonth,
        year: selectedYear,
        countryId: selectedCountry,
        claimGrossAmount: selectedClaimPreview.totalGrossAmount,
        claimVATAmountLocCur: selectedClaimPreview.totalVATAmount,
        claimVATAmountEUR: selectedClaimPreview.totalVATAmountEUR,
        paymentTypeId: 2,
        paymentToDelta: preFinanceState === "11" ? 1 : 0,
        claimStateId: 1,
      };

      let invoicesToBeUpdated = [];
      console.log(claimArray);

      axios
        .post("/api/v1/claims/claimInitialCreate", claimArray)
        .then((response) => {
          let initClaimId = response.data.claimId;

          console.log(response.data);

          invoicesToBeUpdated = invoiceGridData.map((row) => {
            if (row.invoiceStateId.toString() !== "4") {
              return {
                ...row,
                // invoiceStateId: 3,
                claimId: initClaimId,
              };
            }
            return row;
          });

          // console.log(JSON.stringify(invoicesToBeUpdated));

          return axios.put("/api/v1/customerInvoices/updateInvoiceClaimGridBulk", invoicesToBeUpdated);
        })
        .then((response) => {
          // console.log(response.data);
        })
        .catch((error) => {
          setErrorMessage("Something went wrong while creating the claim.");
        })
        .finally(() => {
          setClaimAdded(true);
          refreshTable();
          setTimeout(() => {
            setClaimAdded(false);
          }, 5000);
        });
    };

    return (
      <Card mb={0}>
        <CardContent>
          <Paper mt={0}>
            <div>
              <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Create Pre-Claim
              </Button>
              <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="xl">
                <DialogTitle id="form-dialog-title">Add Pre-Claim for customer {customerNumber}</DialogTitle>
                <Divider />
                <DialogContent>
                  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => (
                      <Card mb={6}>
                        <CardContent>
                          {claimAdded ? (
                            <Alert severity="error" my={3}>
                              {errorMessage}
                            </Alert>
                          ) : errorMessage.length > 0 ? (
                            <Alert severity="success" my={3}>
                              Your data has been submitted successfully!
                            </Alert>
                          ) : (
                            ""
                          )}
                          <Grid container xs={12} sx={{ mb: 5 }}>
                            <Grid container xs={6} spacing={5} sx={{ pr: 2 }}>
                              <Grid item xs={5}>
                                <SelectWrapper
                                  name="countryId"
                                  labelColor="#f64435"
                                  label="Country"
                                  options={JSON.parse(
                                    JSON.stringify(JSON.parse(JSON.stringify(countryData).split('"countryId":').join('"key":')))
                                      .split('"countryCode":')
                                      .join('"value":')
                                  )}
                                  handleChange={handleCountryValue}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <SelectWrapper
                                  name="year"
                                  label="Year"
                                  options={JSON.parse(
                                    JSON.stringify(JSON.parse(JSON.stringify(yearArr).split('"yearId":').join('"key":')))
                                      .split('"yearText":')
                                      .join('"value":')
                                  )}
                                  handleChange={handleYearValue}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <SelectWrapper
                                  name="startMonth"
                                  label="Start month"
                                  options={JSON.parse(
                                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                                      .split('"monthText":')
                                      .join('"value":')
                                  )}
                                  handleChange={handleStartMonthValue}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <SelectWrapper
                                  name="endMonth"
                                  label="End month"
                                  options={JSON.parse(
                                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                                      .split('"monthText":')
                                      .join('"value":')
                                  )}
                                  handleChange={handleEndMonthValue}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Button onClick={handlePreview} sx={{ mt: 6 }} variant="outlined">
                                  Preview Pre-Claim
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item xs={6} backgroundColor="#376fd0">
                              <Grid item xs={12} sx={{ p: 5, pt: 3 }}>
                                <Grid container xs={12}>
                                  <Grid item xs={5}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>Invoice Count:</Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>{invoiceCount}</Typography>
                                  </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                  <Grid item xs={5}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>Total Gross Amount:</Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>{totalGrossAmount}</Typography>
                                  </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                  <Grid item xs={5}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>Total VAT Amount:</Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>{totalVATAmount}</Typography>
                                  </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                  <Grid item xs={5}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>Total VAT Amount EUR:</Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>{totalVATAmountEur}</Typography>
                                  </Grid>
                                </Grid>
                                <Grid container xs={12}>
                                  <Grid item xs={5}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>Missing Invoice Copies:</Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography sx={{ fontSize: 22, color: "white" }}>{missingInvoiceCopies}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewCustomerInvoiceDialog
                              viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
                              setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
                              countryArr={countryData}
                              supplierArr={supplierData}
                              currencyArr={currencyData}
                              productArr={productData}
                            />
                            <DataGrid
                              getRowId={(row) => row.customerInvoiceId}
                              disableColumnFilter
                              disableSelectionOnClick
                              disableColumnSelector
                              components={{ Toolbar: GridToolbar }}
                              density="compact"
                              componentsProps={{
                                toolbar: {
                                  csvOptions: { disableToolbarButton: false },
                                  printOptions: { disableToolbarButton: false },
                                  showQuickFilter: true,
                                  quickFilterProps: { debounceMs: 250 },
                                },
                              }}
                              rows={invoiceGridData}
                              columns={columns}
                              pageSize={15}
                              sx={{
                                height: 667,
                                width: "100%",
                                borderRadius: 1,
                                backgroundColor: "background.paper",
                                boxShadow: 2,
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                marginTop: 0,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" mt={3} onClick={handleAddClaim} my={2} mr={4}>
                              Create Pre-Claim
                            </Button>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              mt={3}
                              onClick={() => {
                                setOpen(false);
                                setInvoiceGridData([]);
                                setSelectedClaimPreview([]);
                              }}
                              my={2}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </CardContent>
                      </Card>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
            </div>
          </Paper>
        </CardContent>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <FormDialog />
    </React.Fragment>
  );
};
export default AddClaimForm;
