import React, { useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const initialValues = {
  shortCode: "",
  description: "",
};

const AddPaymentTypeForm = ({ refreshTable }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!values.shortCode || !values.description) {
      setErrors({
        shortCode: !values.shortCode ? "Required" : "",
        description: !values.description ? "Required" : "",
      });
      return;
    }

    setSubmitting(true);

    const newPaymentType = {
      shortCode: values.shortCode,
      description: values.description,
    };

    axios
      .post("/api/v1/paymentTypes", newPaymentType)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Refresh the table data after successful submission.
          refreshTable();
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          setValues(initialValues);
          setOpen(false);
        } else {
          // Handle unsuccessful request, e.g. show error message.
          console.error("Error adding new payment type.");
          setErrorMessage("Error adding new payment type.");
          setSubmitInError(true);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setErrorMessage("Error: " + error.message);
        setSubmitInError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Card mb={0}>
        <CardContent>
          <Paper mt={0}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Payment Type
              </Button>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add Payment Type
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Card mb={6}>
                    <CardContent>
                      {submitInError ? (
                        <Alert severity="error" my={3}>
                          {errorMessage}
                        </Alert>
                      ) : errorMessage.length > 0 ? (
                        <Alert severity="success" my={3}>
                          Your data has been submitted successfully!
                        </Alert>
                      ) : (
                        ""
                      )}

                      {isSubmitting ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          my={20}
                          mx={40}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={6}>
                            <Grid item md={6} xs={12}>
                              <TextField
                                name="shortCode"
                                label="Short Code"
                                value={values.shortCode}
                                error={Boolean(errors.shortCode)}
                                fullWidth
                                helperText={errors.shortCode}
                                onChange={handleChange}
                                type="text"
                                variant="outlined"
                                my={2}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item md={12} xs={12}>
                              <TextField
                                name="description"
                                label="Description"
                                value={values.description}
                                error={Boolean(errors.description)}
                                fullWidth
                                helperText={errors.description}
                                onChange={handleChange}
                                type="text"
                                variant="outlined"
                                my={2}
                              />
                            </Grid>
                          </Grid>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            mt={3}
                            my={2}
                            mr={4}
                          >
                            Submit Payment Type
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            mt={3}
                            onClick={() => setOpen(false)}
                            my={2}
                          >
                            Close
                          </Button>
                        </form>
                      )}
                    </CardContent>
                  </Card>
                </DialogContent>
              </Dialog>
            </div>
          </Paper>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
export default AddPaymentTypeForm;
