import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridFooter, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
  Button,
  Alert,
  CircularProgress,
  Collapse,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import axios from "axios";
import ViewCustomerInvoiceDialog from "../../components/ViewCustomerInvoiceDialog";
import moment from "moment";
import SelectWrapper from "../../components/SelectBoxDataGridCell";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataGridExport from "../../components/DataGridExport";
import ConfirmDialog from "../../components/ConfirmDialog";
import ViewClaimDialog from "../../components/ViewClaimDialog";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const AllInvoices = () => {
  const [pageSize, setPageSize] = React.useState(15);
  const [loading, setLoading] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [invoiceStateData, setInvoiceStateData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [customerPreFinanceData, setCustomerPreFinanceData] = useState([]);
  const [customerClaimsData, setCustomerClaimsData] = useState([]);
  // const [customerData, setCustomerData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("any");
  const [selectedYear, setSelectedYear] = useState("any");
  const [selectedStartMonth, setSelectedStartMonth] = useState("any");
  const [selectedEndMonth, setSelectedEndMonth] = useState("any");
  const [selectedState, setSelectedState] = useState("any");
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedInvoiceCount, setSelectedInvoiceCount] = useState(0);
  const [selectedInvoiceTotalAmount, setSelectedInvoiceTotalAmount] = useState(0);
  const [selectedInvoiceTotalVATAmount, setSelectedInvoiceTotalVATAmount] = useState(0);

  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    try {
      const [suppliers, countries, invoiceStates, products, currencies, preFinance, claims] = await Promise.all([
        axios.get("/api/v1/suppliers"),
        axios.get("/api/v1/countries"),
        axios.get("/api/v1/invoiceStates"),
        axios.get("/api/v1/productTypes"),
        axios.get("/api/v1/currencies"),
        axios.get("/api/v1/preFinance/"),
        axios.get("/api/v1/claims/"),
        // axios.get("/api/v1/entities/"),
      ]);

      setSupplierData(suppliers.data);
      setCountryData(countries.data);
      setInvoiceStateData(invoiceStates.data);
      setProductData(products.data);
      setCurrencyData(currencies.data);
      setCustomerPreFinanceData(preFinance.data);
      setCustomerClaimsData(claims.data);
      // setCustomerData(customers.data);
    } catch (error) {
      console.error("Error fetching initial data", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getPreviewData = useCallback(async () => {
    if (supplierData.length === 0) return;

    setLoading(true);
    try {
      const response = await axios.get("/api/v1/customerInvoicesWithProductCodes");
      const invoicesReturn = response.data;

      const enhancedInvoices = invoicesReturn.map((invoice) => {
        const supplier = supplierData.find((supplier) => supplier.supplierId === invoice.supplierId);
        const country = countryData.find((country) => country.countryId === invoice.countryCode);
        const currency = currencyData.find((currency) => currency.currencyId === invoice.currencyId);
        const invoiceState = invoiceStateData.find((state) => state.invoiceStateId === invoice.invoiceStateId);
        const claim = customerClaimsData.find((c) => String(c.claimId) === String(invoice.claimId).trim());

        let claimStateLabel = "";
        if (claim) {
          switch (claim.claimStateId) {
            case 4:
              claimStateLabel = "Pending";
              break;
            case 16:
              claimStateLabel = "Finished";
              break;
            default:
              claimStateLabel = "";
          }
        }

        return {
          ...invoice,
          supplierName: supplier ? supplier.supplierName : "",
          supplierCode: supplier ? supplier.supplierCode : "",
          countryCode: country ? country.countryCode : "",
          currencyCode: currency ? currency.isoCode : "",
          invoiceStateId: invoiceState ? invoiceState.invoiceStateShortCode : "",
          claimStateLabel,
          claimCode: claim ? claim.claimCode : "",
          formattedInvoiceDate: moment(invoice.invoiceDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
          formattedPreFinancedDate: customerPreFinanceData.find((item) => item.preFinanceId === invoice.preFinanceId)
            ? moment(
                customerPreFinanceData.find((item) => item.preFinanceId === invoice.preFinanceId).payoutDate,
                "YYYY-MM-DD"
              ).format("DD-MM-YYYY")
            : "",
        };
      });

      let invoicesFiltered = enhancedInvoices;

      if (selectedCountry !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => inv.countryCode === selectedCountry);
      }
      if (selectedYear !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => {
          const invYear = new Date(inv.invoiceDate).getFullYear();
          return invYear === parseInt(selectedYear);
        });
      }
      if (selectedYear !== "any" && selectedStartMonth !== "any" && selectedEndMonth !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => {
          const invYear = new Date(inv.invoiceDate).getFullYear();
          const invMonth = new Date(inv.invoiceDate).getMonth();
          const filterStartDate = new Date(selectedYear, selectedStartMonth - 1, 1);
          const filterEndDate = new Date(selectedYear, selectedEndMonth, 0);
          const invoiceDate = new Date(invYear, invMonth, 1);
          return invoiceDate >= filterStartDate && invoiceDate <= filterEndDate;
        });
      }
      if (selectedState !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => inv.invoiceStateId === selectedState);
      }

      setTableData(invoicesFiltered);
    } catch (error) {
      console.error("Error fetching preview data", error);
    } finally {
      setLoading(false);
    }
  }, [
    supplierData,
    countryData,
    currencyData,
    invoiceStateData,
    customerClaimsData,
    selectedCountry,
    selectedYear,
    selectedStartMonth,
    selectedEndMonth,
    selectedState,
    customerPreFinanceData,
  ]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    getPreviewData();
  }, [supplierData, selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, selectedState, getPreviewData]);

  const handleFilter = async () => {
    getPreviewData();
  };

  const [viewClaimDialog, setViewClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  const columns = [
    {
      field: "customerInvoiceId",
      headerName: "id",
      flex: 0.5,
      hide: true,
    },
    {
      field: "customerNumber",
      headerName: "Customer Number",
      flex: 0.5,
      hide: false,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 0.7,
      hide: true,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 0.5,
      hide: false,
    },
    {
      field: "claimCode",
      headerName: "Claim Code",
      flex: 0.5,
      hide: true,
    },
    {
      field: "formattedInvoiceDate",
      headerName: "Invoice Date",
      flex: 0.35,
      type: "date",
      valueFormatter: (params) => params.value,
    },
    { field: "supplierName", headerName: "Supplier", flex: 0.8 },
    { field: "supplierCode", headerName: "Supplier Code", flex: 0.8, hide: true },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.25,
    },
    {
      field: "currencyCode",
      headerName: "Currency",
      flex: 0.25,
    },
    {
      field: "invoiceVATAmountLocalCurrency",
      headerName: "Loc. VAT Amount",
      flex: 0.4,
      align: "right",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "invoiceStateId",
      headerName: "Invoice State",
      flex: 0.33,
    },
    {
      field: "claimStateLabel",
      headerName: "Claim State",
      flex: 0.33,
      renderCell: (params) => {
        if (params.value) {
          return (
            <Button
              variant="text"
              onClick={() => {
                setViewClaimDialog({
                  isOpen: true,
                  title: "View Claim",
                  subTitle: "Details for Claim " + params.row.claimId,
                  params: params.row,
                  custInfo: [{ customerId: params.row.customerId, customerNumber: params.row.customerNumber }],
                });
              }}
            >
              {params.value}
            </Button>
          );
        }
        return "";
      },
    },
    {
      field: "preFinanceId",
      headerName: "Pre-Financed",
      flex: 0.33,
      renderCell: (params) => (params.value > 0 ? <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} /> : ""),
    },
    {
      field: "formattedPreFinancedDate",
      headerName: "Pre-Financed Date",
      flex: 0.4,
      type: "date",
      valueFormatter: (params) => params.value,
    },
    {
      field: "fileId",
      headerName: "PDF Available",
      flex: 0.35,
      renderCell: (params) =>
        params.value > 0 ? (
          <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} />
        ) : (
          <CheckCircleOutlineIcon style={{ color: "red", margin: "0 auto" }} />
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.35,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Invoice",
                  subTitle: "Are you sure you want to delete " + params.row.shortCode + "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewCustomerInvoiceDialog({
                  isOpen: true,
                  downloadFile: true,
                  title: "Manage Invoice",
                  params: params.row,
                  custInfo: [{ customerId: params.row.customerId, customerNumber: params.row.customerNumber }],
                  productInfo: [
                    {
                      productCode: params.row.claimProductCode,
                      productSubCode: params.row.claimProductSubCode,
                    },
                  ],
                  subTitle: "Are you sure you want to manage " + params.row.contactName + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                  handleFile: () => {
                    handleFile();
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleFile = () => {};

  const fetchCustomerInvoices = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/customerInvoicesWithProductCodes");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching customer invoices by entity ID:", error);
    }
  }, []);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
    productInfo: [],
  });

  function onEditConfirmed(e, params) {
    window.location.reload(false);
    setViewCustomerInvoiceDialog({
      ...viewCustomerInvoiceDialog,
      isOpen: false,
    });
  }

  const handleEditCellChange = (params) => {
    const updatedRows = tableData.map((row) => (row.id === params.id ? { ...row, [params.field]: params.props.value } : row));
    setTableData(updatedRows);
  };

  function onDeleteConfirmed(e, params) {
    var submitInError = false;
    axios
      .delete("/api/v1/contacts/" + params.contactId)
      .then((response) => {
        submitInError = false;
      })
      .catch(function (error) {
        if (error.response) {
          submitInError = true;
          if (error.response.data.includes("Cannot insert duplicate key row in object")) {
          }
        } else if (error.request) {
          submitInError = true;
        } else {
          submitInError = true;
        }
      });
    if (!submitInError) {
      var tableData2 = tableData.filter((currency) => currency.currencyId !== params.currencyId);
      setTableData(tableData2);
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }

  function generateArrayOfYears() {
    var max = new Date().getFullYear() + 1;
    var min = max - 5;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push({ yearId: i, yearText: i });
    }

    return years;
  }

  let yearArr = generateArrayOfYears().reverse();

  const monthArr = [
    { monthId: 1, monthText: "Jan" },
    { monthId: 2, monthText: "Feb" },
    { monthId: 3, monthText: "Mar" },
    { monthId: 4, monthText: "Apr" },
    { monthId: 5, monthText: "May" },
    { monthId: 6, monthText: "Jun" },
    { monthId: 7, monthText: "Jul" },
    { monthId: 8, monthText: "Aug" },
    { monthId: 9, monthText: "Sep" },
    { monthId: 10, monthText: "Oct" },
    { monthId: 11, monthText: "Nov" },
    { monthId: 12, monthText: "Dec" },
  ];

  const handleCountryValue = (value, name) => {
    setSelectedCountry(value);
  };
  const handleYearValue = (value, name) => {
    setSelectedYear(value);
  };
  const handleStartMonthValue = (value, name) => {
    setSelectedStartMonth(value);
  };
  const handleEndMonthValue = (value, name) => {
    setSelectedEndMonth(value);
  };
  const handleStateValue = (value) => {
    setSelectedState(value);
  };

  const handleResetFilter = async () => {
    try {
      const response = await axios.get("/api/v1/customerInvoicesWithProductCodes");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching customer invoices by entity ID:", error);
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    let selectedRowsAmount = 0;
    let selectedRowsVATAmount = 0;
    let disablePreFinanced = false;

    newSelection.forEach((rowId) => {
      const selectedRow = tableData.find((row) => row.customerInvoiceId === rowId);
      if (selectedRow) {
        selectedRowsAmount += selectedRow.invoiceGrossAmount;
        selectedRowsVATAmount += selectedRow.invoiceVATAmountLocalCurrency;

        // Check if preFinancedId is greater than 0
        if (selectedRow.preFinanceId > 0) {
          disablePreFinanced = true;
        }
      }
    });

    setSelectedInvoiceCount(newSelection.length);
    setSelectedInvoiceTotalAmount(selectedRowsAmount);
    setSelectedInvoiceTotalVATAmount(selectedRowsVATAmount);

    // Update the button's disabled state
    // setIsPreFinancedDisabled(disablePreFinanced);
  };

  const handleSetState = () => {
    if (selectedRows.length <= 0) {
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMessage("No invoices selected");
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return;
    }

    // Show the custom ConfirmDialog
    setConfirmDialog({
      isOpen: true,
      title: "Confirm Set State",
      subTitle: "Are you sure you want to set selected invoices to state 'RFC'?",
      onConfirm: () => {
        // Set state Id to 3 (RFC)
        const invoiceStateId = 3;

        let rowsToBeUpdated = [];
        selectedRows.forEach((rowId) => {
          const selectedRow = {};

          selectedRow.customerInvoiceId = rowId;
          selectedRow.invoiceStateId = invoiceStateId;

          if (selectedRow) {
            rowsToBeUpdated.push(selectedRow);
          }
        });
        const jsonRowsToBeUpdated = JSON.stringify(rowsToBeUpdated);
        axios
          .put("/api/v1/customerInvoices/updateInvoiceStateId", jsonRowsToBeUpdated, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status !== 204) {
              // Replace 200 with the actual status code your API returns upon successful update
              throw new Error("Failed to update invoice states");
            }
            // console.table(response.data);
            getPreviewData();
            setSelectedRows(0);
            setOpenAlert(true);
            setAlertSeverity("success");
            setAlertMessage("Invoice states are updated!");
            setTimeout(() => {
              setOpenAlert(false);
            }, 5000);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          })
          .catch((error) => {
            setOpenAlert(true);
            setAlertSeverity("error");
            setAlertMessage(error.message);
            setTimeout(() => {
              setOpenAlert(false);
            }, 5000);
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          });
      },
      onCancel: () => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      },
    });
  };

  // const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedInvoiceCount, setSelectedInvoiceCount] = useState(0);
  // const [selectedInvoiceTotalAmount, setSelectedInvoiceTotalAmount] = useState(0);
  // const [selectedInvoiceTotalVATAmount, setSelectedInvoiceTotalVATAmount] = useState(0);
  // const [isPreFinancedDisabled, setIsPreFinancedDisabled] = useState(false);

  // const handleSelectionChange = (newSelection) => {
  //   setSelectedRows(newSelection);
  //   let selectedRowsAmount = 0;
  //   let selectedRowsVATAmount = 0;
  //   let disablePreFinanced = false;

  //   newSelection.forEach((rowId) => {
  //     const selectedRow = tableData.find((row) => row.customerInvoiceId === rowId);
  //     if (selectedRow) {
  //       selectedRowsAmount += selectedRow.invoiceGrossAmount;
  //       selectedRowsVATAmount += selectedRow.invoiceVATAmountLocalCurrency;

  //       if (selectedRow.preFinanceId > 0) {
  //         disablePreFinanced = true;
  //       }
  //     }
  //   });

  //   setSelectedInvoiceCount(newSelection.length);
  //   setSelectedInvoiceTotalAmount(selectedRowsAmount);
  //   setSelectedInvoiceTotalVATAmount(selectedRowsVATAmount);

  //   setIsPreFinancedDisabled(disablePreFinanced);
  // };

  const BulkEditFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #ccc",
          paddingTop: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start" }}></div>
        <div>
          <div>
            <GridFooter
              style={{ borderTop: "none" }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[15, 50, 100]}
            />
          </div>
        </div>
      </div>
    );
  };

  // const [openAlert, setOpenAlert] = React.useState(false);
  // const [alertSeverity, setAlertSeverity] = useState("info");
  // const [alertMessage, setAlertMessage] = useState("");

  return (
    <React.Fragment>
      <ViewClaimDialog viewClaimDialog={viewClaimDialog} setViewClaimDialog={setViewClaimDialog} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />

      <Box sx={{ width: "100%" }}>
        <Collapse in={openAlert}>
          <Alert
            severity={alertSeverity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
      </Box>

      <Helmet title="Customer Invoices" />

      <Divider my={6} />
      <Grid item xs={12} md={6}></Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        ></CardContent>
        <Grid container spacing={0} mt={0}>
          <Grid container xs={12} spacing={5} sx={{ pr: 2 }}>
            <Grid item xs={2}>
              <SelectWrapper
                name="countryId"
                labelColor="#f64435"
                label="Country"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(countryData).split('"countryId":').join('"key":')))
                      .split('"countryCode":')
                      .join('"value":')
                  ),
                ]}
                value={selectedCountry}
                handleChange={handleCountryValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="year"
                label="Year"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(yearArr).split('"yearId":').join('"key":')))
                      .split('"yearText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedYear}
                handleChange={handleYearValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="startMonth"
                label="Start month"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                      .split('"monthText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedStartMonth}
                handleChange={handleStartMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="endMonth"
                label="End month"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                      .split('"monthText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedEndMonth}
                handleChange={handleEndMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="invoiceStateId"
                label="Invoice State"
                options={[
                  { key: "any", value: "Any" },
                  ...invoiceStateData.map((state) => ({
                    key: state.invoiceStateId,
                    value: state.invoiceStateShortCode,
                  })),
                ]}
                value={selectedState}
                handleChange={handleStateValue}
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={handleFilter} sx={{ mt: 4, pl: 6, pr: 6 }} variant="contained">
                Filter
              </Button>
              <Button onClick={handleResetFilter} sx={{ mt: 4, ml: 4, pl: 6, pr: 6 }} variant="outlined">
                Reset
              </Button>
            </Grid>
          </Grid>
          <ViewCustomerInvoiceDialog
            viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
            setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
            countryArr={countryData}
            supplierArr={supplierData}
            currencyArr={currencyData}
            productTypeArr={productData}
            invoiceStateArr={invoiceStateData}
            refreshGrid={fetchCustomerInvoices}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                checkboxSelection
                idProperty="customerInvoiceId"
                editMode="cell"
                getRowId={(row) => row.customerInvoiceId}
                onEditCellChange={handleEditCellChange}
                selectionModel={selectedRows}
                onSelectionModelChange={handleSelectionChange}
                components={{ Toolbar: GridToolbar, Footer: BulkEditFooter }}
                density="compact"
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: false },
                    printOptions: { disableToolbarButton: false },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                rowIndexColumn
                experimentalFeatures={{ newEditingApi: true }}
                rows={tableData}
                columns={columns}
                pagination
                paginationMode="client"
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[15, 50, 100]}
                sx={{
                  height: 677,
                  width: "100%",
                  borderRadius: 1,
                  backgroundColor: "background.paper",
                  boxShadow: 2,
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  marginTop: 0,
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "customerInvoiceId", sort: "desc" }],
                  },
                }}
              />
            )}
            <div style={{ alignSelf: "flex-end", marginBottom: 5, marginTop: 10 }}>
              {/* <Button variant="contained" color="primary" onClick={handlePreFinanced} disabled={isPreFinancedDisabled} sx={{ mr: 3 }}>
                Set selected to pre-financed
              </Button> */}

              <Button
                variant="contained"
                color="success"
                onClick={handleSetState}
                sx={{ m: 3 }}
                // disabled={isPreFinancedDisabled}
              >
                Set Invoice State to RFC
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default AllInvoices;
