import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddSupplier from "../../../components/AddSupplier";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import ViewSupplierDialog from "../../../components/ViewSupplierDialog";
import DataGridExport from "../../../components/DataGridExport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const CountryDataGrid = () => {
  const columns = [
    { field: "supplierId", headerName: "SupplierId", width: 50, hide: true },
    { field: "supplierName", headerName: "SupplierName", flex: 1 },
    { field: "supplierCode", headerName: "SupplierCode", flex: 1 },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.5,
      valueGetter: (params) =>
        countryData.find(
          (country) => country.countryId === params.row.countryCode
        )?.countryCode || "",
    },
    {
      field: "supplierCoC",
      headerName: "Chamber of Commerce number",
      flex: 1,
    },
    {
      field: "supplierVATnr",
      headerName: "VAT number",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Supplier",
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.supplierName +
                    "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewSupplierDialog({
                  isOpen: true,
                  title: "View Supplier",
                  params: params.row,
                  test: [],
                  subTitle:
                    "Are you sure you want to update " +
                    params.row.countryName +
                    "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [viewSupplierDialog, setViewSupplierDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const fetchSuppliers = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/suppliers");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  }, []);

  const fetchCountries = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/countries");
      setCountryData(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/suppliers");
        if (!isCancelled) {
          setTableData(response.data);
        }
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchSuppliers]);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/countries");
        if (!isCancelled) {
          setCountryData(response.data);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchCountries]);

  const onDeleteConfirmed = useCallback(
    (e, params) => {
      axios
        .delete("/api/v1/suppliers/" + params.supplierId)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
          fetchSuppliers();
        })
        .catch(function (error) {
          if (error.response) {
            // submitInError = true;
          }
        });

      setConfirmDialog({ ...confirmDialog, isOpen: false });
    },
    [fetchSuppliers, confirmDialog]
  );

  function onEditConfirmed(e, params) {
    setViewSupplierDialog({ ...viewSupplierDialog, isOpen: false });
  }

  return (
    <React.Fragment>
      <Helmet title="Countries" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Suppliers
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Suppliers</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid item xs={12} md={6}></Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Suppliers Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table, you can search and view Suppliers. You can use the
            below options to customize the Suppliers grid.
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <AddSupplier refreshTable={fetchSuppliers} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <ViewSupplierDialog
            viewSupplierDialog={viewSupplierDialog}
            setViewSupplierDialog={setViewSupplierDialog}
            refreshTable={fetchSuppliers}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.supplierId}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
                rowsContainer: {
                  onClick: (e) => {
                    if (e.target.closest("button") || e.target.closest("a")) {
                      e.stopPropagation();
                    }
                  },
                },
              }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CountryDataGrid;
