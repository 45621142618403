import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import Select from "./SelectBox";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  shortDescription: Yup.string().required("Required"),
  extendedDescription: Yup.string().required("Required"),
  bankAccountTypeId: Yup.string().required("Required"),
  iban: Yup.string().required("Required"),
  bic: Yup.string().required("Required"),
  bankName: Yup.string().required("Required"),
  bankAddress: Yup.string().required("Required"),
  bankPostalCode: Yup.string().required("Required"),
  bankCity: Yup.string().required("Required"),
  countryId: Yup.string().required("Required"),
});

export default function ViewBankAccountDialog(props) {
  const {
    viewBankAccountDialog,
    setViewBankAccountDialog,
    onBankAccountUpdate,
  } = props;

  // const [open, setOpen] = useState(false);
  const [bankAccountTypeData, setBankAccountTypeData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBankAccountType = await axios.get(
          "/api/v1/bankaccounttypes"
        );
        const responseCountry = await axios.get("/api/v1/countries");

        setBankAccountTypeData(responseBankAccountType.data);
        setCountryData(responseCountry.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const initialValues = {
    bankAccountId: viewBankAccountDialog?.params?.bankAccountId,
    entityId: viewBankAccountDialog?.params?.entityId,
    shortDescription: viewBankAccountDialog?.params?.shortDescription,
    extendedDescription: viewBankAccountDialog?.params?.extendedDescription,
    bankAccountTypeId: viewBankAccountDialog?.params?.bankAccountType,
    iban: viewBankAccountDialog?.params?.iban,
    bic: viewBankAccountDialog?.params?.bic,
    bankName: viewBankAccountDialog?.params?.bankName,
    bankAddress: viewBankAccountDialog?.params?.bankAddress,
    bankPostalCode: viewBankAccountDialog?.params?.bankPostalCode,
    bankCity: viewBankAccountDialog?.params?.bankCity,
    countryId: viewBankAccountDialog?.params?.countryCode,
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const updatedContact = {
        entityId: viewBankAccountDialog?.customerId,
        shortDescription: values.shortDescription,
        extendedDescription: values.extendedDescription,
        bankAccountType: values.bankAccountTypeId,
        iban: values.iban,
        bic: values.bic,
        bankName: values.bankName,
        bankAddress: values.bankAddress,
        bankPostalCode: values.bankPostalCode,
        bankCity: values.bankCity,
        countryCode: values.countryId,
      };

      await axios.put(
        `/api/v1/BankAccounts/${values.bankAccountId}`,
        updatedContact
      );

      await timeOut(1500);
      // resetForm();
      setStatus({ sent: true });
      setSubmitting(false);

      // Notify parent component about contact update
      onBankAccountUpdate();
      setViewBankAccountDialog({
        ...viewBankAccountDialog,
        isOpen: false,
      });
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={viewBankAccountDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        {viewBankAccountDialog.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                )}

                {status && status.submit && (
                  <Alert severity="error" my={3}>
                    {status.submit}
                  </Alert>
                )}

                {isSubmitting ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    my={20}
                    mx={40}
                    height={375}
                  >
                    <Typography variant="h6" mr={2}>
                      Updating Bank Account
                    </Typography>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="iban"
                          label="IBAN"
                          value={values.iban}
                          error={Boolean(touched.iban && errors.iban)}
                          fullWidth
                          helperText={touched.iban && errors.iban}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          name="bic"
                          label="BIC"
                          value={values.bic}
                          error={Boolean(touched.bic && errors.bic)}
                          fullWidth
                          helperText={touched.bic && errors.bic}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Select
                          name="bankAccountTypeId"
                          label="Account Type"
                          options={bankAccountTypeData.map((item) => ({
                            key: item.bankAccountTypeId,
                            value: item.shortCode,
                          }))}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="shortDescription"
                          label="Short Description"
                          value={values.shortDescription}
                          error={Boolean(
                            touched.shortDescription && errors.shortDescription
                          )}
                          fullWidth
                          helperText={
                            touched.shortDescription && errors.shortDescription
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="shortDescription"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="extendedDescription"
                          label="Long Description"
                          multiline
                          rows={5}
                          value={values.extendedDescription}
                          error={Boolean(
                            touched.extendedDescription &&
                              errors.extendedDescription
                          )}
                          fullWidth
                          helperText={
                            touched.extendedDescription &&
                            errors.extendedDescription
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="extendedDescription"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="bankName"
                          label="Bank Name"
                          value={values.bankName}
                          error={Boolean(touched.bankName && errors.bankName)}
                          fullWidth
                          helperText={touched.bankName && errors.bankName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="bankName"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="bankAddress"
                          label="Bank Address"
                          value={values.bankAddress}
                          error={Boolean(
                            touched.bankAddress && errors.bankAddress
                          )}
                          fullWidth
                          helperText={touched.bankAddress && errors.bankAddress}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="bankPostalCode"
                          label="Bank Postal Code"
                          value={values.bankPostalCode}
                          error={Boolean(
                            touched.bankPostalCode && errors.bankPostalCode
                          )}
                          fullWidth
                          helperText={
                            touched.bankPostalCode && errors.bankPostalCode
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="bankCity"
                          label="Bank City"
                          value={values.bankCity}
                          error={Boolean(touched.bankCity && errors.bankCity)}
                          fullWidth
                          helperText={touched.bankCity && errors.bankCity}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Select
                          name="countryId"
                          label="Country"
                          options={JSON.parse(
                            JSON.stringify(
                              JSON.parse(
                                JSON.stringify(countryData)
                                  .split('"countryId":')
                                  .join('"key":')
                              )
                            )
                              .split('"countryCode":')
                              .join('"value":')
                          )}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      // onClick={() => setOpen(false)}
                      my={2}
                      mr={4}
                      onClick={handleSubmit}
                    >
                      Update Bank Account
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      //   onClick={() => window.location.reload(false)}
                      my={2}
                      // marginLeft={2}
                      onClick={() => {
                        setViewBankAccountDialog({
                          ...viewBankAccountDialog,
                          isOpen: false,
                        });
                      }}
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
