import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import axios from "axios";
import * as moment from "moment";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl as MuiFormControl,
  Select,
  InputLabel,
  MenuItem,
  Collapse,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const initialValues = {};

const validationSchema = Yup.object().shape({
  entityNumber: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  companyAddress: Yup.string().required("Required"),
  companyPostalCode: Yup.string().required("Required"),
  companyCity: Yup.string().required("Required"),
  companyProvince: Yup.string().required("Required"),
  coc: Yup.string().required("Required"),
  vat: Yup.string().required("Required"),
  taxOffice: Yup.string().required("Required"),
  website: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phonenumber: Yup.number().required("Required"),
});

function BasicForm() {
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/countries")
      .then((response) => setCountryData(response.data));
  }, []);

  const [relationTypeData, setRelationTypeData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/relationTypes")
      .then((response) => setRelationTypeData(response.data));
  }, []);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await timeOut(500);
      const test = {
        entityNumber: values.entityNumber,
        typeRelation: selectRelationType,
        name: values.companyName,
        address: values.companyAddress,
        postalCode: values.companyPostalCode,
        city: values.companyCity,
        province: values.companyProvince,
        countryId: selectCountry,
        coC: values.coc,
        vat: values.vat,
        taxOffice: values.taxOffice,
        website: values.website,
        generalEmailAddress: values.email,
        generalPhoneNumber: values.phonenumber,
        supplierId: 0,
        creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
        startupDate: values.startupDate,
        endDate: null,
        modifiedDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
        isDisabled: true,
        entityStatusId: 0,
      };

      axios
        .post("/api/v1/entities", test)
        .then((response) => {
          setOpenAlert(true);
          setAlertSeverity("success");
          setAlertMessage("Customer has been created successfully");
          setTimeout(() => {
            setOpenAlert(false);
          }, 5000);
        })
        .catch(function (error) {
          if (error.response) {
            setOpenAlert(true);
            setAlertSeverity("error");
            setAlertMessage("Customer creation failed!");
            setTimeout(() => {
              setOpenAlert(false);
            }, 10000);
          }
        });

      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const [selectRelationType, setRelationType] = React.useState("");
  const [selectCountry, setCountry] = React.useState("");
  // const [selectSupplier, setSupplier] = React.useState("");

  const handleSelectChange = (event) => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    if (event.target.name === "relationType") {
      // console.log("1");
      setRelationType(event.target.value);
    }

    if (event.target.name === "companyCountry") {
      // console.log("2");
      setCountry(event.target.value);
    }

    // if (event.target.name === "supplier") {
    //   // console.log("3");
    //   setSupplier(event.target.value);
    // }

    // console.log(event);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      //onChange={handleChange}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              New Entity Information
            </Typography>

            <Box sx={{ width: "100%" }}>
              <Collapse in={openAlert}>
                <Alert
                  severity={alertSeverity}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {alertMessage}
                </Alert>
              </Collapse>
            </Box>

            <form onSubmit={handleSubmit}>
              <Typography variant="body2" gutterBottom>
                Entity Information
              </Typography>
              <Grid container spacing={6}>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="entityNumber"
                    label="Customer Number"
                    value={values.entityNumber}
                    error={Boolean(touched.entityNumber && errors.entityNumber)}
                    fullWidth
                    helperText={touched.entityNumber && errors.entityNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="entityNumber"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth my={2}>
                    <InputLabel id="demo-simple-select-label">
                      Relation Type
                    </InputLabel>
                    <Select
                      name="relationType"
                      labelId="relationType"
                      id="relationType-select"
                      value={selectRelationType}
                      label="Relation Type"
                      error={Boolean(
                        touched.relationType && errors.relationType
                      )}
                      // helperText={touched.relationType && errors.relationType}
                      onBlur={handleBlur}
                      onChange={handleSelectChange}
                    >
                      {relationTypeData.map(
                        ({ relationTypeId, shortDescription }, index) => (
                          <MenuItem key={relationTypeId} value={relationTypeId}>
                            {shortDescription}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="companyName"
                    label="Company Name"
                    value={values.companyName}
                    error={Boolean(touched.companyName && errors.companyName)}
                    fullWidth
                    helperText={touched.companyName && errors.companyName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="companyName"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item md={8} xs={12}>
                  <TextField
                    name="companyAddress"
                    label="Address"
                    value={values.companyAddress}
                    error={Boolean(
                      touched.companyAddress && errors.companyAddress
                    )}
                    fullWidth
                    helperText={touched.companyAddress && errors.companyAddress}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    name="companyPostalCode"
                    label="Postal Code"
                    value={values.companyPostalCode}
                    error={Boolean(
                      touched.companyPostalCode && errors.companyPostalCode
                    )}
                    fullWidth
                    helperText={
                      touched.companyPostalCode && errors.companyPostalCode
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item md={5} xs={12}>
                  <TextField
                    name="companyCity"
                    label="City"
                    value={values.companyCity}
                    error={Boolean(touched.companyCity && errors.companyCity)}
                    fullWidth
                    helperText={touched.companyCity && errors.companyCity}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <TextField
                    name="companyProvince"
                    label="Province"
                    value={values.companyProvince}
                    error={Boolean(
                      touched.companyProvince && errors.companyProvince
                    )}
                    fullWidth
                    helperText={
                      touched.companyProvince && errors.companyProvince
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <FormControl fullWidth my={2}>
                    <InputLabel id="demo-simple-select-label">
                      Country
                    </InputLabel>
                    <Select
                      name="companyCountry"
                      labelId="companyCountry"
                      id="companyCountry-select"
                      value={selectCountry}
                      label="Country"
                      error={Boolean(
                        touched.companyCountry && errors.companyCountry
                      )}
                      // helperText={
                      //   touched.companyCountry && errors.companyCountry
                      // }
                      onBlur={handleBlur}
                      onChange={handleSelectChange}
                    >
                      {countryData.map(({ countryId, countryCode }, index) => (
                        <MenuItem key={countryId} value={countryId}>
                          {countryCode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={6} variant="outlined">
                <Grid item md={6} xs={12}>
                  <TextField
                    name="email"
                    label="General Email Address"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    name="phonenumber"
                    label="General Phone Number"
                    value={values.phonenumber}
                    error={Boolean(touched.phonenumber && errors.phonenumber)}
                    fullWidth
                    helperText={touched.phonenumber && errors.phonenumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="phone"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6} variant="outlined">
                <Grid item md={6} xs={12}>
                  <TextField
                    name="coc"
                    label="Chamber of Commerce"
                    value={values.coc}
                    error={Boolean(touched.coc && errors.coc)}
                    fullWidth
                    helperText={touched.coc && errors.coc}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="coc"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="vat"
                    label="VAT"
                    value={values.vat}
                    error={Boolean(touched.vat && errors.vat)}
                    fullWidth
                    helperText={touched.vat && errors.vat}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="vat"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              <TextField
                name="taxOffice"
                label="Tax Office"
                value={values.taxOffice}
                error={Boolean(touched.taxOffice && errors.taxOffice)}
                fullWidth
                helperText={touched.taxOffice && errors.taxOffice}
                onBlur={handleBlur}
                onChange={handleChange}
                type="taxOffice"
                variant="outlined"
                my={2}
              />

              <TextField
                name="website"
                label="Website"
                value={values.website}
                error={Boolean(touched.website && errors.website)}
                fullWidth
                helperText={touched.website && errors.website}
                onBlur={handleBlur}
                onChange={handleChange}
                type="website"
                variant="outlined"
                my={2}
              />

              <TextField
                id="startupDate"
                name="startupDate"
                label="Start Date"
                type="date"
                value={values.startupDate} // Use value instead of defaultValue
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "10px" }}>Save New Entity</span>
                {isSubmitting && <CircularProgress size={20} />}
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function Blank() {
  return (
    <React.Fragment>
      <Helmet title="Formik" />
      <Typography variant="h3" gutterBottom display="inline">
        Formik
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Forms
        </Link>
        <Typography>Formik</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicForm />
    </React.Fragment>
  );
}

export default Blank;
