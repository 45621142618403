import React from "react";
import styled from "@emotion/styled";

// import { Badge, Typography } from "@mui/material";

// import useAuth from "../../hooks/useAuth";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

// const FooterText = styled(Typography)`
//   color: ${(props) => props.theme.sidebar.footer.color};
// `;

// const FooterSubText = styled(Typography)`
//   color: ${(props) => props.theme.sidebar.footer.color};
//   font-size: 0.7rem;
//   display: block;
//   padding: 1px;
// `;

// const FooterBadge = styled(Badge)`
//   margin-right: ${(props) => props.theme.spacing(1)};
//   span {
//     background-color: ${(props) =>
//       props.theme.sidebar.footer.online.background};
//     border: 1.5px solid ${(props) => props.theme.palette.common.white};
//     height: 12px;
//     width: 12px;
//     border-radius: 50%;
//   }
// `;

// const SidebarFooter = ({ ...rest }) => {
//   // const { user } = useAuth();

//   return (
//     <Footer {...rest}>
//       <Grid container spacing={2}>
//         <Grid item>
//           <FooterBadge
//             overlap="circular"
//             anchorOrigin={{
//               vertical: "bottom",
//               horizontal: "right",
//             }}
//             variant="dot"
//           >
//             {<Avatar alt={"Gozerman"} src={""} />}
//             {/* Demo data */}
//             {
//               <Avatar
//                 alt="Lucy Lavender"
//                 src="/static/img/avatars/avatar-1.jpg"
//               />
//             }
//           </FooterBadge>
//         </Grid>
//         <Grid item>
//           {<FooterText variant="body2">{"Gozerman"}</FooterText>}
//           {/* Demo data */}
//           {<FooterText variant="body2">Lucy Lavender</FooterText>}
//           <FooterSubText variant="caption">UX Designer</FooterSubText>
//         </Grid>
//       </Grid>
//     </Footer>
//   );
// };

const SidebarDefaultFooter = ({ ...rest }) => {
  // const { user } = useAuth();

  return (
    <Footer {...rest}>
      <div>
        <br />
        Footer
        <br />
        <br />
      </div>
    </Footer>
  );
};

export default SidebarDefaultFooter;
