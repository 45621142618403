import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid, Box, Card as MuiCard, CardContent as MuiCardContent, Typography, Divider, IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import apiUtils from "../../utils/apiUtils";
import ViewBankAccountDialog from "../../components/ViewBankAccountDialog";
import ViewPreFinanceDialog from "../../components/ViewPreFinanceDialog";
import AddBankAccount from "../../components/AddBankAccount";
import { useCallback } from "react";
import DataGridExport from "../../components/DataGridExport";
import moment from "moment";
// import { de } from "date-fns/locale";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const CustomerFinancial = ({ customerId }) => {
  const countryData = apiUtils.useApiData("/api/v1/countries");

  const columnsBankAccounts = [
    {
      field: "bankAccountId",
      headerName: "BankAccountId",
      width: 50,
      hide: true,
    },
    // { field: "shortDescription", headerName: "Name", flex: 0.5 },
    { field: "iban", headerName: "IBAN", flex: 1 },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.3,
      valueGetter: (params) => countryData.find((country) => country.countryId === params.row.countryCode).countryCode,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Contact",
                  subTitle: "Are you sure you want to delete " + params.row.iban + "?",
                  onConfirm: () => {
                    onDeleteBankAccountConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewBankAccountDialog({
                  isOpen: true,
                  title: "Edit Bank Account",
                  params: params.row,
                  customerId: customerId,
                  test: [],
                  subTitle: "Edit " + params.row.iban + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const preFinancedColumns = [
    {
      field: "preFinanceId",
      headerName: "ID",
      width: 50,
      hide: true,
    },
    {
      field: "creationDate",
      headerName: "Prefinanced Date",
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
    },

    {
      field: "payoutAmount",
      headerName: "Prefinanced Total",
      flex: 1,
      align: "right",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Prefinance",
                  subTitle: `Are you sure you want to delete Pre-Finance data for the date ${new Date(
                    params.row.creationDate
                  ).toLocaleDateString("nl-NL")}?`,

                  onConfirm: () => {
                    onDeletePreFinancedConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewPreFinanceDialog({
                  isOpen: true,
                  title: "Edit Pre-Financed Data",
                  params: params.row,
                  customerId: customerId,
                  test: [],
                  subTitle: "Edit " + params.row.creationDate + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableBankAccounts, setTableBankAccounts] = useState([]);
  const [loadingPreFinanced, setLoadingPreFinanced] = useState(false);
  const [loadingBankAccounts, setLoadingBankAccounts] = useState(false);
  const [preFinancedData, setPreFinancedData] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [viewBankAccountDialog, setViewBankAccountDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const [viewPreFinanceDialog, setViewPreFinanceDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  const fetchPreFinanced = useCallback(async () => {
    try {
      setLoadingPreFinanced(true);
      const data = await apiUtils.fetchData(`/api/v1/preFinanceByEntityId/${customerId}`);

      // // Group the invoices by day and calculate the sum
      // const groupedData = data.reduce((result, invoice, index) => {
      //   const invoiceDate = new Date(invoice.preFinanceDate)
      //     .toISOString()
      //     .split("T")[0];
      //   if (!result[invoiceDate]) {
      //     result[invoiceDate] = {
      //       preFinancedId: index, // Incremental ID starting from 0
      //       PrefinancedDate: invoiceDate, // PrefinancedDate field
      //       PrefinancedSum: invoice.invoiceVATAmountLocalCurrency, // PrefinancedSum field
      //     };
      //   } else {
      //     result[invoiceDate].PrefinancedSum +=
      //       invoice.invoiceVATAmountLocalCurrency;
      //   }
      //   return result;
      // }, {});

      // // Convert the groupedData object into an array
      // const groupedArray = Object.values(groupedData);

      setPreFinancedData(data);
      setLoadingPreFinanced(false);
      // console.log(data);
    } catch (error) {
      console.error(error);
      setLoadingPreFinanced(false);
      // Handle error here...
    }
  }, [customerId]);

  const fetchBankAccounts = useCallback(async () => {
    try {
      setLoadingBankAccounts(true);
      const data = await apiUtils.fetchData(`/api/v1/bankaccountsByEntityId/${customerId}`);
      setTableBankAccounts(data);
      setLoadingBankAccounts(false);
    } catch (error) {
      console.error(error);
      setLoadingBankAccounts(false);
      // Handle error here...
    }
  }, [customerId]);

  useEffect(() => {
    fetchPreFinanced();
    fetchBankAccounts();
  }, [fetchPreFinanced, fetchBankAccounts]);

  if (loadingPreFinanced || loadingBankAccounts) {
    return <div>Loading...</div>;
  }

  // console.table(tableData);
  // console.table(tableBankAccounts);

  const onDeleteBankAccountConfirmed = async (e, params) => {
    try {
      await apiUtils.deleteData(`/api/v1/bankaccounts/${params.bankAccountId}`);
      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });

      fetchBankAccounts();
    } catch (error) {
      console.error(error);
      // handle error here...
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const onDeletePreFinancedConfirmed = async (e, params) => {
    try {
      await apiUtils.deleteData(`/api/v1/preFinance/${params.preFinanceId}`);
      await apiUtils.updateData(`/api/v1/customerInvoices/updateInvoiceRemovePreFinanceId/${params.preFinanceId}`, null);

      setNotify({
        isOpen: true,
        message: "Deleted Successfully",
        type: "success",
      });

      fetchPreFinanced();
    } catch (error) {
      console.error(error);

      // Notify the user about the error
      setNotify({
        isOpen: true,
        message: error.message || "An unexpected error occurred.",
        type: "error",
      });
    }

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  // const onDeleteConfirmed = async (e, params) => {
  //   try {
  //     await apiUtils.deleteData(
  //       `/api/v1/customerInvoicesPrefinanced/${params.contactId}`
  //     );
  //     setNotify({
  //       isOpen: true,
  //       message: "Deleted Successfully",
  //       type: "success",
  //     });

  //     fetchPreFinanced();
  //   } catch (error) {
  //     console.error(error);
  //     // handle error here...
  //   }

  //   setConfirmDialog({ ...confirmDialog, isOpen: false });
  // };

  const onEditConfirmed = (e, params) => {
    setViewBankAccountDialog({ ...viewBankAccountDialog, isOpen: false });
  };

  return (
    <React.Fragment>
      <Helmet title="Customer Financials" />
      <Divider my={6} />
      <Grid item xs={12} md={6}>
        {/* <FormDialog /> */}
      </Grid>
      <Card mb={0}>
        <CardContent pb={1} sx={{ marginBottom: 0 }}>
          <Typography variant="h6" gutterBottom>
            Customer Financials
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewBankAccountDialog
            viewBankAccountDialog={viewBankAccountDialog}
            setViewBankAccountDialog={setViewBankAccountDialog}
            onBankAccountUpdate={fetchBankAccounts}
          />
          <ViewPreFinanceDialog
            viewPreFinanceDialog={viewPreFinanceDialog}
            setViewPreFinanceDialog={setViewPreFinanceDialog}
            refreshPreFinanced={fetchPreFinanced}
            // onBankAccountUpdate={fetchBankAccounts}
          />
          <Grid item xs={7}>
            Financial Details
          </Grid>
          <Grid xs={5}>
            <Typography variant="h6" gutterBottom>
              Bank Accounts
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <AddBankAccount onDataRefresh={fetchBankAccounts} customerId={customerId} />
              </Grid>
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <DataGridExport tableData={tableBankAccounts} columns={columnsBankAccounts} />
                </div>
              </Grid>
            </Grid>
            <DataGrid
              getRowId={(row) => row.bankAccountId}
              disableColumnFilter
              disableSelectionOnClick
              disableColumnSelector
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={tableBankAccounts}
              columns={columnsBankAccounts}
              pageSize={10}
              sx={{
                height: 307,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
            <Typography variant="h6" mt={10} gutterBottom>
              Pre-Financed
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={preFinancedData} columns={preFinancedColumns} />
            </div>
            <DataGrid
              getRowId={(row) => row.preFinanceId}
              disableColumnFilter
              disableSelectionOnClick
              // disableColumnSelector
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={preFinancedData}
              columns={preFinancedColumns}
              pageSize={10}
              sx={{
                height: 307,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerFinancial;
