import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import Select from "./SelectBox";
import apiUtils from "../utils/apiUtils";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const validationSchema = Yup.object().shape({
  categoryCode: Yup.string().required("Required"),
  subCode: Yup.string().required("Required"),
  productShortDescription: Yup.string().required("Required"),
  productLongDescription: Yup.string().required("Required"),
});
export default function ViewProductTypeDialog(props) {
  const { viewProductTypeDialog, setViewProductTypeDialog, refreshGrid } =
    props;
  const countryData = apiUtils.useApiData("/api/v1/countries");

  const initialValues = {
    productTypeId: viewProductTypeDialog?.params?.productTypeId,
    categoryCode: viewProductTypeDialog?.params?.categoryCode,
    subCode: viewProductTypeDialog?.params?.subCode,
    productShortDescription:
      viewProductTypeDialog?.params?.productShortDescription,
    productLongDescription:
      viewProductTypeDialog?.params?.productLongDescription,
    countryId: viewProductTypeDialog?.params?.countryCode,
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [submitInError, setSubmitInError] = useState(false);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    // console.log("rgregerer");
    try {
      const updatedProduct = {
        productCode: values.productCode,
        categoryCode: values.categoryCode,
        subCode: values.subCode,
        productShortDescription: values.productShortDescription,
        productLongDescription: values.productLongDescription,
        countryCode: values.countryId,
      };
      await axios
        .put("/api/v1/productTypes/" + values.productTypeId, updatedProduct)
        .then((response) => {
          setErrorMessage("Your data has been submitted successfully!");
          setSubmitInError(false);
          refreshGrid();
        })
        .catch(function (error) {
          if (error.response) {
            setSubmitInError(true);
          }
        });
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={viewProductTypeDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {viewProductTypeDialog.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {submitInError ? (
                  <Alert severity="error" my={3}>
                    {errorMessage}
                  </Alert>
                ) : errorMessage.length > 0 ? (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                ) : (
                  ""
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <TextField
                        name="productTypeId"
                        label="productTypeId"
                        value={values.productTypeId}
                        type="hidden"
                      />
                      <Grid item md={12} xs={12}>
                        <Grid item md={12} xs={12}>
                          <Select
                            name="countryId"
                            label="Country"
                            options={JSON.parse(
                              JSON.stringify(
                                JSON.parse(
                                  JSON.stringify(countryData)
                                    .split('"countryId":')
                                    .join('"key":')
                                )
                              )
                                .split('"countryCode":')
                                .join('"value":')
                            )}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="categoryCode"
                          label="Category Code"
                          value={values.categoryCode}
                          error={Boolean(
                            touched.productCode && errors.productCode
                          )}
                          fullWidth
                          helperText={touched.productCode && errors.productCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="categoryCode"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          name="subCode"
                          label="Sub Code"
                          value={values.subCode}
                          error={Boolean(
                            touched.productCode && errors.productCode
                          )}
                          fullWidth
                          helperText={touched.productCode && errors.productCode}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="subCode"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="productShortDescription"
                          label="Short Description"
                          value={values.productShortDescription}
                          error={Boolean(
                            touched.productShortDescription &&
                              errors.productShortDescription
                          )}
                          fullWidth
                          helperText={
                            touched.productShortDescription &&
                            errors.productShortDescription
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="productShortDescription"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          name="productLongDescription"
                          label="Long Description"
                          value={values.productLongDescription}
                          error={Boolean(
                            touched.productLongDescription &&
                              errors.productLongDescription
                          )}
                          fullWidth
                          multiline
                          rows={4}
                          helperText={
                            touched.productLongDescription &&
                            errors.productLongDescription
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="productLongDescription"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      mr={4}
                    >
                      Change Product Type
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      onClick={() =>
                        setViewProductTypeDialog({
                          ...viewProductTypeDialog,
                          isOpen: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

//   <IconButton onClick={(e) => onButtonClick(e, params.row)}>
//     <DeleteIcon />
//   </IconButton>;
