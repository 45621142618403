import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid, Box, Card as MuiCard, Typography, Divider, IconButton, CircularProgress } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewClaimDialog from "../../components/ViewClaimDialog";
import DataGridExport from "../../components/DataGridExport";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const AllClaims = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [claimStateData, setClaimStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const fetchClaimsByEntityId = useCallback(async (customers, claimStates, countries) => {
    setLoading(true);
    try {
      const response = await axios.get("/api/v1/claims");
      getPreviewData(customers, claimStates, countries, response.data);
    } catch (error) {
      console.error("Error fetching claims by entity ID:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const [customers, claimStates, countries] = await Promise.all([
          axios.get("/api/v1/entities/"),
          axios.get("/api/v1/claimStates"),
          axios.get("/api/v1/countries"),
        ]);

        setCustomerData(customers.data);
        setClaimStateData(claimStates.data);
        setCountryData(countries.data);

        fetchClaimsByEntityId(customers.data, claimStates.data, countries.data);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [fetchClaimsByEntityId]);

  const getPreviewData = (customers, claimStates, countries, claims) => {
    const enhancedClaims = claims.map((claim) => {
      const customer = customers.find((cust) => cust.entityId === claim.entityId);
      const claimState = claimStates.find((state) => state.claimStateId === claim.claimStateId);
      const country = countries.find((country) => country.countryId === claim.countryId);

      return {
        ...claim,
        customerName: customer ? customer.name : "",
        claimStateDescription: claimState ? claimState.description : "",
        countryCode: country ? country.countryCode : "",
        taxOfficeRefNo: claim.taxOfficeRefNo,
        formattedClaimDate:
          moment(claim.claimDate).isValid() && moment(claim.claimDate).year() > 1900
            ? moment(claim.claimDate).format("DD-MM-YYYY")
            : "",
        formattedNotificationDate:
          moment(claim.notificationDate).isValid() && moment(claim.notificationDate).year() > 1900
            ? moment(claim.notificationDate).format("DD-MM-YYYY")
            : "",
        formattedPayDate:
          moment(claim.payDate).isValid() && moment(claim.payDate).year() > 1900 ? moment(claim.payDate).format("DD-MM-YYYY") : "",
      };
    });

    setTableData(enhancedClaims);
  };

  const columns = [
    {
      field: "claimId",
      headerName: "Claim ID",
      width: 0.1,
      hide: true,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 0.9,
      hide: false,
    },
    { field: "claimCode", headerName: "Claim Code", flex: 0.55 },
    {
      field: "taxOfficeRefNo",
      headerName: "Ref. number",
      flex: 0.45,
    },
    {
      field: "claimStateDescription",
      headerName: "Claim State",
      flex: 0.4,
    },
    { field: "year", headerName: "Year", flex: 0.2 },
    {
      field: "startMonth",
      headerName: "Start",
      flex: 0.2,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "endMonth",
      headerName: "End",
      flex: 0.2,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.2,
    },
    {
      field: "claimVATAmountLocCur",
      headerName: "VAT Local",
      flex: 0.3,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "formattedClaimDate",
      headerName: "Claim Date",
      type: "date",
      flex: 0.3,
      sortComparator: (v1, v2) => {
        const date1 = v1 ? moment(v1, "DD-MM-YYYY") : moment.invalid();
        const date2 = v2 ? moment(v2, "DD-MM-YYYY") : moment.invalid();
        return date1.isValid() && date2.isValid() ? date1.unix() - date2.unix() : date1.isValid() ? -1 : 1;
      },
    },
    {
      field: "formattedNotificationDate",
      headerName: "Notif. Date",
      type: "date",
      flex: 0.3,
      sortComparator: (v1, v2) => {
        const date1 = v1 ? moment(v1, "DD-MM-YYYY") : moment.invalid();
        const date2 = v2 ? moment(v2, "DD-MM-YYYY") : moment.invalid();
        return date1.isValid() && date2.isValid() ? date1.unix() - date2.unix() : date1.isValid() ? -1 : 1;
      },
    },
    {
      field: "formattedPayDate",
      headerName: "Pay Date",
      type: "date",
      flex: 0.3,
      sortComparator: (v1, v2) => {
        const date1 = v1 ? moment(v1, "DD-MM-YYYY") : moment.invalid();
        const date2 = v2 ? moment(v2, "DD-MM-YYYY") : moment.invalid();
        return date1.isValid() && date2.isValid() ? date1.unix() - date2.unix() : date1.isValid() ? -1 : 1;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.35,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              disabled={params.row.claimStateId !== 1}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Claim",
                  subTitle: "Are you sure you want to delete " + params.row.shortCode + "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewClaimDialog({
                  isOpen: true,
                  title: "View Claim",
                  params: params.row,
                  countryData: countryData,
                  custInfo: [{ customerId: params.row.customerId, customerNumber: params.row.customerNumber }],
                  subTitle: "Are you sure you want to delete " + params.row.claimCode + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewClaimDialog, setViewClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  function onEditConfirmed(e, params) {
    window.location.reload(false);
    setViewClaimDialog({ ...viewClaimDialog, isOpen: false });
  }

  function onDeleteConfirmed(e, params) {
    axios
      .delete("/api/v1/claims/" + params.claimId)
      .then((response) => {
        axios.put("/api/v1/customerInvoices/updateInvoiceRemoveClaimId/" + params.claimId).then((response) => {});
        setNotify({
          isOpen: true,
          message: "Claim deleted successfully",
          type: "success",
        });
        fetchClaimsByEntityId(customerData, claimStateData, countryData);
      })
      .catch((error) => {
        console.log(error);
        setNotify({
          isOpen: true,
          message: "Error deleting claim",
          type: "error",
        });
      })
      .finally(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      });
  }

  const getRowClassName = (params) => {
    const { claimSequenceNumber, uploadStatus } = params.row;
    if (claimSequenceNumber > 0) {
      if (uploadStatus === "acknowledgement") {
        return "pastelGreen";
      } else if (uploadStatus !== "" && uploadStatus !== "acknowledgement") {
        return "pastelRed";
      } else if (uploadStatus === "") {
        return "pastelBlue";
      }
    }
    return "";
  };

  function addDynamicStyles(rules) {
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;

    rules.forEach((rule) => {
      styleSheet.insertRule(rule, styleSheet.cssRules.length);
    });
  }

  useEffect(() => {
    addDynamicStyles([
      `.pastelGreen { background-color: #b2f2bb; }`, // pastel green
      `.pastelRed { background-color: #ffc9c9; }`, // pastel red
      `.pastelBlue { background-color: #a5d8ff; }`, // pastel blue
    ]);
  }, []);

  const ColorLegend = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#b2f2bb" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload status OK
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#a5d8ff" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload in progress
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={15} height={15} bgcolor="#ffc9c9" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Error while uploading claim
        </Typography>
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      <Helmet title="Claims" />
      <Divider my={6} />
      <Card mb={6}>
        <Notification notify={notify} setNotify={setNotify} />
        <Divider />
        <ColorLegend />
        <Grid container spacing={2} mt={0}>
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewClaimDialog
            viewClaimDialog={viewClaimDialog}
            setViewClaimDialog={setViewClaimDialog}
            refreshTable={() => fetchClaimsByEntityId(customerData, claimStateData, countryData)}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              countryData.length > 0 && (
                <DataGrid
                  getRowId={(row) => row.claimId}
                  getRowClassName={getRowClassName}
                  disableSelectionOnClick
                  components={{ Toolbar: GridToolbar }}
                  density="compact"
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: false },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  rows={tableData}
                  columns={columns}
                  pageSize={15}
                  sx={{
                    height: 667,
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    marginTop: 0,
                  }}
                />
              )
            )}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default AllClaims;
