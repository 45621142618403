import React, { useState, useCallback } from "react";
import { Box } from "@mui/system";
import { useDropzone } from "react-dropzone";

const Dropzone = (props) => {
  const [filename, setFilename] = useState("");
  const [containsInvoiceNumber, setContainsInvoiceNumber] = useState(true); // Assuming it starts as true, update it accordingly based on your initial data

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      var selectedFile = acceptedFiles[0];
      props.handleFile(selectedFile, props.rowIndex);
      setFilename(selectedFile.name);
      setContainsInvoiceNumber(
        selectedFile.name.includes(props.selectedInvoiceNumber)
      );
    },
    [props]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
  });

  var files = acceptedFiles.map((file) => <div>{file.path}</div>);

  return (
    <React.Fragment>
      <Box {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {filename ? (
          <span
            style={{
              padding: 12,
              color: containsInvoiceNumber ? "black" : "red",
            }}
          >
            {filename}
          </span>
        ) : files.length > 0 ? (
          <span style={{ padding: 12, color: "green" }}>
            {acceptedFiles[0].path}
          </span>
        ) : (
          <span style={{ padding: 12 }}>Drag or click</span>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Dropzone;
