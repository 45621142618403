import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
// import Select from "./SelectBox";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const initialValues = {
  invoiceLabelShortCode: "",
  invoiceLabelDescription: "",
};

const validationSchema = Yup.object().shape({
  invoiceLabelShortCode: Yup.string().required("Required"),
  invoiceLabelDescription: Yup.string().required("Required"),
});

const AddInvoiceLabelForm = () => {
  function FormDialog() {
    const [open, setOpen] = useState(false);
    var errorMessage = "";
    var submitInError = false;

    const handleSubmit = async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
      try {
        const test = {
          invoiceLabelShortCode: values.invoiceLabelShortCode,
          invoiceLabelDescription: values.invoiceLabelDescription,
        };
        // console.table(test);
        // console.log("wefwef wef wewe ");
        const headers = {
          "Access-control-allow-origin": "*",
          "Access-Control-Allow-Headers":
            "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
          "Access-Control-Allow-Methods": "GET,POST,PUT",
        };

        axios
          .post("/api/v1/invoiceLabels", test, {
            headers: headers,
          })
          .then((response) => {
            errorMessage = "Your data has been submitted successfully!";
            submitInError = false;
          })
          .catch(function (error) {
            if (error.response) {
              submitInError = true;
              if (
                error.response.data.includes(
                  "Cannot insert duplicate key row in object"
                )
              ) {
              }
              var errTxt = error.response.data;
              var sb = errTxt.substring(
                errTxt.indexOf("The duplicate key value is"),
                errTxt.length - errTxt.indexOf("The duplicate key value is")
              );
              errorMessage =
                "Error submitting new Invoice label. There is already an existing value found in the database: " +
                sb.substring(28, sb.indexOf(")."));
            } else if (error.request) {
              submitInError = true;
            } else {
              submitInError = true;
            }
          });

        await timeOut(1500);
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } catch (error) {
        setStatus({ sent: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    };

    return (
      <Card mb={0}>
        <CardContent>
          <Paper mt={0}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Invoice label
              </Button>
              <Dialog
                open={open}
                onClose={() => window.location.reload(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add Invoice label
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      status,
                    }) => (
                      <Card mb={6}>
                        <CardContent>
                          {/* <Typography
                            variant="h6"
                            gutterBottom
                            marginBottom={10}
                          >
                            New Country Information
                          </Typography> */}

                          {submitInError ? (
                            <Alert severity="error" my={3}>
                              {errorMessage}
                            </Alert>
                          ) : errorMessage.length > 0 ? (
                            <Alert severity="success" my={3}>
                              Your data has been submitted successfully!
                            </Alert>
                          ) : (
                            ""
                          )}

                          {isSubmitting ? (
                            <Box
                              display="flex"
                              justifyContent="center"
                              my={20}
                              mx={40}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <form onSubmit={handleSubmit}>
                              {/* <Typography variant="body2" gutterBottom>
                                Company Information
                              </Typography> */}
                              <Grid container spacing={6}>
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    name="invoiceLabelShortCode"
                                    label="Short Code"
                                    value={values.invoiceLabelShortCode}
                                    error={Boolean(
                                      touched.invoiceLabelShortCode &&
                                        errors.invoiceLabelShortCode
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.invoiceLabelShortCode &&
                                      errors.invoiceLabelShortCode
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="invoiceLabelShortCode"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={6}>
                                <Grid item md={12} xs={12}>
                                  <TextField
                                    name="invoiceLabelDescription"
                                    label="Description"
                                    value={values.invoiceLabelDescription}
                                    error={Boolean(
                                      touched.invoiceLabelDescription &&
                                        errors.invoiceLabelDescription
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.invoiceLabelDescription &&
                                      errors.invoiceLabelDescription
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="invoiceLabelDescription"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                              </Grid>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                mt={3}
                                // onClick={() => setOpen(false)}
                                my={2}
                                mr={4}
                              >
                                Submit Invoice label
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                mt={3}
                                onClick={() => window.location.reload(false)}
                                my={2}
                                // marginLeft={2}
                              >
                                Close
                              </Button>
                            </form>
                          )}
                        </CardContent>
                      </Card>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
            </div>
          </Paper>
        </CardContent>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <FormDialog />
    </React.Fragment>
  );
};
export default AddInvoiceLabelForm;
