import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import ViewAccountManagerDialog from "../../../components/ViewAccountManagerDialog";
import AddAccountManagerDialog from "../../../components/AddAccountManager";
import DataGridExport from "../../../components/DataGridExport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const AccountManagersDataGrid = () => {
  const [tableData, setTableData] = useState([]);
  const [entities, setEntities] = useState([]);

  const fetchAccountManagers = useCallback(() => {
    axios
      .get("/api/v1/GetAccountManagers")
      .then((response) => {
        const dataWithCompanyNames = response.data.map((item) => {
          const company = entities.find((entity) => entity.entityId === item.entityId);
          return {
            ...item,
            companyName: company ? company.name : "Unknown Company",
          };
        });
        setTableData(dataWithCompanyNames);
      })
      .catch((error) => {
        console.error("Error fetching Account Managers:", error);
      });
  }, [entities]);

  useEffect(() => {
    axios
      .get("/api/v1/entities")
      .then((response) => {
        setEntities(response.data);
      })
      .catch((error) => {
        console.error("Error fetching entities:", error);
      });
  }, []);

  useEffect(() => {
    if (entities.length > 0) {
      fetchAccountManagers();
    }
  }, [entities, fetchAccountManagers]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewAccountManagerDialog, setViewAccountManagerDialog] = useState({
    isOpen: false,
    title: "",
    accountManagerId: null,
  });

  const [addAccountManagerDialog, setAddAccountManagerDialog] = useState({
    isOpen: false,
    title: "Add Account Manager",
  });

  const columns = [
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
    },
    { field: "lastname", headerName: "Last Name", flex: 1 },
    { field: "companyName", headerName: "Default Company", flex: 2 },
    { field: "email", headerName: "Email Address", flex: 2 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Account Manager",
                  subTitle: `Are you sure you want to delete ${params.row.email}?`,
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewAccountManagerDialog({
                  isOpen: true,
                  title: "View account manager",
                  accountManagerId: params.row.id,
                  accountManagerInfo: params.row,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  function onDeleteConfirmed(e, params) {
    axios
      .delete(`/api/v1/DeleteAccountManager/${params.id}`)
      .then(() => {
        setTableData(tableData.filter((item) => item.id !== params.id));
        setNotify({
          isOpen: true,
          message: `${params.email} successfully deleted`,
          type: "success",
        });
      })
      .catch((error) => {
        console.error("Error deleting account manager:", error);
        setNotify({
          isOpen: true,
          message: "Cannot delete account manager",
          type: "error",
        });
      });
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }

  return (
    <React.Fragment>
      <Helmet title="Account Managers" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Account Managers
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Settings
            </Link>
            <Link component={NavLink} to="/">
              Portal Settings
            </Link>
            <Typography>Account Managers</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={0}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Account Managers Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view Account Managers. You can use the below options to customize the grid.
          </Typography>
        </CardContent>
        <Grid container spacing={0}>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewAccountManagerDialog
            viewAccountManagerDialog={viewAccountManagerDialog}
            setViewAccountManagerDialog={setViewAccountManagerDialog}
            onSuccess={fetchAccountManagers} // Pass the callback to refresh data
          />
          <AddAccountManagerDialog
            addAccountManagerDialog={addAccountManagerDialog}
            setAddAccountManagerDialog={setAddAccountManagerDialog}
            onSuccess={fetchAccountManagers} // Pass the callback to refresh data
          />
          <Grid item xs={12}>
            <Grid item xs={12} ml={4} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddAccountManagerDialog({ ...addAccountManagerDialog, isOpen: true })}
              >
                Add Account Manager
              </Button>
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.id}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default AccountManagersDataGrid;
