import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import apiUtils from "../utils/apiUtils";
import Select from "./SelectBox";
// import { is } from "date-fns/locale";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const initialValues = {
  categoryCode: "",
  subCode: "",
  productShortDescription: "",
  productLongDescription: "",
  countryCode: "",
};

const validationSchema = Yup.object().shape({
  categoryCode: Yup.string().required("Required"),
  // subCode: Yup.string().required("Required"),
  productShortDescription: Yup.string().required("Required"),
  productLongDescription: Yup.string().required("Required"),
  countryCode: Yup.string().required("Required"),
});

const AddProductTypeForm = (props) => {
  const { refreshGrid } = props;
  function FormDialog() {
    const [open, setOpen] = useState(false);
    var errorMessage = "";
    var submitInError = false;

    const countryData = apiUtils.useApiData("/api/v1/countries");

    const handleSubmit = async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
      // console.log(values);
      try {
        const newProductType = {
          categoryCode: values.categoryCode,
          subCode: values.subCode,
          productShortDescription: values.productShortDescription,
          productLongDescription: values.productLongDescription,
          countryCode: values.countryCode,
        };

        axios
          .post("/api/v1/productTypes", newProductType)
          .then((response) => {
            errorMessage = "Your data has been submitted successfully!";
            submitInError = false;
            refreshGrid();
          })
          .catch(function (error) {
            if (error.response) {
              submitInError = true;
              if (
                error.response.data.includes(
                  "Cannot insert duplicate key row in object"
                )
              ) {
              }
              var errTxt = error.response.data;
              var sb = errTxt.substring(
                errTxt.indexOf("The duplicate key value is"),
                errTxt.length - errTxt.indexOf("The duplicate key value is")
              );
              errorMessage =
                "Error submitting new Product Type. There is already an existing value found in the database: " +
                sb.substring(28, sb.indexOf(")."));
            } else if (error.request) {
              submitInError = true;
            } else {
              submitInError = true;
            }
          });

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    };

    return (
      <Card mb={0}>
        <CardContent>
          <Paper mt={0}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Product Type
              </Button>
              <Dialog
                open={open}
                onClose={() => window.location.reload(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add Product Type
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      status,
                    }) => (
                      <Card mb={6}>
                        <CardContent>
                          {submitInError ? (
                            <Alert severity="error" my={3}>
                              {errorMessage}
                            </Alert>
                          ) : errorMessage.length > 0 ? (
                            <Alert severity="success" my={3}>
                              Your data has been submitted successfully!
                            </Alert>
                          ) : (
                            ""
                          )}

                          {isSubmitting ? (
                            <Box
                              display="flex"
                              justifyContent="center"
                              my={20}
                              mx={40}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <form
                              onSubmit={(e) => {
                                // console.log("Form submitted");
                                handleSubmit(e);
                              }}
                            >
                              {/* <Typography variant="body2" gutterBottom>
                                Company Information
                              </Typography> */}
                              <Grid container spacing={6}>
                                {/* <TextField
                                  name="productTypeId"
                                  // inputProps={{ maxLength: 10 }}
                                  label="productTypeId"
                                  value={values.productTypeId}
                                  type="hidden"
                                /> */}
                                <Grid item md={12} xs={12}>
                                  <Select
                                    name="countryCode"
                                    label="Country"
                                    options={JSON.parse(
                                      JSON.stringify(
                                        JSON.parse(
                                          JSON.stringify(countryData)
                                            .split('"countryId":')
                                            .join('"key":')
                                        )
                                      )
                                        .split('"countryCode":')
                                        .join('"value":')
                                    )}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={6}>
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    name="categoryCode"
                                    label="Category Code"
                                    value={values.categoryCode}
                                    error={Boolean(
                                      touched.categoryCode &&
                                        errors.categoryCode
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.categoryCode &&
                                      errors.categoryCode
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="categoryCode"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    name="subCode"
                                    label="Sub Code"
                                    value={values.subCode}
                                    error={Boolean(
                                      touched.subCode && errors.subCode
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.subCode && errors.subCode
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="subCode"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={6}>
                                <Grid item md={12} xs={12}>
                                  <TextField
                                    name="productShortDescription"
                                    label="Short Description"
                                    value={values.productShortDescription}
                                    error={Boolean(
                                      touched.productShortDescription &&
                                        errors.productShortDescription
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.productShortDescription &&
                                      errors.productShortDescription
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="productShortDescription"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={6}>
                                <Grid item md={12} xs={12}>
                                  <TextField
                                    name="productLongDescription"
                                    label="Long Description"
                                    value={values.productLongDescription}
                                    error={Boolean(
                                      touched.productLongDescription &&
                                        errors.productLongDescription
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.productLongDescription &&
                                      errors.productLongDescription
                                    }
                                    multiline
                                    rows={4}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="productLongDescription"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                              </Grid>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                mt={3}
                                // onClick={() => setOpen(false)}
                                my={2}
                                mr={4}
                              >
                                Submit Product Type
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                mt={3}
                                //</form>
                                onClick={() => setOpen(false)}
                                my={2}
                                // marginLeft={2}
                              >
                                Close
                              </Button>
                            </form>
                          )}
                        </CardContent>
                      </Card>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
            </div>
          </Paper>
        </CardContent>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <FormDialog />
    </React.Fragment>
  );
};
export default AddProductTypeForm;
