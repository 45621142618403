import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Grid,
  IconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import ViewCustomerInvoiceUploadDialog from "../../components/ViewCustomerInvoiceUploadDialog"; // Adjust the import path
import apiUtils from "../../utils/apiUtils";
import { hi } from "date-fns/locale";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const CustomerDataGrid = () => {
  const [tableData, setTableData] = useState([]);
  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    downloadFile: false,
    params: {},
  });

  const [selectedFiles, setSelectedFiles] = useState([]);

  const supplierData = apiUtils.useApiData("/api/v1/suppliers");
  const currencyData = apiUtils.useApiData("/api/v1/currencies");
  const productData = apiUtils.useApiData("/api/v1/productTypes");
  const invoiceStateData = apiUtils.useApiData("/api/v1/invoiceStates");
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const customerData = apiUtils.useApiData("/api/v1/entities");

  const fetchCustomerFiles = useCallback(async () => {
    const response = await axios.get("/api/v1/CustomerFile");
    setTableData(response.data);
    // console.log(response.data);
  }, []);

  useEffect(() => {
    fetchCustomerFiles();
  }, [fetchCustomerFiles]);

  const handleViewClick = (params) => {
    setViewCustomerInvoiceDialog({
      isOpen: true,
      downloadFile: true,
      params: params.row,
    });
  };

  const columns = [
    { field: "fileId", headerName: "File ID", flex: 0.5, sortable: true, hide: true },
    {
      field: "entityId",
      headerName: "Customer Number",
      flex: 1.5,
      sortable: true,
      valueGetter: (params) => {
        const entity = customerData.find((item) => item.entityId === params.row.entityId);
        return entity ? entity.entityNumber : "Unknown"; // Fallback to "Unknown" if not found
      },
    },
    { field: "fileName", headerName: "File Name", flex: 1.5, sortable: true },
    { field: "mimeType", headerName: "Mime Type", flex: 0.5, sortable: true },
    {
      field: "creationDate",
      headerName: "Upload Date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.value), // Keep it as a Date object for sorting
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return new Intl.DateTimeFormat("nl-NL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);
      },
    },
    { field: "fileSize", headerName: "File Size", flex: 1, sortable: true },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton onClick={() => handleViewClick(params)}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleDownloadClick = async () => {
    const downloadPromises = selectedFiles.map(async (fileId) => {
      const fileData = tableData.find((file) => file.fileId === fileId);
      if (!fileData) return;

      const { fileName } = fileData;

      try {
        const response = await axios({
          url: `/api/v1/AzureCustomerBlobStorage/${fileId}`,
          method: "GET",
          responseType: "blob",
        });
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); // Use the original file name
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error(`Error downloading file with ID ${fileId}:`, error);
      }
    });

    await Promise.all(downloadPromises);
  };

  return (
    <React.Fragment>
      <Helmet title="Customer Files" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Customer Files
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <NavLink to="/">Dashboard</NavLink>
            <NavLink to="/">Pages</NavLink>
            <Typography>Customer Files</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 5,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Customer Files Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view customer files.
          </Typography>
        </CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <DataGrid
              getRowId={(row) => row.fileId}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              checkboxSelection // Add this line to enable checkbox selection
              onSelectionModelChange={(newSelection) => {
                setSelectedFiles(newSelection);
              }}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadClick}
            disabled={selectedFiles.length === 0}
            sx={{ mt: 4, ml: 10, mb: 4 }} // Add spacing: margin-top and margin-left
          >
            Download Selected Files
          </Button>
        </Grid>
      </Card>
      <ViewCustomerInvoiceUploadDialog
        viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
        setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
        countryArr={countryData}
        supplierArr={supplierData}
        currencyArr={currencyData}
        productTypeArr={productData}
        invoiceStateArr={invoiceStateData}
        refreshGrid={fetchCustomerFiles}
      />
    </React.Fragment>
  );
};

export default CustomerDataGrid;
