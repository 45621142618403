import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Divider,
  TextField as MuiTextField,
  Alert as MuiAlert,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  contactName: Yup.string().required("Required"),
  contactJobTitle: Yup.string().required("Required"),
  contactEmailAddress: Yup.string().required("Required"),
  contactMobileNumber: Yup.string().required("Required"),
  contactDirectNumber: Yup.string().required("Required"),
});

export default function ViewContactsDialog(props) {
  const { viewContactsDialog, setViewContactsDialog, onContactUpdate } = props;

  const initialValues = {
    contactId: viewContactsDialog?.params?.contactId,
    entityId: viewContactsDialog?.params?.entityId,
    contactName: viewContactsDialog?.params?.contactName,
    contactJobTitle: viewContactsDialog?.params?.contactJobTitle,
    contactEmailAddress: viewContactsDialog?.params?.contactEmailAddress,
    contactMobileNumber: viewContactsDialog?.params?.contactMobileNumber,
    contactDirectNumber: viewContactsDialog?.params?.contactDirectNumber,
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const updatedContact = {
        entityId: values.entityId,
        contactName: values.contactName,
        contactJobTitle: values.contactJobTitle,
        contactEmailAddress: values.contactEmailAddress,
        contactMobileNumber: values.contactMobileNumber,
        contactDirectNumber: values.contactDirectNumber,
      };

      await axios.put(`/api/v1/contacts/${values.contactId}`, updatedContact);

      await timeOut(1500);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);

      // Notify parent component about contact update
      onContactUpdate();
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={viewContactsDialog.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {viewContactsDialog.title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                )}

                {status && status.submit && (
                  <Alert severity="error" my={3}>
                    {status.submit}
                  </Alert>
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={20} mx={40}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="contactName"
                          label="Name"
                          value={values.contactName}
                          error={Boolean(
                            touched.contactName && errors.contactName
                          )}
                          fullWidth
                          helperText={touched.contactName && errors.contactName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="contactJobTitle"
                          label="Job Title"
                          value={values.contactJobTitle}
                          error={Boolean(
                            touched.contactJobTitle && errors.contactJobTitle
                          )}
                          fullWidth
                          helperText={
                            touched.contactJobTitle && errors.contactJobTitle
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="contactEmailAddress"
                          label="Email Address"
                          value={values.contactEmailAddress}
                          error={Boolean(
                            touched.contactEmailAddress &&
                              errors.contactEmailAddress
                          )}
                          fullWidth
                          helperText={
                            touched.contactEmailAddress &&
                            errors.contactEmailAddress
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="contactMobileNumber"
                          label="Mobile Number"
                          value={values.contactMobileNumber}
                          error={Boolean(
                            touched.contactMobileNumber &&
                              errors.contactMobileNumber
                          )}
                          fullWidth
                          helperText={
                            touched.contactMobileNumber &&
                            errors.contactMobileNumber
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="contactDirectNumber"
                          label="Direct Number"
                          value={values.contactDirectNumber}
                          error={Boolean(
                            touched.contactDirectNumber &&
                              errors.contactDirectNumber
                          )}
                          fullWidth
                          helperText={
                            touched.contactDirectNumber &&
                            errors.contactDirectNumber
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      disabled={isSubmitting}
                    >
                      Update Contact
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      mt={3}
                      ml={3}
                      onClick={() => {
                        setViewContactsDialog({
                          ...viewContactsDialog,
                          isOpen: false,
                        });
                      }}
                    >
                      Close
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
