import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import axios from "axios";
import * as moment from "moment";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert as MuiAlert,
  Box,
  Paper as MuiPaper,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  TextField as MuiTextField,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
// import Select from "../../components/SelectBox";

const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const initialValues = {
  shortDescription: "",
  content: "",
  fileToUpload: "",
};

const validationSchema = Yup.object().shape({
  shortDescription: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
});

const AddCustomerNoteForm = ({ onDataRefresh, customerId }) => {
  function FormDialog() {
    const [open, setOpen] = useState(false);

    var errorMessage = "";
    var submitInError = false;
    var testId = 0;
    var fileId = 0;

    const handleSubmit = async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
      try {
        //Add File To DB

        if (values.file) {
          const fileInfo = {
            documentType: 1,
            creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
            createdBy: 1,
            fileName: values.file.name,
            fileSize: values.file.size,
            mimeType: values.file.type,
          };
          axios.post("/api/v1/file", fileInfo).then((response) => {
            // console.table(response);
            fileId = response.data.fileId;
            var FormData = require("form-data");
            var data = new FormData();
            data.append("file", values.file, fileId);
            return axios.post("/api/v1/azureblobstorage", data).then(() => {
              // const fileIdInfo = {
              //   fileId: fileId,
              // };
              const noteContent = {
                entityId: customerId,
                shortDescription: values.shortDescription,
                content: values.content,
                creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
                createdBy: 1,
                fileId: fileId,
              };

              return axios
                .post("/api/v1/customernote", noteContent)
                .then(() => {
                  // alert("Note Added Successfully");
                  onDataRefresh();
                });
            });
          });
        } else {
          const noteContent = {
            entityId: customerId,
            shortDescription: values.shortDescription,
            content: values.content,
            creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
            createdBy: 1,
            fileId: 0,
          };

          axios.post("/api/v1/customernote", noteContent).then(() => {
            // alert("Note Added Successfully");
            onDataRefresh();
          });
        }

        values.file[0].name = testId;
        // console.table(values);
        // console.log(testId);
        // console.table(values);

        await timeOut(1500);
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } catch (error) {
        setStatus({ sent: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    };

    return (
      <Card mb={0}>
        <CardContent>
          <Paper mt={0}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Note
              </Button>
              <Dialog
                open={open}
                onClose={() => window.location.reload(false)}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Add Note</DialogTitle>
                <Divider />
                <DialogContent>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      status,
                      setFieldValue,
                    }) => (
                      <Card mb={6}>
                        <CardContent>
                          {/* <Typography
                            variant="h6"
                            gutterBottom
                            marginBottom={10}
                          >
                            New Country Information
                          </Typography> */}

                          {submitInError ? (
                            <Alert severity="error" my={3}>
                              {errorMessage}
                            </Alert>
                          ) : errorMessage.length > 0 ? (
                            <Alert severity="success" my={3}>
                              Your data has been submitted successfully!
                            </Alert>
                          ) : (
                            ""
                          )}

                          {isSubmitting ? (
                            <Box
                              display="flex"
                              justifyContent="center"
                              my={20}
                              mx={40}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <form onSubmit={handleSubmit}>
                              {/* <Typography variant="body2" gutterBottom>
                                Company Information
                              </Typography> */}
                              <Grid container spacing={6}>
                                <Grid item md={12}>
                                  <TextField
                                    name="shortDescription"
                                    label="Short Description"
                                    value={values.shortDescription}
                                    error={Boolean(
                                      touched.shortDescription &&
                                        errors.shortDescription
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.shortDescription &&
                                      errors.shortDescription
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="shortDescription"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                                <Grid item md={12}>
                                  <TextField
                                    multiline
                                    fullWidth
                                    rows={20}
                                    name="content"
                                    label="Content"
                                    value={values.content}
                                    error={Boolean(
                                      touched.content && errors.content
                                    )}
                                    helperText={
                                      touched.content && errors.content
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="content"
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={6}>
                                <Grid item md={12}>
                                  <TextField
                                    fullWidth
                                    name="file"
                                    id="file"
                                    // label="fileToUpload"
                                    type="file"
                                    // value={values.fileToUpload}
                                    error={Boolean(
                                      touched.fileToUpload &&
                                        errors.fileToUpload
                                    )}
                                    helperText={
                                      touched.fileToUpload &&
                                      errors.fileToUpload
                                    }
                                    onChange={(event) => {
                                      setFieldValue(
                                        "file",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    variant="outlined"
                                    my={2}
                                  />
                                </Grid>
                              </Grid>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                mt={3}
                                // onClick={() => setOpen(false)}
                                my={2}
                                mr={4}
                              >
                                Submit Note
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                mt={3}
                                onClick={() => setOpen(false)}
                                my={2}
                              >
                                Close
                              </Button>
                            </form>
                          )}
                        </CardContent>
                      </Card>
                    )}
                  </Formik>
                </DialogContent>
              </Dialog>
            </div>
          </Paper>
        </CardContent>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <FormDialog />
    </React.Fragment>
  );
};
export default AddCustomerNoteForm;
