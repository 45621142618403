import React from "react";
import { TextField, MenuItem, FormControl as MuiFormControl } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)``;

const SelectWrapper = ({ name, rowIndex, selectedKey, optional1, options, handleChange, ...otherProps }) => {
  const handleChangeInt = (event) => {
    const { value } = event.target;
    handleChange(value, rowIndex);
  };

  const configSelect = {
    ...otherProps,
    select: true,
    variant: "outlined",
    onChange: handleChangeInt,
    fullWidth: true,
  };

  return (
    <React.Fragment>
      <FormControl fullWidth my={2}>
        <TextField {...configSelect} defaultValue={selectedKey} SelectProps={{ displayEmpty: true }}>
          {optional1
            ? options
                .filter((prod) => prod.countryCode === optional1)
                .map(({ key, value, disabled }, index) => (
                  <MenuItem key={key} value={key} disabled={disabled}>
                    {value}
                  </MenuItem>
                ))
            : options.map(({ key, value, disabled }, index) => (
                <MenuItem key={key} value={key} disabled={disabled}>
                  {value}
                </MenuItem>
              ))}
        </TextField>
      </FormControl>
    </React.Fragment>
  );
};

export default SelectWrapper;
