import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddPaymentType from "../../../components/AddPaymentType";
import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import axios from "axios";
import ViewPaymentTypeDialog from "../../../components/ViewPaymentTypeDialog";
import DataGridExport from "../../../components/DataGridExport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const PaymentTypesDataGrid = () => {
  const columns = [
    {
      field: "paymentTypeId",
      headerName: "PaymentTypeId",
      width: 50,
      hide: false,
    },
    { field: "shortCode", headerName: "ShortCode", flex: 0.5 },
    { field: "description", headerName: "Description", flex: 2.5 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Payment State",
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.shortCode +
                    "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewPaymentTypeDialog({
                  isOpen: true,
                  title: "Delete PaymentType",
                  params: params.row,
                  test: [],
                  subTitle:
                    "Are you sure you want to delete " +
                    params.row.shortCode +
                    "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState([]);

  const fetchPaymentTypes = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/paymentTypes");
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching payment types:", error);
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/paymentTypes");
        if (!isCancelled) {
          setTableData(response.data);
        }
      } catch (error) {
        console.error("Error fetching payment types:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchPaymentTypes]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewPaymentTypeDialog, setViewPaymentTypeDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    test: [],
  });

  function onEditConfirmed(e, params) {
    setViewPaymentTypeDialog({ ...viewPaymentTypeDialog, isOpen: false });
  }

  function onDeleteConfirmed(e, params) {
    axios
      .delete("/api/v1/paymentTypes/" + params.paymentTypeId)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
        fetchPaymentTypes();
      })
      .catch(function (error) {
        if (error.response) {
          // submitInError = true;
        }
      });

    setConfirmDialog({ ...confirmDialog, isOpen: false });
  }

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Payment States
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Payment States</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid item xs={12} md={6}></Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Payment States Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view Payment States. You can use
            the below options to customize the grid.
          </Typography>
        </CardContent>
        <Grid container spacing={0} mt={0}>
          <AddPaymentType refreshTable={fetchPaymentTypes} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <ViewPaymentTypeDialog
            viewPaymentTypeDialog={viewPaymentTypeDialog}
            setViewPaymentTypeDialog={setViewPaymentTypeDialog}
            refreshTable={fetchPaymentTypes}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.paymentTypeId}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default PaymentTypesDataGrid;
