import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import {
  Box,
  Grid,
  IconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import apiUtils from "../../utils/apiUtils";
import DataGridExport from "../../components/DataGridExport";
import axios from "axios";
import { Tabs, Tab } from "@mui/material";
// import { array } from "prop-types";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const CustomerDataGrid = () => {
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const entityTypes = apiUtils.useApiData("/api/v1/relationTypes");

  useEffect(() => {
    axios
      .get("/api/v1/entities")
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching entities:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/api/v1/entities")
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching entities:", error);
      });
  }, []);

  useEffect(() => {
    if (entityTypes.length > 0) {
      setActiveTab(entityTypes[0].relationTypeId);
    }
  }, [entityTypes]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredData = tableData.filter(
    (data) => data.typeRelation === activeTab
  );

  const defaultSortModel = [
    {
      field: "entityNumber", // Specify the field to sort by
      sort: "asc", // Specify the sort direction ('asc' or 'desc')
    },
  ];

  // console.table(tableData);

  const columns = [
    { field: "entityId", headerName: "EntityID", width: 50, hide: true },
    { field: "entityNumber", headerName: "EntityNumber", flex: 0.5 },
    { field: "name", headerName: "Name", flex: 1.5 },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.5,
      valueGetter: (params) =>
        countryData.find(
          (country) => country.countryId === params.row.countryId
        )?.countryCode || "",
    },
    { field: "generalPhoneNumber", headerName: "General Phone", flex: 0.5 },
    { field: "vat", headerName: "VATNumber", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              component={Link}
              to={
                "/customers/customeroverview?customerId=" +
                params.row.entityId +
                "&customerNumber=" +
                params.row.entityNumber
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Helmet title="Customers" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Customers
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Customers</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 5,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Customers Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view customers. You can use the
            below options to customize the customer grid. Test.
          </Typography>
        </CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {entityTypes && (
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="customer data tabs"
              >
                {entityTypes.map((type) => (
                  <Tab
                    key={type.relationTypeId}
                    label={type.shortDescription}
                    value={type.relationTypeId}
                  />
                ))}
              </Tabs>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.entityId}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              rows={filteredData}
              columns={columns}
              pageSize={15}
              sortModel={defaultSortModel}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerDataGrid;
